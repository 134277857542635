
import React,{useEffect,useState} from 'react';
import axios from 'axios';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import Footer from "../components/Footer";
import Getdeal from "../API/Getdeal";
import RemoveDeal from "../API/RemoveDeal";
import GetCountry from "../API/GetCountry";
import GetState from "../API/GetState";
import GetCity from "../API/GetCity";
import ManageSidebar from '../components/ManageSidebar';
import GetdealdyId from '../API/GetdealdyId';
import GetProductByDealId from '../API/GetProductByDealId'
//import SavaMeets from '../API/SavaMeets';
import UpdateLeadStatus from '../API/UpdateLeadStatus';
import Getproduct from "../API/Getproduct";

export default function Leads(){  
  let [country,CountryList] = useState();
  let [state, StateList] = useState();
  let [city, CityList] = useState();
  let [contectpersonval, setContectpersonval] = useState();
  let [emailval, setEmailval] = useState("");
  let [mobileval, setMobileval] = useState();
  let [companyNameval, setCompanynameval] = useState();
  let [companycontactval,setCompanycontactval] = useState();
  let [countrynameval, setCountry] = useState();
  let [statenameval, setState] = useState();
  let [citynameval, setCity] = useState();
  let [companyaddressval, setCompanyaddress] = useState();
  let [postalcodeval, setPostalcodeval] = useState("");
  let [websiteval, setWebsite] = useState();

  let [noofemployeeval, setPerson] = useState();
  let [noofexcustomerval, setNoofexcustomer] = useState();
  let [industryval, setIndustry] = useState();
  let [dealid,setDealid] = useState();
  let [productlist, setProduct] = useState();
  let [meetstatus, setMeetStatusval] = useState();
  let [product, setProductval] = useState();
  let [meetdesc, setMeetDescriptionVal] = useState();
  let [productDealList, setDealProduct] = useState();


    let [deallists, setDeal] = useState();

    let [dealbyid,DealListById] = useState();
    let getProduct = async () => {
      let resp = await Getproduct();
      if(resp.status === 1)
      {
          setProduct(resp.responseValue);  
      }
    };
    let getDeal = async () => {
      var userId = localStorage.getItem("userId");
        let resp = await Getdeal(userId);
        if(resp.status === 1)
        {
            setDeal(resp.responseValue);  
        }
      };
      let removeDeal = async (e) =>{
        let con = window.confirm('Are you sure want to delete this Record?');
        if(con == true)
        {
        const sendData = {
            "id": e,
               }
               const resp = await RemoveDeal(sendData);
               getDeal();
               if(resp.status === 1){
                   document.getElementById("deleteMsg").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong>Lead are deleted successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
               window.setTimeout(function() {
                document.getElementById("deleteMsg").style.display='none';
                window.location.href = '/leads';
                }, 2000);
               }
              }
              else{
                return false;
              }
      }
      let companyMeets = async(e) =>{
        const resp = await GetdealdyId(e);
        if(resp.status === 1)
         {
          document.getElementById('dealid').value = resp.responseValue[0].id;
         }
        const respProduct = await GetProductByDealId(e);
        {
          if(respProduct.status === 1)
          {
           setDealProduct(respProduct.responseValue);
          }
        }
 
     }
     let clear = async () =>{
      document.getElementById("addMeets").reset();

  }
   
    
      let handlechage = async (e) => {
        //clear();
        let name = e.target.name;
        let value = e.target.value;
        if(name === 'addDeal'){
          setDealid(value)
        } 
        if (name === 'contectpersonval') {
       
          setContectpersonval(value)
          
      }
      if (name === 'emailval') {
        setEmailval(value)
      }
      if (name === 'mobileval') {
        setMobileval(value)
       }
       if (name === 'companynameval') {
        setCompanynameval(value)
       }
       if(name === 'companycontactval'){
        setCompanycontactval(value)
       }
        if (name === 'productval') {
            setProductval(value);
        }
        if (name === 'meetStatusval') {
          setMeetStatusval(value);
          }
          if (name === 'meetDescriptionVal') {
            setMeetDescriptionVal(value);
        }
        if (name === 'companyaddressval') {
          setCompanyaddress(value)
        }
        if (name === 'postalcodeval') {
          setPostalcodeval(value)
        }
        if (name === 'websiteval') {
          setWebsite(value)
        }
        if (name === 'noofemployeeval') {
          setPerson(value)
        }
        if (name === 'noofexcustomerval') {
          setNoofexcustomer(value)
        }
        if (name === 'industryval') {
          setIndustry(value)
        }

      };
      let addCompanyMeets = async() =>{
        var userId = localStorage.getItem("userId");
        var requestID = localStorage.getItem("customerId");
        
        if(document.getElementById('contectpersonval').value === ""){
          document.getElementById("errorNameval").innerText = 'Enter Contact Person';
        }
       else if(document.getElementById('emailval').value === ""){
          document.getElementById("errorEmailval").innerText = 'Enter email';
        }
        else if(document.getElementById('mobileval').value === ""){
          document.getElementById("errorMobileval").innerText = 'Enter mobile no';
        }
       
        else if(document.getElementById('companynameval').value === ""){
          document.getElementById("errorCompanyNameval").innerText = 'Enter company name';
        }
        else if(document.getElementById('productval').value === ""){
          document.getElementById("errorproductval").innerText = 'select product';
        }
        else if(document.getElementById('meetStatusval').value === "-1"){
          document.getElementById("errormeetStatusval").innerText = 'select status';
        }
        else if(document.getElementById('meetDescriptionVal').value === ""){
          document.getElementById("errormeetDescriptionVal").innerText = 'Enter Meeting Description';
        }
        else{
          const formData = new FormData();
          formData.append('DealID', document.getElementById('dealid').value);
          formData.append('ProductID', document.getElementById('productval').value);
          formData.append('ContactPerson', document.getElementById('contectpersonval').value);
          formData.append('ContactNumber', document.getElementById('mobileval').value);
          formData.append('CompanyName', document.getElementById('companynameval').value);
          formData.append('Email', document.getElementById('emailval').value);
          formData.append('MeetingStatus',document.getElementById('meetStatusval').value);
          formData.append('MeetingDescription',document.getElementById('meetDescriptionVal').value);
          formData.append('RequestID',userId);
          formData.append('UserID',userId);
          let url =window.UserbaseUrl+"/api/CompanyMeeting/InsertCompanyMeeting";
            axios({
              method: "post",
              url: url,
              data: formData,
              headers: { "Content-Type": "multipart/form-data" },
              })
              .then(function (response) {
                //handle success
                if(response.data.status == 1)
                {
                clear();
                 document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
                <strong>Success!</strong> Meets are added successfully.
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                </button>
                </div>`;
              }
              else{
                document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>Error!</strong> Meets are not added.
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                </button>
                </div>`;
              }
        
              })
            .catch(function (response) {
              //handle error
              console.log(response);
            });
          // const resp = await SavaMeets(sendData);
          // clear();
          // getDeal();
          // if(resp.status === 1){
          //   document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
          //   <strong>Success!</strong> Meets are added successfully.
          //   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
          //   </button>
          //   </div>`;
          // }
          // else{
          //   document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
          //   <strong>Error!</strong> Meets are not added.
          //   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
          //   </button>
          //   </div>`;
          // }
        }
      }
      let getCountry= async () => {
        let resp = await GetCountry();
        if(resp.status === 1)
        {
            CountryList(resp.responseValue);
            getState();
        }
      };

      let getState = async(e)=>{
        
        let resp = await GetState(e);
        if(resp.status === 1)
        {
            StateList(resp.responseValue);
        }
      }
      let getCity = async(e)=>{
        
        let resp = await GetCity(e);
        if(resp.status === 1)
        {
            CityList(resp.responseValue);
        }
      }
       let approveLead = async(e,s)=>{
        var userId = localStorage.getItem("userId");
        let apprstatus = document.getElementById('partnerstatus');
        let iconspart = document.getElementById('partnerstatusicon');
        let status ='';
        if(s == 0){
          status = 1;
        }
        
        
        const sendData = {
            'id' : e,
            "dealStatus": status,
            "userID" : userId
               }
               const resp = await UpdateLeadStatus(sendData);
               getDeal();
               if(resp.status === 1){
                   document.getElementById("successMsg").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong> Leads are registered successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
               }
       }
    
       let changeFronTDate = (e) =>{
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(e)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
        }
        let  searchTable = async()=> {
          // Declare variables
          var input, filter, table, tr, td, i, j, txtValue,found;
          input = document.getElementById("searchText");
          filter = input.value.toUpperCase();
          table = document.getElementById("tableData");
          tr = table.getElementsByTagName("tr");
        
          // Loop through all table rows, and hide those who don't match the search query
          for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td");
            for (j = 0; j < td.length; j++) {
                if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
                    found = true;
                }
            }
            if (found) {
                tr[i].style.display = "";
                found = false;
            } else {
                tr[i].style.display = "none";
            }
        }
        }
      useEffect(() => {
        getDeal();
        getCountry();
        getState();
        getCity();
        companyMeets();
        getProduct();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech -  Edit</title>
                <link rel="canonical" href="http://localhost:5000/leads" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
                <div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">All Leads</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href="/add-lead" className="btn btn-primary p-2 text-white"><i className="fas fa-plus"></i> Add Lead</a></li>
</ul>
</div>
</div>
</div>
</div>
<div className="row">
<div className="col-sm-12">
<div className="card card-table comman-shadow">
<div className="card-body">

<div className="page-header">
<div className="row align-items-center">
<span id="deleteMsg"></span><span id="successMsg"></span>
<div className="col-9">
    
<h3 className="page-title">My Leads</h3>
</div>
<div className="col-3 text-end float-end ms-auto download-grp">
<ul className='headerlist'><li className='headerlistitem'><div className="form-group">
<input type="text" className="form-control" placeholder="Search ..." id='searchText' name='searchText' onChange={()=>{searchTable();}}/>
</div></li>
</ul>
</div>

</div>
</div>

<div className="table-responsive fixTableHead">
<table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
<thead className="student-thread">
<tr>
<th>
#
</th>
<th>Contact Person</th>
<th>Company Name</th>
<th>Contact No.</th>
<th>Email Id</th>
<th>Status</th>
<th>Date</th>
<th className="text-end">Action</th>
</tr>
</thead>
<tbody id='tableData'>
{
deallists && deallists.length > 0 ?
deallists.map((val, ind) => { return ( val.dealStatus === 0 &&
<tr>
<td>
{ind+1}
</td>
<td>{val.contactPerson}</td>   
<td>{val.companyName}</td>  
<td>{val.mobile}</td>  
<td>{val.emailID}</td>     
<td> {val.dealStatus === 0 && <a className='btn-sm btn-info text-white'>Under Review</a>} </td> 
<td>{val.createdDate !== "" ? val.createdDate : '--'}</td>
<td className="text-end">
<div className="actions ">
  
{/* <a href="javascript:;" title='Assigned Lead' className="btn btn-sm bg-success-light me-2 " onClick={() => {approveLead(val.id,val.dealStatus);}}>
<i className="fa fa-check-circle"></i>
</a> */}
<a href="javascript:;" title='Covert to Deal' className="btn btn-sm bg-success-light me-2 " onClick={() => {approveLead(val.id,val.dealStatus);}}>
Convert to Deal
</a>
{/* <a href="javascript:;" data-bs-toggle="modal" onClick={() => {companyMeets(val.id);}} title='Company Meets' data-bs-target="#con-close-modal" className="btn btn-sm bg-danger-light">
<i className="feather-user"></i>
</a>&nbsp; */}
<a href={`/view-deal?id=${val.id}`} title="view" className="btn btn-sm bg-danger-light">
View 
</a>
<a href={`/edit-lead?id=${val.id}`} title="edit" className="btn btn-sm bg-danger-light">
Edit 
</a>
<a href="javascript:;" onClick={() => {removeDeal(val.id);}} className="btn btn-sm bg-danger-light">
Remove
</a>
</div>
</td>
</tr>
);
})
: <tr><td colspan="12" style={{textAlign :"center"}}>No results found</td></tr>
}
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div id="con-close-modal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display:"none", }}>
<div className="modal-dialog modal-lg">
<div className="modal-content">
<div className="modal-header">
<h4 className="modal-title">Customer Meeting</h4>
<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div className="modal-body p-4">
<div className="card comman-shadow">
<div className="card-body">
  <div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='addMeets' id='addMeets' onChange={handlechage}>
  <input type='hidden' id='dealid' name='dealid'/>
<div className="row">
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Contact Person <span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter Contact Person" name='contectpersonval' id="contectpersonval" onChange={handlechage}/>
<span id="errorNameval" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Email <span className="login-danger">*</span></label>
<input className="form-control" type="email" required placeholder="Enter email" name='emailval' id='emailval' onChange={handlechage} />
<span id="errorEmailval" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Mobile No. <span className="login-danger">*</span></label>
<input className="form-control" type="number" size="15" required placeholder="Enter mobile" name='mobileval' id='mobileval' onChange={handlechage}/>
<span id="errorMobileval" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Company Name <span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter company Name" name='companynameval' id="companynameval" onChange={handlechage} />
<span id="errorCompanyNameval" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Product <span className="login-danger">*</span></label>
<select className="form-control select" name="productval" id="productval" required onChange={handlechage}>
<option value={-1}>Select product</option>
{productDealList &&  productDealList.map((val, ind) => { return (<option value={val.productId}>{val.productName}</option>);
})} 
</select>
<span id="errorproductval" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Metting Status <span className="login-danger">*</span></label>
<select className="form-control select" name="meetStatusval" id="meetStatusval" required onChange={handlechage}>
<option value={-1}>Select status</option>
<option value="1">Not Started</option>
<option value="2">Progress</option>
<option value="3">Completed</option>
</select>
<span id="errormeetStatusval" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Meeting Description <span className="login-danger">*</span></label>
<textarea className="form-control" required placeholder="Enter Meeting Description." name='meetDescriptionVal' id='meetDescriptionVal' onChange={handlechage}></textarea>
<span id="errormeetDescriptionVal" class="errormsg"></span>
</div>
</div>
</div>
</form>
</div>
</div>
</div>
<div className="modal-footer">
<button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal">Close</button>
<button type="submit" className="btn btn-info waves-effect waves-light" name='saveMeets' id='saveMeets' onClick={()=>{addCompanyMeets();}}>Save Company Meets</button>
</div>
</div>
</div>
</div>
< Footer />

</div>
            </div>
    </React.Fragment>
    )
}

