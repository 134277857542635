
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import Footer from "../components/Footer";
// import Getdeal from "../API/Getdeal";
// import RemoveDeal from "../API/RemoveDeal";
// import GetCountry from "../API/GetCountry";
// import GetState from "../API/GetState";
// import GetCity from "../API/GetCity";
import ManageSidebar from '../components/ManageSidebar';
// import GetdealdyId from '../API/GetdealdyId';
// import UpdateLead from '../API/UpdateLead';
import GetPartner from '../API/GetPartner';
import ApprovePartner from '../API/ApprovePartner';
import RemovePartner from '../API/RemovePartner';

export default function Channelpartner(){  
  

    let [partnerlist, getPartner] = useState();


    // let [dealbyid,DealListById] = useState();
   
    let getChannelPartner = async () => {
        let resp = await GetPartner();
        if(resp.status === 1)
        {
            getPartner(resp.responseValue);  
        }
      };
      let removePartner = async (e) =>{
        let con = window.confirm('Are you sure want to delete this channel partner?');
        if(con == true)
        {
          const sendData = {
            "id": e,
               }
               const resp = await RemovePartner(sendData);
               getChannelPartner();
               if(resp.status === 1){
                   document.getElementById("deleteMsg").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong>Partner are deleted successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
               window.setTimeout(function() {

                document.getElementById("deleteMsg").style.display='none';
                window.location.href = '/channel-partner';
                }, 2000);
               }
              }
              else{
                return false;
              }
      }
  
     let clear = async () =>{
      document.getElementById("updateLead").reset();

  }
   
    
      let approvePartner = async(e,s,m) =>{
        var userId = localStorage.getItem("userId");
        
        let apprstatus = document.getElementById('partnerstatus');
        let iconspart = document.getElementById('partnerstatusicon');
        let status ='';
        if(s == 0){
          status = 1;
          apprstatus.setAttribute('title', 'Un approve');
          iconspart.setAttribute('class', 'fa fa-check-circle');
        }
        else{
          status = 0;
          apprstatus.setAttribute('title', 'Approve');
          iconspart.setAttribute('class', 'fa fa-times');
        }
        const sendData = {
          "id" : e,
          "emailID" : m,
          "userID" : userId,
          "approvalStatus" : status
          }
        
          const resp = await ApprovePartner(sendData);
          //clear();
          getChannelPartner();
          if(resp.status === 1){
            if(status == 1)
           { document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>Success!</strong> Partner has been approved successfully.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
            </div>`;
            window.setTimeout(function() {

              document.getElementById("textMessage").style.display='none';
              window.location.href = '/channel-partner';
              }, 2000);

          }
          else{
            { document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>Success!</strong> Partner has been Un approved successfully.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
            </div>`;
            window.setTimeout(function() {
              window.location.href = '/channel-partner';
              document.getElementById("textMessage").style.display='none';
              }, 2000);
          }
          }
          }
          else{
            document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>Error!</strong> Dealer are not approved.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
            </div>`;
          }
        }
      
       let changeFronTDate = (e) =>{
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(e)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
        }
        let  searchTable = async()=> {
          // Declare variables
          var input, filter, table, tr, td, i, j, txtValue,found;
          input = document.getElementById("searchText");
          filter = input.value.toUpperCase();
          table = document.getElementById("tableData");
          tr = table.getElementsByTagName("tr");
        
          // Loop through all table rows, and hide those who don't match the search query
          for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td");
            for (j = 0; j < td.length; j++) {
                if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
                    found = true;
                }
            }
            if (found) {
                tr[i].style.display = "";
                found = false;
            } else {
                tr[i].style.display = "none";
            }
        }
        }
      useEffect(() => {
        getChannelPartner();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Channel Partner List</title>
                <link rel="canonical" href="http://localhost:5000/dealers" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
                <div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Channel Partner</h3>
<ul className="breadcrumb">
{/* <li className="breadcrumb-item active">All Channel Partner</li> */}
</ul>
</div>
</div>
</div>
</div>

<div className="row">
<div className="col-sm-12">
<div className="card card-table comman-shadow">
<div className="card-body">

<div className="page-header">
<div className="row align-items-center">

<div className="col-9">
    
<h3 className="page-title">My Channel Partner</h3>
</div>
<div className="col-3 text-end float-end ms-auto download-grp">
<ul className='headerlist'>

  <li className='headerlistitem' ><div className="form-group">
  
<input type="text" className="form-control" placeholder="Search ..." id='searchText' name='searchText' onChange={()=>{searchTable();}}/>
</div></li>
</ul>
</div>
</div>
</div>

<div className="table-responsive fixTableHead">
<div id='textMessage'></div><div id='errorMessage'></div><span id="deleteMsg"></span>
<table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
<thead className="student-thread">
<tr>
<th>
#
</th>
<th>Contact Person</th>
<th>Company Name</th>
<th>Contact No.</th>
<th>Email Id</th>
<th>Website</th>
<th>Status</th>
<th>Enquiry Date</th>
<th className="text-end">Action</th>
</tr>
</thead>
<tbody id="tableData">
{
partnerlist && partnerlist.length > 0 ?
partnerlist.map((val, ind) => { return (
<tr>
<td>
{ind+1}
</td>
<td>{val.contactPerson}</td>   
<td>{val.companyName}</td>  
<td>{val.mobile}</td>  
<td>{val.emailID}</td>   
<td>{val.website}</td>  
<td>{val.approvalStatus == '0' && "Pending"} {val.approvalStatus == '1' && "Approve"} </td> 
<td>{val.createdDate}</td>  
<td className="text-end">
<div className="actions ">
{/* <a href="javascript:;" className="btn btn-sm bg-success-light me-2 ">
<i className="feather-eye"></i>
</a> */}

<a href="javascript:;" id="partnerstatus" title='Approve' onClick={() => {approvePartner(val.id,val.approvalStatus,val.emailID);}} className="btn btn-sm bg-danger-light">
<i id="partnerstatusicon" class="fa fa-check-circle"></i>
</a>&nbsp;

<a href="javascript:;" title='Remove' onClick={() => {removePartner(val.id);}} className="btn btn-sm bg-danger-light">
<i className="feather-trash"></i>
</a>
</div>
</td>
</tr>
);
})
: <tr><td colspan="12" style={{textAlign :"center"}}>No results found</td></tr>
}
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>

< Footer />

</div>
            </div>
    </React.Fragment>
    )
}

