
import React , { useEffect, useState } from 'react'; 
import {Helmet} from "react-helmet";
import Footer from "../components/Footer";
import ManageSidebar from '../components/ManageSidebar';
import ManageHeader from "../components/ManageHeader";
import Getdeal from "../API/Getdeal";
import Getproduct from "../API/Getproduct";
import GetPartner from '../API/GetPartner';
import GetTraning from '../API/GetTraning';
//import getToken from '../layouts/firebase';


export default function Admindashboard(){  
    const [userName, setUser] = useState(); 
    const [totallead, totalDeal] = useState();
    const [totalproduct, settotalProduct] = useState();
    const [totalpartner, getTotalPartner] = useState();
    const [totaltrainee, getTotalTrainingList] = useState();

 

    let gettotalDeal = async () => {
        var userId = localStorage.getItem("userId");
          let resp = await Getdeal(userId);
          if(resp.status === 1)
          {
              totalDeal(resp.responseValue.length);  
          }
        };

        let totalProduct = async () => {
            let resp = await Getproduct();
            if(resp.status === 1)
            {
                settotalProduct(resp.responseValue.length);  
            }
          };
          // total partner
          let getTotalChannelPartner = async () => {
            let resp = await GetPartner();
            if(resp.status === 1)
            {
                getTotalPartner(resp.responseValue.length);  
            }
          };
          // total tarinee
          let getTotalTraining = async () => {
            var userId = localStorage.getItem("userId");
              let resp = await GetTraning(userId);
              if(resp.status === 1)
              {
                  getTotalTrainingList(resp.responseValue.length);  
              }
            };
    useEffect(() => {
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
        setUser(pageView);
        gettotalDeal();
        totalProduct();
        getTotalChannelPartner();
        getTotalTraining();
      }, []); 
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Admin Dashboard</title>
                <link rel="canonical" href="http://localhost:5000/admin-bashboard" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar/>
            <div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Welcome Jonathan!</h3>
<ul className="breadcrumb">

<li className="breadcrumb-item">Admin Dashboard</li>
</ul>
</div>
</div>
</div>
</div>


<div className="row">
<div className="col-xl-3 col-sm-6 col-12 d-flex">
<a href='/channel-partner' style={{"width":"100%"}}>
<div className="card bg-comman w-100">
<div className="card-body">
<div className="db-widgets d-flex justify-content-between align-items-center">
<div className="db-info">
<h6>Total Partner</h6>
<h3>{totalpartner}</h3>
</div>
<div className="db-icon">
<i className="fas fa-calendar-day"></i>
</div>
</div>
</div>
</div>
</a>
</div>
<div className="col-xl-3 col-sm-6 col-12 d-flex">
<a href='/leads' style={{"width":"100%"}}>
<div className="card bg-comman w-100">
<div className="card-body">
<div className="db-widgets d-flex justify-content-between align-items-center">
<div className="db-info">
<h6>Total Leads</h6>
<h3>{totallead}</h3>
</div>
<div className="db-icon">
<i className="fas fa-chalkboard-teacher"></i>
</div>
</div>
</div>
</div>
</a>
</div>
<div className="col-xl-3 col-sm-6 col-12 d-flex">
<a href='/products' style={{"width":"100%"}}>
<div className="card bg-comman w-100">
<div className="card-body">
<div className="db-widgets d-flex justify-content-between align-items-center">
<div className="db-info">
<h6>Total Product</h6>
<h3>{totalproduct}</h3>
</div>
<div className="db-icon">
<i className="fa fa-cart-plus"></i>
</div>
</div>
</div>
</div>
</a>
</div>

<div className="col-xl-3 col-sm-6 col-12 d-flex">
<a href='/training' style={{"width":"100%"}}>
<div className="card bg-comman w-100">
<div className="card-body">
<div className="db-widgets d-flex justify-content-between align-items-center">
<div className="db-info">
<h6>Total Training</h6>
<h3>{totaltrainee}</h3>
</div>
<div className="db-icon">
<i className="fas fa-book-reader"></i>
</div>
</div>
</div>
</div>
</a>
</div>
</div>

</div>

<Footer />

</div>
            </div>
    </React.Fragment>
    )
}

