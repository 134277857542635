
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";

import SaveDesignation from "../API/SaveDesignation";
import ManageSidebar from '../components/ManageSidebar';
//import Footer from "../components/Footer";

export default function Adddesignation(){  

    let [designationname,setDesignationName] = useState();
  
    let clear = async () =>{
      document.getElementById("addPartnerType").reset();
  }
    
let submitDesignation = async() =>{
  var userId = localStorage.getItem("userId");
  if(document.getElementById('designationname').value === ""){
    document.getElementById("errorName").innerText = 'Enter designation name';
  }
  else{
  const sendData = {
    "designation": designationname,
    "userId" : userId
    }
    
    const resp = await SaveDesignation(sendData);
    console.log(resp);
    clear();
    if(resp.status === 1){
      document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
		  <strong>Success!</strong> Designation are added successfully.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
    }
    else{
      document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
		  <strong>Error!</strong> Designation are not added.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
    }
  }
}

let handlechage = async (e) => {
  //clear();
  let name = e.target.name;
  let value = e.target.value;
  if (name === 'designationname') {
      setDesignationName(value)
  }

  };
     
      useEffect(() => {
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }

      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Add Designation </title>
                <link rel="canonical" href="http://localhost:5000/add-designation" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
              
<div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row align-items-center">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Add Designation</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href="/designation-master" className="btn btn-primary p-2 text-white">All Designation </a></li>
</ul>
</div>
</div>
</div>
</div>

<div className="row">
<div className="col-sm-12">
<div className="card comman-shadow">
<div className="card-body">
  <div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='addPartnerType'  id='addPartnerType' enctype="multipart/form-data"> 
<div className="row">
<div className="col-12 col-sm-12">
<h5 className="form-title student-info">Designation Information <span><a href="javascript:;"><i className="feather-more-vertical"></i></a></span></h5>
</div>

<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Designation Name <span className="login-danger">*</span></label>
<input className="form-control" style={{"text-transform":"capitalize"}} type="text" required placeholder="Enter designation name" name='designationname' id="designationname" onChange={handlechage}/>
<span id="errorName" className='errormsg'></span>
</div>
</div>
<div className="col-12">
<div className="student-submit">
<button type="submit" name='saveProduct' id='saveProduct' onClick={()=>{submitDesignation();}} className="btn btn-primary">Submit</button>
</div>
</div>

</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
            </div>
    </React.Fragment>
    )
}

