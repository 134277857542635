
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";

import SaveIndustryType from "../API/SaveIndustryType";
import ManageSidebar from '../components/ManageSidebar';
//import Footer from "../components/Footer";

export default function AddIndustryType(){  

    let [industrytypename,setIndustryTypeName] = useState();
    
    let [description, setDescription] = useState();
    let clear = async () =>{
      document.getElementById("addPartnerType").reset();
  }
    
let submitIndustryType = async() =>{
  var userId = localStorage.getItem("userId");
  if(document.getElementById('industrytypename').value === ""){
    document.getElementById("errorName").innerText = 'Enter partner type name';
  }
 else if(document.getElementById('description').value === ""){
    document.getElementsByClassName("errorDescription").innerText = 'Enter description';
  }
  
  else{
  const sendData = {
    "industryType": industrytypename,
    "description": description,
    "userID" : userId
    }
    
    const resp = await SaveIndustryType(sendData);
    console.log(resp);
    clear();
    if(resp.status === 1){
      document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
		  <strong>Success!</strong> Industry Type are added successfully.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
    }
    else{
      document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
		  <strong>Error!</strong> Industry Type are not added.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
    }
  }
}

let handlechage = async (e) => {
  //clear();
  let name = e.target.name;
  let value = e.target.value;
  if (name === 'industrytypename') {
      setIndustryTypeName(value)
  }
  if (name === 'description') {
    setDescription(value)
  }

  };
     
      useEffect(() => {
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }

      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Add Partner Type </title>
                <link rel="canonical" href="http://localhost:5000/add-partner-type" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
              
<div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row align-items-center">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Add Industry Type</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active">Add Partner Type</li>
</ul>
</div>
</div>
</div>
</div>

<div className="row">
<div className="col-sm-12">
<div className="card comman-shadow">
<div className="card-body">
  <div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='addPartnerType'  id='addPartnerType' enctype="multipart/form-data"> 
<div className="row">
<div className="col-12 col-sm-12">
<h5 className="form-title student-info">Industry Type Information <span><a href="javascript:;"><i className="feather-more-vertical"></i></a></span></h5>
</div>

<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Industry Type Name <span className="login-danger">*</span></label>
<input className="form-control" style={{"text-transform":"capitalize"}} type="text" required placeholder="Enter industry type name" name='industrytypename' id="industrytypename" onChange={handlechage}/>
<span id="errorName" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Description <span className="login-danger">*</span></label>
<textarea className="form-control" required placeholder="Enter description" style={{"height":"207px"}} name='description' id='description' onChange={handlechage} ></textarea>
<span id="errorDescription" className='errormsg'></span>
</div>
</div>
<div className="col-12">
<div className="student-submit">
<button type="submit" name='saveProduct' id='saveProduct' onClick={()=>{submitIndustryType();}} className="btn btn-primary">Submit</button>
</div>
</div>

</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
            </div>
    </React.Fragment>
    )
}

