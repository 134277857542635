import React , { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom'; 
export default function AdminSidebar() {  
const [userRole, setRole] = useState(); 

useEffect(() => {
    var role = window.localStorage.getItem("userRole");
    setRole(role);
  }, []);  
        return (
            <React.Fragment>  
                
<div className="sidebar" id="sidebar">
<div className="sidebar-inner slimscroll">
<div id="sidebar-menu" className="sidebar-menu">
<ul>
<li className="menu-title">
        <span>Main Menu</span>
        </li>
        <li className="submenu" >
        <Link to="/admin-dashboard"><i className="feather-grid"></i> <span> Dashboard</span> </Link>
        </li>
        <li className="menu-title">
        <span>Master</span>
        </li>
       
        <li className="submenu">
        <Link to="/industry-type-master"><i className="fas fa-chalkboard-teacher"></i> <span> Industry Type Master</span> </Link>
        </li>
                <li className="submenu">
        <Link to="/products"><i className="fa fa-cart-plus"></i> <span> Products Master</span></Link>
        </li>
        <li className="submenu">
        <Link to="/product-industry"><i className="fas fa-chalkboard-teacher"></i> <span> Product Industry Master</span> </Link>
        </li>
        <li className="submenu">
        <Link to="/designation-master"><i className="fas fa-chalkboard-teacher"></i> <span> Designation Master</span> </Link>
        </li>

        <li className="submenu">
        <Link to="/partner-type"><i className="fa fa-cart-plus"></i> <span> Partner Type Master</span></Link>
        </li>
        <li className="submenu">
        <Link to="/product-material"><i className="fa fa-cart-plus"></i> <span>Product Material</span></Link>
        </li>
        <li className="menu-title">
        <span>Leads</span>
        </li>
        <li className="submenu">
        <Link to="/leads"><i className="fas fa-chalkboard-teacher"></i> <span> Leads Master</span> </Link>
        </li>
        <li className="submenu">
        <Link to="/register-deal"><i className="fas fa-chalkboard-teacher"></i> <span> Register Deals</span> </Link>
        </li>
        <li className="menu-title">
        <span>User</span>
        </li>
        <li className="submenu">
        <Link to="/user"><i className="fas fa-chalkboard-teacher"></i> <span> User</span> </Link>
        </li>
        <li className="submenu">
        <Link to="/channel-partner"><i className="fas fa-chalkboard-teacher"></i> <span> Channel Partner</span> </Link>
        </li>
        
        {/* <li className="submenu">
        <a href="#"><i className="fas fa-calendar-day"></i> <span> Events</span></a>
        </li> */}
        
        <li className="submenu">
        <Link to="/training"><i className="fas fa-book-reader"></i> <span> Training</span> </Link>
        </li>
</ul>
</div>
</div>
</div>
    </React.Fragment>
    )
}