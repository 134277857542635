
import React , { useEffect, useState } from 'react';
import { useRef } from "react";
import { toPng } from 'html-to-image';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import ManageSidebar from '../components/ManageSidebar';

export default function Downloadcertificate(){  
        
const [companyName, setCompanyName] = useState(); 
const  [customerId, setCustomerId] = useState();
const [registerdate, setRegisterdate] = useState();
const [certificatetilldate, setCertificateTillDate] = useState(); 
const elementRef = useRef(null);

// text to image
    const htmlToImageConvert = () => {
      toPng(elementRef.current, { cacheBust: false })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = companyName;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //let now = new Date(localStorage.getItem("enquiryDate")).toLocaleDateString('en-us', { date:"long",month:"long"});
//console.log(now)
    function getMonthName(monthNumber) {
        const date = new Date()
        date.setMonth(monthNumber) // starts with 0, so 0 is January
        return date.toLocaleString('en-EN', { month: "long" })
      }
      let changeFronTDate = (e) =>{
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(e)
        return [pad(d.getDate()), pad(getMonthName(d.getMonth())), d.getFullYear()].join('-')
        }
        let certificateTillDate = (e) =>{
          function pad(s) { return (s < 10) ? '0' + s : s; }
          var d = new Date(e)
          return [pad(d.getDate()), pad(getMonthName(d.getMonth())), (d.getFullYear()+1)].join('-')
          }
    useEffect(() => {
        var pageView = localStorage.getItem("userName");
        setRegisterdate(changeFronTDate(localStorage.getItem("enquiryDate")));
        setCertificateTillDate(certificateTillDate(localStorage.getItem("enquiryDate")));
        var role = localStorage.getItem("userRole");
        var companyName = localStorage.getItem("companyName");
        var customerId = localStorage.getItem("customerId");
        setCustomerId(customerId);
        if(pageView == null && role == null){
            window.location.href = '/admin'; 
        }
        else {
        setCompanyName(companyName);
    }
    getMonthName(1);
    document.addEventListener('contextmenu', event => event.preventDefault());
    document.addEventListener('keydown', event => {
      console.log(`User pressed: ${event.key}`);
    
      if (event.key === 'F12') {
        event.preventDefault();
        return false;
      }
    });
    var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
       
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
      }, []); 
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Download Certificate</title>
                <link rel="canonical" href="http://localhost:5000/download-certificate" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar/>
<div className="page-wrapper">
<div className="content container-fluid">
<div className="row justify-content-center">
<div className="col-xl-10">
<div className="card invoice-info-card">
<div className="card-body">

  <button onClick={htmlToImageConvert} className="btn print-btnn">
    <i className="fa fa-print fa-lg"></i> <i className="fa fa print"></i>Download Certificate</button>
  <div id='printMe' ref={elementRef}>
    <div className="my-width">
      <div className="full-backgrodd">

        <div className="full-centr">
          <div className="company-name">
            <h1>{companyName}</h1>
            <img src="../assets/img/certificate-assets/line.png" className="img-fluid" alt="..line."/>
            <h5>({customerId})</h5>
          </div>
          <div className="paragrapha">
            <p>Has fulfilled the Program Prerequisites and Business requirements to qualify as a Registered Partner<br></br>  for the Criterion Tech Partner Program for Fiscal Year 2024.</p>
          </div>
          <div className="footer-content">
            <div className="sign">
              <img src="../assets/img/certificate-assets/sign.png" alt="shild"/>
              <h6>Authorised Signature<br />Criterion Tech
              </h6>
            </div>
            <div className="date">
              <img src="../assets/img/certificate-assets/sheild.png" alt="shild"/>
              <h6>{registerdate} - {certificatetilldate}</h6>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>


    </React.Fragment>
    )
}

