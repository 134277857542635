
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import Footer from "../components/Footer";
import GetRegisterdeal from "../API/GetRegisterdeal";
import RemoveDeal from "../API/RemoveDeal";
import GetCountry from "../API/GetCountry";
import GetState from "../API/GetState";
import GetCity from "../API/GetCity";
import ManageSidebar from '../components/ManageSidebar';
import GetdealdyId from '../API/GetdealdyId';
import UpdateLead from '../API/UpdateLead';
import UpdateLeadStatus from '../API/UpdateLeadStatus';

export default function Registerdeal(){  
  const [userRole, setRole] = useState();
  let [country,CountryList] = useState();
  let [state, StateList] = useState();
  let [city, CityList] = useState();
  let [contectpersonval, setContectpersonval] = useState();
  let [emailval, setEmailval] = useState("");
  let [mobileval, setMobileval] = useState();
  let [companyNameval, setCompanynameval] = useState();
  let [companycontactval,setCompanycontactval] = useState();
  let [countrynameval, setCountry] = useState();
  let [statenameval, setState] = useState();
  let [citynameval, setCity] = useState();
  let [companyaddressval, setCompanyaddress] = useState();
  let [postalcodeval, setPostalcodeval] = useState("");
  let [websiteval, setWebsite] = useState();

  let [noofemployeeval, setPerson] = useState();
  let [noofexcustomerval, setNoofexcustomer] = useState();
  let [industryval, setIndustry] = useState();
  let [dealid,setDealid] = useState();
  let [delstatus,setDealstatus]  = useState();
  let [leadId,setDealids] = useState();

    let [deallist, setDeal] = useState();

    let [dealbyid,DealListById] = useState();
    let registerDeal = async () => {
      var userId = localStorage.getItem("userId");
      let resp = await GetRegisterdeal(userId);
        if(resp.status === 1)
        {
            setDeal(resp.responseValue);  
        }
      };
      let removeDeal = async (e) =>{
        const sendData = {
            "id": e,
               }
               const resp = await RemoveDeal(sendData);
               registerDeal();
               if(resp.status === 1){
                   document.getElementById("deleteMsg").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong>Deal are deleted successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
               }
      }
      let editDeal = async(e) =>{
        const resp = await GetdealdyId(e);
        if(resp.status === 1)
         {
          document.getElementById('dealid').value = resp.responseValue[0].id;
          document.getElementById('contectpersonval').value = resp.responseValue[0].contactPerson;
          document.getElementById('emailval').value = resp.responseValue[0].emailID;
          document.getElementById('mobileval').value = resp.responseValue[0].mobile;
          document.getElementById('companycontactval').value = resp.responseValue[0].companyContact;
          document.getElementById('companynameval').value = resp.responseValue[0].companyName;
          document.getElementById('countryval').value = resp.responseValue[0].companyCountryID;
          document.getElementById('companyaddressval').value = resp.responseValue[0].companyAddress;
          document.getElementById('postalcodeval').value = resp.responseValue[0].companyZipCode;
          document.getElementById('websiteval').value = resp.responseValue[0].website;

          document.getElementById('noofemployeeval').value = resp.responseValue[0].noOfEmployee;
          document.getElementById('noofexcustomerval').value = resp.responseValue[0].isExixtingCustomer;
          document.getElementById('industryval').value = resp.responseValue[0].industryTypeID;
         }
 
     }
     let clear = async () =>{
      document.getElementById("updateLead").reset();

  }
   
    
      let handlechage = async (e) => {
        //clear();
        let name = e.target.name;
        let value = e.target.value;
        if(name === 'addDeal'){
          setDealid(value)
        }
        if (name === 'contectpersonval') {
       
          setContectpersonval(value)
          
      }
      if (name === 'emailval') {
        setEmailval(value)
      }
      if (name === 'mobileval') {
        setMobileval(value)
       }
       if (name === 'companynameval') {
        setCompanynameval(value)
       }
       if(name === 'companycontactval'){
        setCompanycontactval(value)
       }
        if (name === 'countryval') {
            getState(value)
            setCountry(value);
        }
        if (name === 'stateval') {
          getCity(value);
          setState(value);
          }
          if (name === 'cityval') {
            setCity(value);
        }
        if (name === 'companyaddressval') {
          setCompanyaddress(value)
        }
        if (name === 'postalcodeval') {
          setPostalcodeval(value)
        }
        if (name === 'websiteval') {
          setWebsite(value)
        }
        if (name === 'noofemployeeval') {
          setPerson(value)
        }
        if (name === 'noofexcustomerval') {
          setNoofexcustomer(value)
        }
        if (name === 'industryval') {
          setIndustry(value)
        }
    
        
      };
      let updateDeal = async() =>{
        var userId = localStorage.getItem("userId");
        if(document.getElementById('contectpersonval').value === ""){
          document.getElementById("errorNameval").innerText = 'Enter name';
        }
       else if(document.getElementById('emailval').value === ""){
          document.getElementsByClassName("errorEmailval").innerText = 'Enter email';
        }
        else if(document.getElementById('mobileval').value === ""){
          document.getElementsByClassName("errorMobileval").innerText = 'Enter mobile no';
        }
        else if(document.getElementById('companycontactval').value === ""){
          document.getElementsByClassName("errorCompanyMobileval").innerText = 'Enter company no';
        }
        else if(document.getElementById('companynameval').value === ""){
          document.getElementsByClassName("errorCompanyNameval").innerText = 'Enter company name';
        }
        else if(document.getElementById('countryval').value === ""){
          document.getElementsByClassName("errorCountryval").innerText = 'select country';
        }
        else if(document.getElementById('stateval').value === ""){
          document.getElementsByClassName("errorStateval").innerText = 'select state';
        }
        else if(document.getElementById('cityval').value === ""){
          document.getElementsByClassName("errorCityval").innerText = 'select city';
        }
        else{
        const sendData = {
          "id" : document.getElementById('dealid').value,
          "emailID": document.getElementById('emailval').value,
          "contactPerson": document.getElementById('contectpersonval').value,
          "mobile" : document.getElementById('mobileval').value,
          "companyName" : document.getElementById('companynameval').value,
          "companyCountryID" : document.getElementById('countryval').value,
          "companyStateID" : document.getElementById('stateval').value,
          "companyCityID" : document.getElementById('cityval').value,
          "companyZipCode" : document.getElementById('postalcodeval').value,
          "companyAddress" : document.getElementById('companyaddressval').value,
          "companyContact" : document.getElementById('companycontactval').value,
          "website" : document.getElementById('websiteval').value,
          "noOfEmployee" : document.getElementById('noofemployeeval').value,
          "userID" : userId,
          "isExixtingCustomer" : document.getElementById('noofexcustomerval').value,
          "industryTypeID" : document.getElementById('industryval').value
          }
        
          const resp = await UpdateLead(sendData);
          clear();
          registerDeal();
          if(resp.status === 1){
            document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>Success!</strong> Dealer are added successfully.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
            </div>`;
          }
          else{
            document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>Error!</strong> Dealer are not added.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
            </div>`;
          }
        }
      }
      let getCountry= async () => {
        let resp = await GetCountry();
        if(resp.status === 1)
        {
            CountryList(resp.responseValue);
            getState();
        }
      };

      let getState = async(e)=>{
        
        let resp = await GetState(e);
        if(resp.status === 1)
        {
            StateList(resp.responseValue);
        }
      }
      let getCity = async(e)=>{
        
        let resp = await GetCity(e);
        if(resp.status === 1)
        {
            CityList(resp.responseValue);
        }
      }
      let editDealResion = async(e,s) =>{
             const resp = await GetdealdyId(e);
        if(resp.status === 1)
         {
          document.getElementById('leadId').value = resp.responseValue[0].id;
          document.getElementById('dealStatus').value = s;
          setDealstatus(s);
          setDealids(resp.responseValue[0].id)
         }
      }
      let approveDeal = async(e,s)=>{
        var userId = localStorage.getItem("userId");
        let sendData;
        if(s != 0 || s != 4){
          sendData = {
            'id' : e,
            "dealStatus": s,
            'reason' : document.getElementById('resionval').value,
            "userID" : userId
               }
        }
        else{
        sendData = {
            'id' : e,
            "dealStatus": s,
            "userID" : userId
               }
              }
               const resp = await UpdateLeadStatus(sendData);
               registerDeal();
               if(resp.status === 1){
                   document.getElementById("successMsg").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong> Deal are updated successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
               
               setTimeout(function() {
                document.getElementById("successMsg").innerHTML = "";
                window.location.href = '/register-deal';
            }, 500);
               }
       }
    
       let changeFronTDate = (e) =>{
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(e)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
        }
        let  searchTable = async()=> {
          // Declare variables
          var input, filter, table, tr, td, i, j, txtValue,found;
          input = document.getElementById("searchText");
          filter = input.value.toUpperCase();
          table = document.getElementById("tableData");
          tr = table.getElementsByTagName("tr");
        
          // Loop through all table rows, and hide those who don't match the search query
          for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td");
            for (j = 0; j < td.length; j++) {
                if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
                    found = true;
                }
            }
            if (found) {
                tr[i].style.display = "";
                found = false;
            } else {
                tr[i].style.display = "none";
            }
        }
        }
      useEffect(() => {
        registerDeal();
        getCountry();
        getState();
        getCity();
        editDeal();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        setRole(role);
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Register Deals</title>
                <link rel="canonical" href="http://localhost:5000/dealers" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
                <div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
{/* <div className="btn-group dropstart">
  <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
    Dropstart
  </button>
  <ul className="dropdown-menu">
  <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><a className="dropdown-item" href="#">Something else here</a></li>
  </ul>
</div> */}
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">All Register Deals</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href="/add-register-lead" className="btn btn-primary p-2 text-white"><i className="fas fa-plus"></i> Register Deal</a></li>
</ul>
</div>
</div>
</div>
</div>

{/* <div className="student-group-form">
<div className="row">
<div className="col-lg-3 col-md-6">
<div className="form-group">
<input type="text" className="form-control" placeholder="Search by ID ..."/>
</div>
</div>
<div className="col-lg-3 col-md-6">
<div className="form-group">
<input type="text" className="form-control" placeholder="Search by Name ..."/>
</div>
</div>
<div className="col-lg-4 col-md-6">
<div className="form-group">
<input type="text" className="form-control" placeholder="Search by Phone ..."/>
</div>
</div>
<div className="col-lg-2">
<div className="search-student-btn">
<button type="btn" className="btn btn-primary">Search</button>
</div>
</div>
</div>
</div> */}
<div className="row">
<div className="col-sm-12">
<div className="card card-table comman-shadow">
<div className="card-body">

<div className="page-header">
<div className="row align-items-center">
<span id="deleteMsg"></span><span id="successMsg"></span>
<div className="col-9">

<h3 className="page-title">My Register Deals</h3>
</div>
<div className="col-3 text-end float-end ms-auto download-grp">
<ul className='headerlist'><li className='headerlistitem'><div className="form-group">
<input type="text" className="form-control" placeholder="Search ..." id='searchText' name='searchText' onChange={()=>{searchTable();}}/>
</div></li>
</ul>
</div>

</div>
</div>

<div className="table-responsive fixTableHead">
<table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
<thead className="student-thread">
<tr>
<th>
#
</th>
<th>Contact Person</th>
<th>Company Name</th>
<th>Contact No.</th>
<th>Email Id</th>
<th>Website</th>
<th>Status</th>
<th>Enquiry Date</th>
<th className="text-end">Action</th>
</tr>
</thead>
<tbody id='tableData'>
{
deallist && deallist.length > 0 ?
deallist.map((val, ind) => { if(val.dealStatus > 0) { { return  (
  
<tr>
<td>
{ind+1}
</td>
<td>{val.contactPerson}</td>   
<td>{val.companyName}</td>  
<td>{val.mobile}</td>  
<td>{val.emailID}</td>   
<td>{val.website}</td>  
<td> 
  {val.dealStatus === 1 && <a className='btn-sm btn-info text-white'>Under Review</a>}
  {val.dealStatus === 3 && <a className='btn-sm btn-danger text-white'>Rejected</a>}
  {val.dealStatus === 4 && <a className='btn-sm btn-success text-white'>Approved</a>}
</td> 
<td>{val.createdDate !== "" ? val.createdDate : '--'}</td>  
<td className="text-end">
<div className="actions ">
{userRole === "Channel Partner" ? (
                                        ""
                                      ) : (
                                        <>
<div className="btn-group dropstart">
<a href="" title="view" data-bs-toggle="dropdown" aria-expanded="false">
<i className="feather-more-vertical"></i>
</a>&nbsp;
<ul className="dropdown-menu letftmensec">
  {/* <li><a className="dropdown-item" href="javascript:;" data-bs-toggle="modal" data-bs-target="#deal-status-modal" onClick={() => {editDealResion(val.id,2);}}><i className="feather-search"></i> Under Review</a></li> */}
  
 
 
  <li><a className="dropdown-item" href="javascript:;" data-bs-toggle="modal" data-bs-target="#deal-status-modal" onClick={() => {editDealResion(val.id,3);}}><i className="feather-x"></i> Rejected</a></li>
  <li><a className="dropdown-item" href="#" onClick={() => {approveDeal(val.id,4);}}><i className="feather-check"></i> Approved</a></li>
  </ul>
  </div>
  </>)}
  <a href={`/edit-registerdeal?id=${val.id}`} title="edit" className="btn btn-sm bg-danger-light">
  Edit
</a>
  <a href={`/view-deal?id=${val.id}`} title="view" className="btn btn-sm bg-danger-light">
View
</a>&nbsp;
<a href="javascript:;" onClick={() => {removeDeal(val.id);}} className="btn btn-sm bg-danger-light">
Remove
</a>
</div>
</td>
</tr>
);
}} })
: <tr><td colspan="12" style={{textAlign :"center"}}>No results found</td></tr>
}
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
{/* update lead */}
<div id="con-close-modal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display:"none", }}>
<div className="modal-dialog modal-lg">
<div className="modal-content">
<div className="modal-header">
<h4 className="modal-title">Update Leads</h4>
<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div className="modal-body p-4">
<div className="card comman-shadow">
<div className="card-body">
  <div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='updateLead' id='updateLead' onChange={handlechage}>
  <input type='hidden' id='dealid' name='dealid'/>
<div className="row">
<div className="col-12">
<h5 className="form-title student-info">Leads Information <span><a href="javascript:;"><i className="feather-more-vertical"></i></a></span></h5>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Contact Person <span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter name" name='contectpersonval' id="contectpersonval" onChange={handlechage}/>
<span id="errorNameval"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Email <span className="login-danger">*</span></label>
<input className="form-control" type="email" required placeholder="Enter email" name='emailval' id='emailval' onChange={handlechage} />
<span id="errorEmailval"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Mobile No. <span className="login-danger">*</span></label>
<input className="form-control" type="number" size="15" required placeholder="Enter mobile" name='mobileval' id='mobileval' onChange={handlechage}/>
<span id="errorMobileval"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Company Contact No. <span className="login-danger">*</span></label>
<input className="form-control" type="number" size="15" required placeholder="Enter Company Contact no." name='companycontactval' id='companycontactval' onChange={handlechage}/>
<span id="errorCompanyMobileval"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Company Name <span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter company Name" name='companynameval' id="companynameval" onChange={handlechage} />
<span id="errorCompanyNameval"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Country <span className="login-danger">*</span></label>
<select className="form-control select" name="countryval" id="countryval" required onChange={handlechage}>
<option value={-1}>Select Country</option>
{country &&  country.map((val, ind) => { return (<option value={val.id}>{val.countryName}</option>);
})} 
</select>
<span id="errorCountryval"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>State <span className="login-danger">*</span></label>
<select className="form-control select" name="stateval" required id="stateval" onChange={handlechage}>
<option value={-1}>Select state</option>
{state &&  state.map((val, ind) => { return (<option value={val.id}>{val.stateName}</option>);
})} 
</select>
<span id="errorStateval"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>City <span className="login-danger">*</span></label>
<select className="form-control select" name='cityval' required id="cityval" onChange={handlechage}>
<option>Select city</option>
{city &&  city.map((val, ind) => { return (<option value={val.id}>{val.name}</option>);
})} 
</select>
<span id="errorCityval"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms ">
<label>Company Address.<span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter company adddrss" name='companyaddressval' id='companyaddressval' onChange={handlechage}/>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms ">
<label>Postal Code/zip code.<span className="login-danger">*</span></label>
<input className="form-control" type="text" maxlength="6" size="6" required placeholder="postal code" name='postalcodeval' id='postalcodeval' onChange={handlechage}/>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms ">
<label>Website<span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter company contact" name='websiteval' id='websiteval' onChange={handlechage} />
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>No of Employee </label>
<input className="form-control" type="text" placeholder="No. of employee" name='noofemployeeval' id="noofemployeeval" onChange={handlechage} />
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Exixting Customer </label>
<input className="form-control" type="text" placeholder="No. of exixting Customer" name='noofexcustomerval' id="noofexcustomerval" onChange={handlechage} />
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Industry <span className="login-danger">*</span></label>
<select className="form-control select" name='industryval' required id="industryval" onChange={handlechage}>
<option value={-1}>Please Select Industry </option>
<option value="1">IT</option>
<option value="2">Realstate</option>
<option value="3">Medical</option>
<option value="4">Other</option>
</select>
</div>
</div>

</div>
</form>
</div>
</div>
</div>
<div className="modal-footer">
<button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal">Close</button>
<button type="submit" className="btn btn-info waves-effect waves-light" name='updateDeal' id='updateDeal' onClick={()=>{updateDeal();}}>Save changes</button>
</div>
</div>
</div>
</div>
{/* update lead status */}
<div
            id="deal-status-modal"
            className="modal fade"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
            style={{ display: "none" }}
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Add Reason</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body p-1">
                  <div className="card comman-shadow">
                    <div className="card-body">
                      <div id="textMessage"></div>
                      <div id="errorMessage"></div>
                      <form
                        method="post"
                        action="javascript:;"
                        name="adddelsresion"
                        id="adddelsresion"
                      >
                         <input
                          type="hidden"
                          name="dealStatus"
                          id="dealStatus"
                          onChange={handlechage}
                        />
                         <input
                          type="hidden"
                          name="leadId"
                          id="leadId"
                          onChange={handlechage}
                        />

                        <div className="row">
                         
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                              Enter Reason{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                type="text"
                                rows="50"
                                style={{ height: "214px" }}
                                required
                                placeholder="Enter Reason"
                                name="resionval"
                                id="resionval"
                                onChange={handlechage}
                              ></textarea>
                              <span id="errorDescription"></span>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary waves-effect"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-info waves-effect waves-light"
                    
                    onClick={() => {approveDeal(leadId,delstatus);}}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
< Footer />

</div>
            </div>
    </React.Fragment>
    )
}

