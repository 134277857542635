
import React , { useEffect, useState } from 'react'; 
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import Footer from "../components/Footer";
import ManageSidebar from '../components/ManageSidebar';
import Getdeal from "../API/Getdeal";
import GetTraning from '../API/GetTraning';

export default function Register(){  
    const [userName, setUser] = useState(); 
    const [totallead, totalDeal] = useState();
    const [userRole, setRole] = useState();
    const [totaltrainee, getTotalTrainingList] = useState();


    let gettotalDeal = async () => {
        var userId = localStorage.getItem("userId");
          let resp = await Getdeal(userId);
          if(resp.status === 1)
          {
              totalDeal(resp.responseValue.length);  
          }
        };
// total tarinee
let getTotalTraining = async () => {
    var userId = localStorage.getItem("userId");
      let resp = await GetTraning(userId);
      if(resp.status === 1)
      {
          getTotalTrainingList(resp.responseValue.length);  
      }
    };
    useEffect(() => {
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
        setUser(pageView);
        setRole(role);
        gettotalDeal();
        getTotalTraining();
      }, []); 
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Partner Dashboard</title>
                <link rel="canonical" href="http://localhost:5000/" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar/>
            <div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Welcome {userName}!</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item">Partner Dashboard</li>
</ul>
</div>
</div>
</div>
</div>


<div className="row">
<div className="col-xl-3 col-sm-6 col-12 d-flex">
<a href='/leads' style={{"width":"100%"}}>
<div className="card bg-comman w-100">
<div className="card-body">
<div className="db-widgets d-flex justify-content-between align-items-center">
<div className="db-info">
<h6>Total Leads</h6>
<h3>{totallead}</h3>
</div>
<div className="db-icon">
<img src="assets/img/icons/teacher-icon-01.svg" alt="Dashboard Icon"/>
</div>
</div>
</div>
</div>
</a>
</div>
{/* <div className="col-xl-3 col-sm-6 col-12 d-flex">
<a href='/register-deal' style={{"width":"100%"}}>
<div className="card bg-comman w-100">
<div className="card-body">
<div className="db-widgets d-flex justify-content-between align-items-center">
<div className="db-info">
<h6>Total Register Deals</h6>
<h3>15</h3>
</div>
<div className="db-icon">
<img src="assets/img/icons/dash-icon-01.svg" alt="Dashboard Icon"/>
</div>
</div>
</div>
</div>
</a>
</div> */}
{/* <div className="col-xl-3 col-sm-6 col-12 d-flex">
<a href='/product-material' style={{"width":"100%"}}>
<div className="card bg-comman w-100">
<div className="card-body">
<div className="db-widgets d-flex justify-content-between align-items-center">
<div className="db-info">
<h6>Total Product Material</h6>
<h3>10</h3>
</div>
<div className="db-icon">
<img src="assets/img/icons/teacher-icon-02.svg" alt="Dashboard Icon"/>
</div>
</div>
</div>
</div>
</a>
</div> */}
{userRole === 'Channel Partner' && userRole === 'Administrator' &&
        <>
<div className="col-xl-3 col-sm-6 col-12 d-flex">
<a href='/training' style={{"width":"100%"}}>
<div className="card bg-comman w-100">
<div className="card-body">
<div className="db-widgets d-flex justify-content-between align-items-center">
<div className="db-info">
<h6>Total Training</h6>
<h3>{totaltrainee}</h3>
</div>
<div className="db-icon">
<img src="assets/img/icons/teacher-icon-03.svg" alt="Dashboard Icon"/>
</div>
</div>
</div>
</div>
</a>
</div>
</>
}
</div>

</div>

<Footer />

</div>
            </div>
    </React.Fragment>
    )
}

