import React , { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';



export default function Header() {  
    
const [userName, setUser] = useState(); 
const [userRole, setRole] = useState();
const [profileimage, setProfileImage] = useState();

const logOut = () => {
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRole');
    window.location.href = '/';
  };
  let toggleNav = async() =>{
    let nevbtn = document.getElementById("mobilemenu");
    nevbtn.classList.toggle("slide-nav");
    const note = document.querySelector('.sidebar-overlay');
    note.classList.toggle('opened');
    
  }
useEffect(() => {
    var pageView = localStorage.getItem("userName");
    
    var role = localStorage.getItem("userRole");
    var profile_pic = localStorage.getItem("profileimage");
    if(pageView == null && role == null){
        window.location.href = '/'; 
    }
    else {
    setUser(pageView);
    setRole(role);
    setProfileImage(window.UserbaseUrl+'/files/'+profile_pic);
}
  }, []);  
        return (
            <React.Fragment>  
                <div className="header">

<div className="header-left">
<Link to="" className="logo">
<img src="assets/img/logo.webp" alt="Logo"/>
</Link>
<a href="index.html" className="logo logo-small">
<img src="assets/img/logo-small.png" alt="Logo" width="30" height="30"/>
</a>
</div>

<div className="menu-toggle">
<a href="javascript:void(0);" id="toggle_btn">
<i className="fas fa-bars"></i>
</a>
</div>

<div className="top-nav-search">
{/* <form>
<input type="text" className="form-control" placeholder="Search here"/>
<button className="btn" type="submit"><i className="fas fa-search"></i></button>
</form> */}
</div>


<a className="mobile_btn" id="mobile_btn" onClick={()=> {toggleNav();}}>
<i className="fas fa-bars"></i>
</a>


<ul className="nav user-menu">
{userRole === 'Channel Partner' &&
        <>
{/* <li className="nav-item dropdown language-drop me-2">
<a href="/download-certificate" title='Download Certificate' className="nav-link header-nav-list"> Certificate
<img src="assets/img/icons/certificate-log.png" className='cer-logo' alt=""/>
</a>
</li> */}
</>
}

<li className="nav-item dropdown has-arrow new-user-menus">
<a href="#" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
<span className="user-img">
<img className="rounded-circle" src={profileimage} width="31" alt="{userName}"/>
<div className="user-text">
<h6>{userName}</h6>
<p className="text-muted mb-0">{userRole}</p>
</div>
</span>
</a>
<div className="dropdown-menu">
<div className="user-header">
<div className="avatar avatar-sm">
<img src={profileimage} alt="User Image" className="avatar-img rounded-circle"/>
</div>
<div className="user-text">
<h6>{userName}</h6>
<p className="text-muted mb-0">{userRole}</p>
</div>
</div>
<Link className="dropdown-item" to="/profile">My Profile</Link>
<Link className="dropdown-item" to="javascript:;" onClick={() => {logOut();}}>Logout</Link>
</div>
</li>

</ul>

</div>


    </React.Fragment>
    )
}