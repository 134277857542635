
import React,{useEffect,useState} from 'react';
import axios from 'axios';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import Footer from "../components/Footer";
import ManageSidebar from '../components/ManageSidebar';
import GetUserbyId from '../API/GetUserbyId';
import GetUser from '../API/GetUser';
import GetPartner from '../API/GetPartner';
import UpdateTraining from '../API/UpdateTraining';
import RemoveUser from '../API/RemoveUser';

export default function User(){  
    let [contectperson, setContectperson] = useState();
    let [email, setEmail] = useState();
    let [mobile, setMobile] = useState();
    let [companyName, setCompanyname] = useState();
    let [password, setPassword] = useState();
    let [companylist,getPartner] = useState();
    let [userId, setUserId] = useState();
    let [profileimage, setProfileImage] = useState();
    let [proimage, setProImage] = useState();
    const [file, setFile] = useState();
    let [userlist, getUserList] = useState();
    let [rowid, setRowId] = useState();
    let getChannelPartner = async () => {
      let resp = await GetPartner();
      if(resp.status === 1)
      {
          getPartner(resp.responseValue);  
      }
    };
    let getUser = async () => {
        let resp = await GetUser();
        if(resp.status === 1)
        {
            getUserList(resp.responseValue);  
        }
      };

      
      let removeUser = async (e) =>{
        let con = window.confirm('Are you sure want to delete this Record?');
        if(con == true)
        {
          const sendData = {
            "id": e,
               }
               const resp = await RemoveUser(sendData);
               
               if(resp.status === 1){
                   document.getElementById("deleteMsg").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong>Record are deleted successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
               getUser();
              //  window.setTimeout(function() {

              //   document.getElementById("deleteMsg").style.display='none';
              //   window.location.href = '/user';
              //   }, 2000);
               }
              }
              else{
                return false;
              }
      }
      let editUser= async(e) =>{
        var options = document.createElement("option");
        const resp = await GetUserbyId(e);

        if(resp.status === 1)
         {
          document.getElementById('rowid').value = resp.responseValue[0].id;
          document.getElementById('contectperson').value = resp.responseValue[0].username;
          document.getElementById('email').value = resp.responseValue[0].email;
          document.getElementById('mobile').value = resp.responseValue[0].contact;
          document.getElementById('password').value = resp.responseValue[0].password;

         setProImage(resp.responseValue[0].profileImage);
         document.getElementById('proImage').src = 'http://172.16.61.31:7098/files/'+resp.responseValue[0].profileImage;
          
          var company = document.getElementById('company');
          var statedata = document.getElementById('company').value;
          options.value= resp.responseValue[0].requestId;
          options.selected= resp.responseValue[0].requestId;
          options.text= resp.responseValue[0].companyName;
          company.add(options);
         }
 
     }
     let clear = async () =>{
      document.getElementById("updateuser").reset();

  }
// edit user
  let updateUserData = async() =>{
    var userId = localStorage.getItem("userId");
    var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if(document.getElementById('contectperson').value === ""){
      document.getElementById("errorName").innerText = 'Enter name';
      return false;
    }
    else if(regex.test(document.getElementById('email').value) === false){
      document.getElementById("errorEmail").innerText = 'Enter email';
      return false;
    }
    else if(document.getElementById('password').value === ""){
      document.getElementById("errorPassword").innerText = 'Enter password';
      return false;
    }
    else if(document.getElementById('mobile').value === ""){
      document.getElementById("errorMobile").innerText = 'Enter mobile no';
      return false;
    }
    else if(document.getElementById('mobile').length < 10){
      document.getElementById("errorMobile").innerText = 'Enter valid phone no';
      return false;
    }
    else if(document.getElementById('company').value === "-1"){
      document.getElementById("errorCompanyName").innerText = 'Select company';
      return false;
    }
    else{
      const imgFile = document.getElementById('profile');
      const formData = new FormData();
      if(imgFile.value !== '')
      {formData.append(
        "attachmentFiles",
        imgFile.files[0],
        imgFile.files[0].name
    );
      }
      else{
        formData.append("attachmentFiles",'');
      }
    
    formData.append('Id', document.getElementById('rowid').value);
    formData.append('email', document.getElementById('email').value);
    formData.append('UserName', document.getElementById('contectperson').value);
    formData.append('Contact', document.getElementById('mobile').value);
    formData.append('Password',document.getElementById('password').value);
    formData.append('RequestId',document.getElementById('company').value);
    formData.append('ApprovalStatus',1);
    formData.append('UserId',userId);
    let url =window.UserbaseUrl+"/api/SubApplicationRequest/UpdateSubApplicationRequest";
    
  
     // const resps = axios.post(url, formData);
      axios({
        method: "post",
        url: url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
          //handle success
          if(response.data.responseValue[0].status == 1)
          {
          clear();
          document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
          <strong>Success!</strong> User are updated successfully.
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
          </button>
          </div>`;
          getUser();
      
          }
          else{
                   return false;
          }
    
        })
      // console.log(resps);
    
      // //clear();
      // // if(resps.status === 1){
      //   document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
      //   <strong>Success!</strong> User are updated successfully.
      //   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
      //   </button>
      //   </div>`;
      //   // window.setTimeout(function() {
      //   //   setProfileImage('');
      //   //   getUser();
      //   //   document.getElementById("textMessage").style.display='none';
      //   //   window.location.href = '/user';
      //   //   }, 2000);
   
    }
  }
   
    
  let handlechage = async (e) => {
    //clear();
    let name = e.target.name;
    let value = e.target.value;
    
    if(name === 'rowid'){
      setRowId(value)
    }
    if(name === 'userid'){
      setUserId(value)
    }
    if (name === 'contectperson') {
      setContectperson(value)
  }
  if (name === 'email') {
    setEmail(value)
  }
  if (name === 'mobile') {
    setMobile(value)
   }
   if (name === 'password') {
    setPassword(value)
   }
   
    if (name === 'company') {
      setCompanyname(value)
     }
  };

     
   
      const handleFileChange = (e) => {
        if (e.target.files) {
          setFile(e.target.files[0]);
          setProfileImage(URL.createObjectURL(e.target.files[0]));
      }
      };
       let changeFronTDate = (e) =>{
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(e)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
        }
        let  searchTable = async()=> {
          // Declare variables
          var input, filter, table, tr, td, i, j, txtValue,found;
          input = document.getElementById("searchText");
          filter = input.value.toUpperCase();
          table = document.getElementById("tableData");
          tr = table.getElementsByTagName("tr");
        
          // Loop through all table rows, and hide those who don't match the search query
          for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td");
            for (j = 0; j < td.length; j++) {
                if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
                    found = true;
                }
            }
            if (found) {
                tr[i].style.display = "";
                found = false;
            } else {
                tr[i].style.display = "none";
            }
        }
        }
      useEffect(() => {
        getUser();
        getChannelPartner();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Trainee List</title>
                <link rel="canonical" href="http://localhost:5000/training" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
                <div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Users</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href="/add-user" className="btn btn-primary p-2 text-white"><i className="fas fa-plus"></i> Add User</a></li>
</ul>
</div>
</div>
</div>
</div>
<div className="row">
<div className="col-sm-12">
<div className="card card-table comman-shadow">
<div className="card-body">

<div className="page-header">
<div className="row align-items-center">

<div className="col-9">
    
<h3 className="page-title">My User</h3>
</div>
<div className="col-3 text-end float-end ms-auto download-grp">
<ul className='headerlist'>

  <li className='headerlistitem' ><div className="form-group">
  
<input type="text" className="form-control" placeholder="Search ..." id='searchText' name='searchText' onChange={()=>{searchTable();}}/>
</div></li>
</ul>
</div>
</div>
</div>

<div className="table-responsive fixTableHead">
<span id="deleteMsg"></span>
<table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
<thead className="student-thread">
<tr>
<th>
#
</th>
<th>Person Name</th>
<th>Company Name</th>
<th>Contact No.</th>
<th>Email Id</th>
<th>Status</th>
<th className="text-end">Action</th>
</tr>
</thead>
<tbody id="tableData">
{
userlist && userlist.length > 0 ?
userlist.map((val, ind) => { return (
<tr>
<td>
{ind+1}
</td>
<td>{val.username}</td>   
<td>{val.companyName}</td> 
<td>{val.contact}</td>  
<td>{val.email}</td>    
<td>{val.approvalStatus == '0' && "Pending"} {val.approvalStatus == '1' && "Approve"}</td>   
<td className="text-end">
<div className="actions ">
{/* <a href="javascript:;" className="btn btn-sm bg-success-light me-2 ">
<i className="feather-eye"></i>
</a> */}

<a href="javascript:;" id="editpartner" title='Edit' onClick={() => {editUser(val.id);}} data-bs-toggle="modal" data-bs-target="#con-close-modal" className="btn btn-sm bg-danger-light">
<i id="partnerstatusicon" class="feather-edit"></i>
</a>&nbsp;
<a href="javascript:;" title='Remove' onClick={() => {removeUser(val.id);}} className="btn btn-sm bg-danger-light">
<i className="feather-trash"></i>
</a>
</div>
</td>
</tr>
);
})
: <tr><td colspan="6" style={{textAlign :"center"}}>No results found</td></tr>
}
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div id="con-close-modal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display:"none", }}>
<div className="modal-dialog modal-lg">
<div className="modal-content">
<div className="modal-header">
<h4 className="modal-title">Update User</h4>
<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div className="modal-body">
<div className="card comman-shadow">
<div className="card-body">
<div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='updateuser' id='updateuser'>
<div className="row">
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
  <input type='hidden' name='userid' id='userid' onChange={handlechage}/>
  <input type='hidden' name='rowid' id='rowid' onChange={handlechage} />
<label>Contact Person <span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter name" name='contectperson' id="contectperson" onChange={handlechage}/>
<span id="errorName" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Email <span className="login-danger">*</span></label>
<input className="form-control" type="email" required placeholder="Enter email" name='email' id='email' onChange={handlechage} />
<span id="errorEmail" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Mobile No. <span className="login-danger">*</span></label>
<input className="form-control" type="number" size="15" required placeholder="Enter mobile" name='mobile' id='mobile' onChange={handlechage}/>
<span id="errorMobile" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Password <span className="login-danger">*</span></label>
<input className="form-control" type="text"  placeholder="Enter password" name='password' id="password" onChange={handlechage}/>
<span id="errorPassword" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Company <span className="login-danger">*</span></label>
<select className="form-control select" name='company' id="company" onChange={handlechage}>
<option value={-1}>Select company </option>
{companylist &&  companylist.map((val, ind) => { return (<option value={val.id}>{val.companyName}</option>);
})} 
</select>
<span id="errorCompanyName" className='errormsg'></span>

</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms ">
<label>Profile Image<span className="login-danger">*</span></label>
<input className="form-control" accept="image/jpeg, image/png, image/jpg" type="file" name='profile' id='profile' onChange={handleFileChange}/>
<span id="errorDoc" className='errormsg'></span>
<div className='imgsec'>
<img id="proImage" src={profileimage} style={{"width":"150px"}} />
</div>

</div>
</div>

</div>
</form>
</div>
</div>
</div>
<div className="modal-footer">
<button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal">Close</button>
<button type="submit" className="btn btn-info waves-effect waves-light" name='updateUserData' id='updateUserData' onClick={()=>{updateUserData();}}>Save changes</button>
</div>
</div>
</div>
</div>
< Footer />

</div>
            </div>
    </React.Fragment>
    )
}

