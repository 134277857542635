
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import Footer from "../components/Footer";
import ManageSidebar from '../components/ManageSidebar';
import GetMeetsByUser from '../API/GetMeetsByUser';
import RemoveUser from '../API/RemoveUser';

export default function CompanyMeets(){  
    let [contectperson, setContectperson] = useState();
    let [email, setEmail] = useState();
    let [mobile, setMobile] = useState();
    let [companyName, setCompanyname] = useState();
    let [password, setPassword] = useState();
    let [userId, setUserId] = useState();
    let [profileimage, setProfileImage] = useState();
    let [proimage, setProImage] = useState();
    const [file, setFile] = useState();
    let [userMeetlist, getUserMeetsList] = useState();
    let [rowid, setRowId] = useState();

    let getMeetByUser = async () => {
      //var requestID = localStorage.getItem("customerId");
      var userId = localStorage.getItem("userId");
        let resp = await GetMeetsByUser(userId);
        if(resp.status === 1)
        {
            getUserMeetsList(resp.responseValue);  
        }
      };


     let clear = async () =>{
      document.getElementById("updatetrainee").reset();

  }

    
  let handlechage = async (e) => {
    //clear();
    let name = e.target.name;
    let value = e.target.value;
    
    if(name === 'rowid'){
      setRowId(value)
    }
    if(name === 'userid'){
      setUserId(value)
    }
    if (name === 'contectperson') {
      setContectperson(value)
  }
  if (name === 'email') {
    setEmail(value)
  }
  if (name === 'mobile') {
    setMobile(value)
   }
   if (name === 'password') {
    setPassword(value)
   }
   
    if (name === 'company') {
      setCompanyname(value)
     }
  };

     
   
      const handleFileChange = (e) => {
        if (e.target.files) {
          setFile(e.target.files[0]);
          setProfileImage(URL.createObjectURL(e.target.files[0]));
      }
      };
       let changeFronTDate = (e) =>{
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(e)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
        }
        let  searchTable = async()=> {
          // Declare variables
          var input, filter, table, tr, td, i, j, txtValue,found;
          input = document.getElementById("searchText");
          filter = input.value.toUpperCase();
          table = document.getElementById("tableData");
          tr = table.getElementsByTagName("tr");
        
          // Loop through all table rows, and hide those who don't match the search query
          for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td");
            for (j = 0; j < td.length; j++) {
                if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
                    found = true;
                }
            }
            if (found) {
                tr[i].style.display = "";
                found = false;
            } else {
                tr[i].style.display = "none";
            }
        }
        }
      useEffect(() => {
        getMeetByUser();
       // getChannelPartner();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Company Meets List</title>
                <link rel="canonical" href="http://localhost:5000/training" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
                <div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Company Meets</h3>
{/* <ul className="breadcrumb">
<li className="breadcrumb-item active"><a href="/add-user" className="btn btn-primary p-2 text-white"><i className="fas fa-plus"></i> Add User</a></li>
</ul> */}
</div>
</div>
</div>
</div>
<div className="row">
<div className="col-sm-12">
<div className="card card-table comman-shadow">
<div className="card-body">

<div className="page-header">
<div className="row align-items-center">

<div className="col-9">
    
<h3 className="page-title">Company Meets</h3>
</div>
<div className="col-3 text-end float-end ms-auto download-grp">
<ul className='headerlist'>

  <li className='headerlistitem' ><div className="form-group">
  
<input type="text" className="form-control" placeholder="Search ..." id='searchText' name='searchText' onChange={()=>{searchTable();}}/>
</div></li>
</ul>
</div>
</div>
</div>

<div className="table-responsive fixTableHead">
<span id="deleteMsg"></span>
<table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
<thead className="student-thread">
<tr>
<th>
#
</th>
<th>Contact Person</th>
<th>Company Name</th>
<th>Contact No.</th>
<th>Email Id</th>
<th>Product</th>
<th>Description</th>
<th>Meeting Status</th>
<th className="text-end">Action</th>
</tr>
</thead>
<tbody id="tableData">
{
userMeetlist && userMeetlist.length > 0 ?
userMeetlist.map((val, ind) => { return (
<tr>
<td>
{ind+1}
</td>
<td>{val.contactPerson}</td>   
<td>{val.companyName}</td> 
<td>{val.contactNumber}</td>  
<td>{val.email}</td> 
<td>{val.productName}</td>     
<td>{val.meetingDescription}</td>   
<td>{val.meetingStatus == '1' && "Not Started"} {val.meetingStatus == '2' && "Progress"} {val.meetingStatus == '3' && "Completed"}</td>   

<td className="text-end">
<div className="actions ">
{/* <a href="javascript:;" className="btn btn-sm bg-success-light me-2 ">
<i className="feather-eye"></i>
</a> */}

<a href="javascript:;" id="editpartner" title='View' data-bs-toggle="modal" data-bs-target="#con-close-modal" className="btn btn-sm bg-danger-light">
<i id="partnerstatusicon" class="feather-eye"></i>
</a>
</div>
</td>
</tr>
);
})
: <tr><td colspan="6" style={{textAlign :"center"}}>No results found</td></tr>
}
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>

< Footer />

</div>
            </div>
    </React.Fragment>
    )
}

