
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import Footer from "../components/Footer";
// import Getdeal from "../API/Getdeal";

import GetCountry from "../API/GetCountry";
import GetState from "../API/GetState";
import GetCity from "../API/GetCity";
import ManageSidebar from '../components/ManageSidebar';
import GetTrainingdyId from '../API/GetTrainingdyId';
import GetJobTitle from "../API/GetJobTitle";
import Getindustry from '../API/Getindustry';
import GetTraning from '../API/GetTraning';
import ApprovePartner from '../API/ApprovePartner';
import UpdateTraining from '../API/UpdateTraining';
import RemoveTraining from '../API/RemoveTraining';

export default function Training(){  
  let [country,CountryList] = useState();
    let [state, StateList] = useState();
    let [city, CityList] = useState();
    let [contectperson, setContectperson] = useState();
    let [email, setEmail] = useState();
    let [mobile, setMobile] = useState();
    let [companyName, setCompanyname] = useState();
    let [companycontact,setCompanycontact] = useState();
    let [countryname, setCountry] = useState();
    let [statename, setState] = useState();
    let [cityname, setCity] = useState();
    let [address, setAddress] = useState();
    let [designationname, setDesignationName] = useState();
    let [industryname, setIndustryName] = useState();
    let [industry,setIndustry] = useState();
    let [designation, setJobTitle] = useState();
    let [trainingId, setTraineeId] = useState();

    let [tarininglist, getTrainingList] = useState();
    let getJobTitle = async()=> {
        
      let resp = await GetJobTitle();
      if(resp.status === 1)
      {
          setJobTitle(resp.responseValue);  
      }
    }
    let getIndustry = async()=>{
      let resp = await Getindustry();
      if(resp.status === 1)
      {
          setIndustry(resp.responseValue);  
      }
      
    }
    let getTraining = async () => {
      var userId = localStorage.getItem("userId");
        let resp = await GetTraning(userId);
        if(resp.status === 1)
        {
            getTrainingList(resp.responseValue);  
        }
      };

      
      let removeTraining = async (e) =>{
        let con = window.confirm('Are you sure want to delete this Record?');
        if(con == true)
        {
          const sendData = {
            "id": e,
               }
               const resp = await RemoveTraining(sendData);
               
               if(resp.status === 1){
                   document.getElementById("deleteMsg").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong>Record are deleted successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
               window.setTimeout(function() {

                document.getElementById("deleteMsg").style.display='none';
                window.location.href = '/training';
                }, 2000);
               }
              }
              else{
                return false;
              }
      }
      let editTraining = async(e) =>{
        var select_box = document.getElementById("state");
        select_box.remove(1);
        var select_box1 = document.getElementById("city");
        select_box1.remove(1);
        var options = document.createElement("option");
        var option = document.createElement("option");
        const resp = await GetTrainingdyId(e);

        if(resp.status === 1)
         {
          document.getElementById('trainingid').value = resp.responseValue[0].id;
          document.getElementById('contectperson').value = resp.responseValue[0].traineeName;
          document.getElementById('email').value = resp.responseValue[0].email;
          document.getElementById('mobile').value = resp.responseValue[0].mobile;
          document.getElementById('address').value = resp.responseValue[0].address;
          document.getElementById('industryname').value = resp.responseValue[0].companyID;
          document.getElementById('designationname').value = resp.responseValue[0].designationID;
          document.getElementById('country').value = resp.responseValue[0].countryID;
          var stateval = document.getElementById('state');
          var statedata = document.getElementById('state').value;
          options.value= resp.responseValue[0].stateID;
          options.selected= resp.responseValue[0].stateID;
          options.text= resp.responseValue[0].companyStateName;
          stateval.add(options);

          var cityval = document.getElementById('city');
          var citydata = document.getElementById('city').value;
          option.value= resp.responseValue[0].cityID;
          option.selected= resp.responseValue[0].cityID;
          option.text= resp.responseValue[0].companyCityName;
          cityval.add(option);
         }
 
     }
     let clear = async () =>{
      document.getElementById("updatetrainee").reset();

  }
   
    
  let handlechage = async (e) => {
    //clear();
    let name = e.target.name;
    let value = e.target.value;

    if(name === 'trainingid'){
      setTraineeId(value)
    }
    if (name === 'contectperson') {
   
      setContectperson(value)
      
  }
  if (name === 'email') {
    setEmail(value)
  }
  if (name === 'mobile') {
    setMobile(value)
   }
   
   
    if (name === 'country') {
        getState(value)
        setCountry(value);
    }
    if (name === 'state') {
      editTraining();
      getCity(value);
      setState(value);
      }
      if (name === 'city') {
        setCity(value);
    }
    if (name === 'company') {
      setCompanyname(value)
     }
    if (name === 'address') {
      setAddress(value)
    }
    
    if (name === 'designationname') {
      setDesignationName(value)
    }
    if (name === 'industryname') {
      setIndustryName(value)
    }
    
    
  };
      // let approvePartner = async(e,s) =>{
      //   var userId = localStorage.getItem("userId");
      //   let apprstatus = document.getElementById('partnerstatus');
      //   let iconspart = document.getElementById('partnerstatusicon');
      //   let status ='';
      //   if(s == 0){
      //     status = 1;
      //     apprstatus.setAttribute('title', 'Un approve');
      //     iconspart.setAttribute('class', 'fa fa-check-circle');
      //   }
      //   else{
      //     status = 0;
      //     apprstatus.setAttribute('title', 'Approve');
      //     iconspart.setAttribute('class', 'fa fa-times');
      //   }
      //   const sendData = {
      //     "id" : e,
      //     "userID" : userId,
      //     "approvalStatus" : status
      //     }
        
      //     const resp = await ApprovePartner(sendData);
      //     //clear();

      //     if(resp.status === 1){
      //       if(status === 1)
      //      { document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
      //       <strong>Success!</strong> Partner has been approved successfully.
      //       <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
      //       </button>
      //       </div>`;
      //       window.setTimeout(function() {

      //         document.getElementById("textMessage").style.display='none';
      //         window.location.href = '/channel-partner';
      //         }, 2000);

      //     }
      //     else{
      //       { document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
      //       <strong>Success!</strong> Partner has been Un approved successfully.
      //       <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
      //       </button>
      //       </div>`;
      //       window.setTimeout(function() {
      //         window.location.href = '/channel-partner';
      //         document.getElementById("textMessage").style.display='none';
      //         }, 2000);
      //     }
      //     }
      //     }
      //     else{
      //       document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
      //       <strong>Error!</strong> Dealer are not approved.
      //       <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
      //       </button>
      //       </div>`;
      //     }
      //   }
      
      let getCountry= async () => {
        let resp = await GetCountry();
        if(resp.status === 1)
        {
            CountryList(resp.responseValue);
            getState();
        }
      };

      let getState = async(e)=>{
        
        let resp = await GetState(e);
        if(resp.status === 1)
        {
            StateList(resp.responseValue);
        }
      }
      let getCity = async(e)=>{
        
        let resp = await GetCity(e);
        if(resp.status === 1)
        {
            CityList(resp.responseValue);
        }
      }
      let updateTraining = async()=>{
        var userId = localStorage.getItem("userId");
        var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if(document.getElementById('contectperson').value === ""){
          document.getElementById("errorName").innerText = 'Enter name';
          return false;
        }
       else if(regex.test(document.getElementById('email').value) === false){
          document.getElementById("errorEmail").innerText = 'Enter email';
          return false;
        }
        else if(document.getElementById('mobile').value === ""){
          document.getElementById("errorMobile").innerText = 'Enter mobile no';
          return false;
        }
        else if(document.getElementById('mobile').value.length < 10){
          document.getElementById("errorMobile").innerText = 'Enter valid phone no';
          return false;
        }
        else if(document.getElementById('country').value === "-1"){
          document.getElementById("errorCountry").innerText = 'Select country';
          return false;
        }
        else if(document.getElementById('state').value === "-1"){
          document.getElementById("errorState").innerText = 'Select state';
          return false;
        }
        else if(document.getElementById('city').value === "-1"){
          document.getElementById("errorCity").innerText = 'Select city';
          return false;
        }
        else if(document.getElementById('address').value === ""){
          document.getElementById("errorAddress").innerText = 'Enter address';
          return false;
        }
        else if(document.getElementById('industryname').value === "-1"){
          document.getElementById("errorCompanyName").innerText = 'Select company';
          return false;
        }
        else if(document.getElementById('designationname').value === "-1"){
          document.getElementById("errorDesignation").innerText = 'Select designation';
          return false;
        }
  else{
  const sendData = {
    "id": document.getElementById('trainingid').value,
    "email": document.getElementById('email').value,
    "traineeName": document.getElementById('contectperson').value,
    "mobile" : document.getElementById('mobile').value,
    // "companyName" : document.getElementById('industryname').value,
    "countryID" : document.getElementById('country').value,
    "stateID" : document.getElementById('state').value,
    "cityID" : document.getElementById('city').value,
    "address" : document.getElementById('address').value,
    "designationID" : document.getElementById('designationname').value,
    "companyID" : document.getElementById('industryname').value,
    "userId" : userId
    }
  //console.log(sendData);
    const resp = await UpdateTraining(sendData);
    clear();
    if(resp.status === 1){
      
      document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
		  <strong>Success!</st.
      rong> Trainee are updated successfully.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
      getTraining();
    }
    else{
      document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
		  <strong>Error!</strong> Trainee are not updated.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
    }
  }
      }
    
  
       let changeFronTDate = (e) =>{
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(e)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
        }
        let  searchTable = async()=> {
          // Declare variables
          var input, filter, table, tr, td, i, j, txtValue,found;
          input = document.getElementById("searchText");
          filter = input.value.toUpperCase();
          table = document.getElementById("tableData");
          tr = table.getElementsByTagName("tr");
        
          // Loop through all table rows, and hide those who don't match the search query
          for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td");
            for (j = 0; j < td.length; j++) {
                if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
                    found = true;
                }
            }
            if (found) {
                tr[i].style.display = "";
                found = false;
            } else {
                tr[i].style.display = "none";
            }
        }
        }
      useEffect(() => {
        getTraining();
        getCountry();
        getState();
        getCity();
        getJobTitle();
        getIndustry();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Trainee List</title>
                <link rel="canonical" href="http://localhost:5000/training" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
                <div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Trainees</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href="/add-training" className="btn btn-primary p-2 text-white"><i className="fas fa-plus"></i> Add Trainee</a></li>
</ul>
</div>
</div>
</div>
</div>
<div className="row">
<div className="col-sm-12">
<div className="card card-table comman-shadow">
<div className="card-body">

<div className="page-header">
<div className="row align-items-center">

<div className="col-9">
    
<h3 className="page-title">My Trainees</h3>
</div>
<div className="col-3 text-end float-end ms-auto download-grp">
<ul className='headerlist'>

  <li className='headerlistitem' ><div className="form-group">
  
<input type="text" className="form-control" placeholder="Search ..." id='searchText' name='searchText' onChange={()=>{searchTable();}}/>
</div></li>
</ul>
</div>
</div>
</div>

<div className="table-responsive fixTableHead">
<span id="deleteMsg"></span>
<table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
<thead className="student-thread">
<tr>
<th>
#
</th>
<th>Contact Person</th>
<th>Contact No.</th>
<th>Email Id</th>
<th>Company</th>
<th>Date</th>
<th className="text-end">Action</th>
</tr>
</thead>
<tbody id="tableData">
{
tarininglist && tarininglist.length > 0 ?
tarininglist.map((val, ind) => { return (
<tr>
<td>
{ind+1}
</td>
<td>{val.traineeName}</td>   
<td>{val.mobile}</td>  
<td>{val.email}</td>    
<td>{val.industryType}</td>  
<td>{val.createdDate}</td>  
<td className="text-end">
<div className="actions ">
{/* <a href="javascript:;" className="btn btn-sm bg-success-light me-2 ">
<i className="feather-eye"></i>
</a> */}

<a href="javascript:;" id="editpartner" title='Edit' onClick={() => {editTraining(val.id);}} data-bs-toggle="modal" data-bs-target="#con-close-modal" className="btn btn-sm bg-danger-light">
<i id="partnerstatusicon" class="feather-edit"></i>
</a>&nbsp;
<a href="javascript:;" title='Remove' onClick={() => {removeTraining(val.id);}} className="btn btn-sm bg-danger-light">
<i className="feather-trash"></i>
</a>
</div>
</td>
</tr>
);
})
: <tr><td colspan="6" style={{textAlign :"center"}}>No results found</td></tr>
}
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div id="con-close-modal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display:"none", }}>
<div className="modal-dialog modal-lg">
<div className="modal-content">
<div className="modal-header">
<h4 className="modal-title">Update Trainee</h4>
<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div className="modal-body">
<div className="card comman-shadow">
<div className="card-body">
<div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='updatetrainee' id='updatetrainee'>
<div className="row">
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
  <input type='hidden' name='trainingid' id='trainingid' onChange={handlechage}/>
<label>Contact Person <span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter name" name='contectperson' id="contectperson" onChange={handlechage}/>
<span id="errorName" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Email <span className="login-danger">*</span></label>
<input className="form-control" type="email" required placeholder="Enter email" name='email' id='email' onChange={handlechage} />
<span id="errorEmail" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Mobile No. <span className="login-danger">*</span></label>
<input className="form-control" type="number" size="15" required placeholder="Enter mobile" name='mobile' id='mobile' onChange={handlechage}/>
<span id="errorMobile" className='errormsg'></span>
</div>
</div>


<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Country <span className="login-danger">*</span></label>
<select className="form-control select" name="country" id="country" required onChange={handlechage}>
<option value={-1}>Select country</option>
{country &&  country.map((val, ind) => { return (<option value={val.id}>{val.countryName}</option>);
})} 
</select>
<span id="errorCountry" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>State <span className="login-danger">*</span></label>
<select className="form-control select" name="state" required id="state" onChange={handlechage}>
<option value={-1}>Select state</option>
{state &&  state.map((val, ind) => { return (<option value={val.id}>{val.stateName}</option>);
})} 
</select>
<span id="errorState" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>City <span className="login-danger">*</span></label>
<select className="form-control select" name='city' required id="city" onChange={handlechage}>
<option>Select city</option>
{city &&  city.map((val, ind) => { return (<option value={val.id}>{val.name}</option>);
})} 
</select>
<span id="errorCity" className='errormsg'></span>
</div>
</div>

<div className="col-12 col-sm-4">
<div className="form-group local-forms ">
<label>Address<span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter address" name='address' id='address' onChange={handlechage}/>
<span id="errorAddress" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Company <span className="login-danger">*</span></label>
<select className="form-control select" name='industryname' required id="industryname" onChange={handlechage}>
<option value={-1}>Select company </option>
{industry &&  industry.map((val, ind) => { return (<option value={val.id}>{val.industryType}</option>);
})} 
</select>
<span id="errorCompanyName" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Designation <span className="login-danger">*</span></label>
<select className="form-control select" name='designationname' required id="designationname" onChange={handlechage}>
<option value={-1}>Select designation </option>
{designation &&  designation.map((val, ind) => { return (<option value={val.id}>{val.designation}</option>);
})} 
</select>
<span id="errorDesignation" className='errormsg'></span>
</div>
</div>

</div>
</form>
</div>
</div>
</div>
<div className="modal-footer">
<button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal">Close</button>
<button type="submit" className="btn btn-info waves-effect waves-light" name='updateProduct' id='updateProduct' onClick={()=>{updateTraining();}}>Save changes</button>
</div>
</div>
</div>
</div>
< Footer />

</div>
            </div>
    </React.Fragment>
    )
}

