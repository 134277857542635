export default async function GetFollowStep() {
    
    let url =window.UserbaseUrl+"/api/TrackStatus/GetTrackStatus";
    let head={ 'Content-Type': 'application/json-patch+json'};
    let response = await fetch(url,{
      method: "GET",
      headers: head
    }).then((res) => res.json());
    return response;
  }
