import React , { useEffect, useState } from 'react'; 



export default function Header() {  
    
const [userName, setUser] = useState(); 
const [userRole, setRole] = useState();

const logOutAdmin = () => {
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRole');
    window.location.href = '/admin';
  };
  let toggleNav = async() =>{
    let nevbtn = document.getElementById("mobilemenu");
    nevbtn.classList.toggle("slide-nav");
    const note = document.querySelector('.sidebar-overlay');
    note.classList.toggle('opened');
    
  }
useEffect(() => {
    var pageView = localStorage.getItem("userName");
    var role = localStorage.getItem("userRole");
    if(pageView == null && role == null){
        window.location.href = '/admin'; 
    }
    else {
    setUser(pageView);
    setRole(role);
}
  }, []);  
        return (
            <React.Fragment>  
                
                <div className="header">

<div className="header-left">
<a href="" className="logo">
<img src="assets/img/logo.webp" alt="Logo"/>
</a>
<a href="" className="logo logo-small">
<img src="assets/img/logo-small.png" alt="Logo" width="30" height="30"/>
</a>
</div>

<div className="menu-toggle">
<a href="javascript:void(0);" id="toggle_btn" >
<i className="fas fa-bars"></i>
</a>
</div>

<div className="top-nav-search">
{/* <form>
<input type="text" className="form-control" placeholder="Search here"/>
<button className="btn" type="submit"><i className="fas fa-search"></i></button>
</form> */}
</div>


<a className="mobile_btn" id="mobile_btn" onClick={()=> {toggleNav();}}>
<i className="fas fa-bars"></i>
</a> 


<ul className="nav user-menu">

<li className="nav-item dropdown has-arrow new-user-menus">
<a href="#" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
<span className="user-img">
<img className="rounded-circle" src="assets/img/profiles/avatar-13.jpg" width="31" alt="{userName}"/>
<div className="user-text">
<h6>{userName}</h6>
<p className="text-muted mb-0">{userRole}</p>
</div>
</span>
</a>
<div className="dropdown-menu">
{/* <div className="user-header">
<div className="avatar avatar-sm">
<img src="assets/img/profiles/avatar-13.jpg" alt="User Image" className="avatar-img rounded-circle"/>
</div>
<div className="user-text">
<h6>{userName}</h6>
<p className="text-muted mb-0">{userRole}</p>
</div>
</div> */}
{/* <a className="dropdown-item" href="#">Inbox</a> */}
<a className="dropdown-item" href="javascript:;" onClick={() => {logOutAdmin();}}>Logout</a>
</div>
</li>

</ul>

</div>


    </React.Fragment>
    )
}