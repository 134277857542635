import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
import {
  Routes,
  Route,
  useSearchParams,
  BrowserRouter
} from "react-router-dom";
import ManageHeader from "../components/ManageHeader";
import GetdealdyId from "../API/GetdealdyId";
import Getdealproduct from "../API/Getdealproduct";
import GetProductByDealId from "../API/GetProductByDealId";
import SaveDealProduct from "../API/SaveDealProduct";
import GetNextDealByid from "../API/GetNextDealByid";
import ManageSidebar from "../components/ManageSidebar";
import RemoveDealProduct from "../API/RemoveDealProductById";
import GetCountry from "../API/GetCountry";
import GetState from "../API/GetState";
import GetCity from "../API/GetCity";
import GetIndustryType from "../API/GetIndustryType";
import Getproduct from "../API/Getproduct";
import UpdateLead from "../API/UpdateLead";
import SaveNextStep from "../API/SaveNextStep";
import RemoveStep from "../API/RemoveStep";
import GetFollowStep from "../API/GetFollowStep";
import GelCompteterById from "../API/GelCompteterById";
import SaveCompteter from '../API/SaveCompteter';

export default function EditLead() {
  let [country, CountryList] = useState();
  let [state, StateList] = useState();
  let [city, CityList] = useState();
  let [productlist, setProduct] = useState();
  let [industrylist, getIndustryList] = useState();
  // let [companycontact,setCompanycontact] = useState();
  let [email, setEmail] = useState();
  let [mobile, setMobile] = useState();
  let [countryname, setCountry] = useState();
  let [statename, setState] = useState();
  let [cityname, setCity] = useState();
  let [companyaddress, setCompanyaddress] = useState();
  let [postalcode, setPostalcode] = useState();
  let [website, setWebsite] = useState();
  let [contectperson, setContectperson] = useState();
  let [noofemployee, setPerson] = useState();
  let [noofexcustomer, setNoofexcustomer] = useState();
  let [industry, setIndustry] = useState();
  const [checkboxes, setCheckboxes] = useState([]);
  // other details
  let [primarycontact, setPrimaryContact] = useState();
  let [techcontactname, setTechContactName] = useState();
  let [techContactEmail, setTechContactEmail] = useState();
  let [techContactNo, setTechContactNo] = useState();
  let [competitor, setCompetitor] = useState();
  let [otherCompetitor, setotherCompetitor] = useState();
  let [estimatebudget, setEstimateBudget] = useState();
  let [selectedDate, setClosureDate] = useState();
  let [remark, setRemark] = useState();
  const [companyName, setCompanyname] = useState();
  const [customerId, setCustomerId] = useState();
  const [dealdetails, setDealDetails] = useState();
  const [productdeatils, setDealProductDetails] = useState();
  const [dealproduct, setDealProduct] = useState();
  const [selectproductlist, setSelectedDealProduct] = useState();
  const [nextstep, setNextStep] = useState([]);
  const [nextdealdeatils, setNextDealDetails] = useState(false);
  const [tractlist, setTractStep] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [competerlist, setCompteter] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

 

  const deleteDealProduct = async (e) => {
    const rowId = e;
    let con = window.confirm("Are you sure want to delete this Record?");
    if (con == true) {
      const sendData = {
        id: e
      };
      const resp = await RemoveDealProduct(sendData);
      if (resp.status === 1) {
        document.getElementById(
          "deleteproMsg"
        ).innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong>Product are deleted successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
        document.getElementById("deleteproMsg").style.display = "none";
        getLeadDetails();
        //  window.setTimeout(function () {
        //   document.getElementById("deleteproMsg").style.display = "none";
        //   window.location.reload();
        // }, 2000);
      }
    } else {
      return false;
    }
  };

  let getTrackStep = async () => {
    let resp = await GetFollowStep();
    if (resp.status === 1) {
      setTractStep(resp.responseValue);
    }
  };
  const deletestep = async (e) => {
    const rowId = e;
    const queryParameters = new URLSearchParams(window.location.search);
    const dealId = queryParameters.get("id");
    let con = window.confirm("Are you sure want to delete this step?");
    if (con == true) {
      const sendData = {
        id: e
      };
      const resp = await RemoveStep(sendData);
      if (resp.status === 1) {
        document.getElementById(
          "deletestepMsg"
        ).innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong>Step are deleted successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
        document.getElementById("deletestepMsg").style.display = "none";
        getLeadDetails();
        //  window.setTimeout(function () {
        //   document.getElementById("deletestepMsg").style.display = "none";
        //   window.location.reload();
        // }, 2000);
      }
    } else {
      return false;
    }
  };

  let [dealid, setDealId] = useState();
  const [val, setVal] = useState({
    expectedClosureDate: new Date() // Initial value for expected closure date
  });
  let clear = async () => {
    //document.getElementById("addDeal").reset();
    setContectperson("");
    // setCompanycontact('');
    setCountry("");
    setState("");
    setCity("");
    setCompanyaddress("");
    setPostalcode("");
    setWebsite("");
    setPerson("");
    setNoofexcustomer("");
    setIndustry("");
  };
  const formatDateToMMDDYY = async (dateString) => {
    const parts = dateString.split("-");
    let finaldate;
    if (parts.length === 3) {
      const year = parseInt(parts[2]);
      const month = parts[1];
      const day = parseInt(parts[0]);
      if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
        // return `${year}-${month}-${day}`;
        return `${year}-${month}-${day}`;
        //return finaldate;
      }
    }
    // Return original string if parsing fails
    return finaldate;
  };
  const getLeadDetails = async () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const Id = queryParameters.get("id");

    const rescom = await GelCompteterById(Id);
    let resultcom = [];
    let initialSelectedOptions = [];
    if (rescom.status === 1) {
      setCompteter(JSON.parse(rescom.responseValue[0].competitorDetails));
      let valobj;
      
      // JSON.parse(rescom.responseValue[0].competitorDetails).map(async (val) => {
      //   valobj = { label: val.competitorName, value: val.competitorId};
      //   resultcom.push(valobj);
      // });
      const competitorDetails = JSON.parse(rescom.responseValue[0].competitorDetails);
    
    // Use Promise.all to handle asynchronous operations in map
    await Promise.all(competitorDetails.map(async (val) => {
        valobj = { label: val.competitorName, value: val.competitorId };
        resultcom.push(valobj);
    }));
      console.log(resultcom);
      initialSelectedOptions = resultcom;
      setSelectedOption(initialSelectedOptions)
    }
    const resp = await GetdealdyId(Id);

    if (resp.status === 1) {
      setDealDetails(resp.responseValue);
      let encosedate = resp.responseValue[0].expectedClosuerDate;

      setDealId(resp.responseValue[0].id);
      setCompanyname(resp.responseValue[0].companyName);
      setContectperson(resp.responseValue[0].contactPerson);
      setEmail(resp.responseValue[0].emailID);
      setMobile(resp.responseValue[0].mobile);
      setCompanyaddress(resp.responseValue[0].companyAddress);
      setPostalcode(resp.responseValue[0].companyZipCode);
      setWebsite(resp.responseValue[0].website);
      setIndustry(resp.responseValue[0].industryTypeID);
      setCountry(resp.responseValue[0].companyCountryID);
      setState(resp.responseValue[0].companyStateID);

      //selectedIndustry(resp.responseValue[0].industryTypeID);
      setPrimaryContact(resp.responseValue[0].primaryContact);
      setTechContactName(resp.responseValue[0].techinicalContactName);
      setTechContactEmail(resp.responseValue[0].techinicalContactEmail);
      setTechContactNo(resp.responseValue[0].techinicalContactPhone);
      if(resp.responseValue[0].competitorName !=''){
      //document.querySelector("#otherCompetitorCompany").style.display = "block";
      setotherCompetitor(resp.responseValue[0].competitorName);
      }
      
      const rescom = await GelCompteterById(Id);
      if (rescom.status === 1) {
        setCompteter(JSON.parse(rescom.responseValue[0].competitorDetails));
      }
      setEstimateBudget(resp.responseValue[0].estimatebudget);
      setClosureDate(resp.responseValue[0].expectedClosuerDate);
      setRemark(resp.responseValue[0].remark);
      const parts = encosedate.split("-");
      if (parts.length === 3) {
        const year = parseInt(parts[2]);
        const month = parts[1];
        const day = parseInt(parts[0]);
        if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
          setClosureDate(`${year}-${month}-${day}`);
        }
      }
    }
    const resproduct = await GetProductByDealId(Id);

    if (resproduct.status === 1) {
      setSelectedDealProduct(resproduct.responseValue);
    }

    const resppro = await Getdealproduct(Id);

    if (resppro.status === 1) {
      setDealProductDetails(resppro.responseValue);
    }
    const respnext = await GetNextDealByid(Id);
    console.log(respnext);
    if (respnext.status === 1) {
      // console.log("fffffffffffffffff", respnext.responseValue);
      setNextDealDetails(respnext.responseValue);
      setNextStep(respnext.responseValue[0].stepId);
    }
  };
  let getCountry = async () => {
    let resp = await GetCountry();
    if (resp.status === 1) {
      CountryList(resp.responseValue);
      getState();
    }
  };

  let getState = async (e) => {
    let resp = await GetState(e);
    if (resp.status === 1) {
      StateList(resp.responseValue);
    }
  };
  let getCity = async (e) => {
    let resp = await GetCity(e);
    if (resp.status === 1) {
      CityList(resp.responseValue);
    }
  };
  let getIndustryType = async () => {
    let resp = await GetIndustryType();
    if (resp.status === 1) {
      getIndustryList(resp.responseValue);
    }
  };
  let getProduct = async () => {
    let resp = await Getproduct();
    if (resp.status === 1) {
      setProduct(resp.responseValue);
    }
  };
  const checkmobileValidation = (e) => {
    if (e.length < 10 || e.length > 12) {
      document.getElementById("errorMobile").innerText = "Enter valid phone no";
      return false;
    } else {
      document.getElementById("errorMobile").innerText = "";
      return false;
    }
  };
  let showothercompeter = async (e) => {
    // if (e == 5) {
    //   document.querySelector("#otherCompetitorCompany").style.display = "block";
    //   return false;
    // } else {
    //   if (e != 5) {
    //     document.querySelector("#otherCompetitorCompany").style.display =
    //       "none";
    //     document.querySelector("#otherCompetitor").value = "";
    //     return false;
    //   }
    //}
  };
  let handlechage = async (e) => {
    //clear();

    let name = e.target.name;
    let value = e.target.value;

    if (dealid === "dealid") {
      setDealId(value);
    }
    if (name === "companyname") {
      setCompanyname(value);
    }
    if (name === "contectperson") {
      setContectperson(value);
    }
    if (name === "email") {
      setEmail(value);
    }
    if (name === "mobile") {
      setMobile(value);
      checkmobileValidation(value);
    }
    
    if (e.target.checked === true) {
      const newValue = Number(e.target.value);
      setNoofexcustomer(newValue);
    }


    if (name === "country") {
      getState(value);
      setCountry(value);
    }
    if (name === "state") {
      getCity(value);
      setState(value);
    }
    if (name === "city") {
      setCity(value);
    }
    if (name === "companyaddress") {
      setCompanyaddress(value);
    }
    if (name === "postalcode") {
      setPostalcode(value);
    }
    if (name === "website") {
      setWebsite(value);
    }
    if (name === "noofemployee") {
      setPerson(value);
    }

    if (name === "industry") {
      setIndustry(value);
    }
    if (name === "industry") {
      setIndustry(value);
    }

    // if (name === "productname") {
    //   const newValue = e.target.checked ? 0 : 1;
    //   setSelectedDealProduct(newValue);
    // }

    if (name === "primarycontact") {
      setPrimaryContact(value);
    }
    if (name === "techcontactname") {
      setTechContactName(value);
    }
    if (name === "techContactNo") {
      setTechContactNo(value);
    }
    if (name === "techContactEmail") {
      setTechContactEmail(value);
    }
    // if (name === "competitor") {
    //   document.querySelector("#otherCompetitorCompany").style.display = "none";
    //   setCompetitor(value);
    //   showothercompeter(value);
    // }
    if (name === "estimateBudget") {
      setEstimateBudget(value);
    }
    if (name === "expectedClosuerDate") {
      setClosureDate(value);
    }
    if (name === "remark") {
      setRemark(value);
    }

    if (name === "otherCompetitor") {
      setotherCompetitor(value);
    }
  };

  let SaveDeal = async () => {
    var userId = localStorage.getItem("userId");
    let resultcom = [];
    let respscom ;
    let valobj ;
    let competitorData ;
    {}
    {
      competitor && competitor.length > 0 ?
      competitor.map( async val => {
         valobj = {
          "leadId" : dealid,
          "competitorId" : val.value,
          "competitorName" : val.label
        }
        resultcom.push(valobj);
      })
      : <></>
      }
      competitorData = {
        jsonCompetitor: JSON.stringify(resultcom),
        userId: userId
      };
      respscom = await SaveCompteter(competitorData);
    var checkBoxes = document.querySelectorAll('input[name="nextstep"]');
    // stepValidation();
    // if(stepValidation() == false){
    //   stepValidation();
    // }
    // else{
    let result = [];
    let resps;
    let sendDataval = "";
    checkBoxes.forEach(async (item) => {
      // loop all the checkbox item
      if (item.checked) {
        //if the check box is checked
        // let data = {    // create an object
        //   course: item.value,
        // }
        // result.push(data);

        sendDataval = {
          dealId: dealid,
          stepId: item.value,
          userID: userId
        };
        result.push(sendDataval);
        resps = await SaveNextStep(sendDataval);
      }
    });

    otherValidation();
    if (otherValidation() == false) {
      otherValidation();
    } else {
      const sendData1 = {
        id: dealid,
        emailID: email,
        contactPerson: contectperson,
        mobile: mobile,
        companyName: companyName,
        companyCountryID: document.getElementById("country").value,
        companyStateID: document.getElementById("state").value,
        companyCityID: document.getElementById("city").value,
        companyZipCode: postalcode,
        companyAddress: companyaddress,
        // "companyContact" : "",
        website: website,
        noOfEmployee: noofemployee,
        userId: userId,
        partnerID: customerId,
        isExixtingCustomer: noofexcustomer,
        industryTypeID: industry,
        primaryContact: primarycontact,
        techinicalContactName: techcontactname,
        techinicalContactEmail: techContactEmail,
        techinicalContactPhone: techContactNo,
        // competitor: competitor,
        otherCompetitor: otherCompetitor,
        estimatebudget: estimatebudget,
        expectedClosuerDate: selectedDate,
        remark: remark,
        dealStatus: 0,
        // emailID: localStorage.getItem("emailID"),
        userID: userId
      };

      console.log(sendData1);

      const resp = await UpdateLead(sendData1);
      clear();
      if (resp.status === 1) {
        document.getElementById(
          "textMessage"
        ).innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>Success!</strong> Lead are updated successfully.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
            </div>`;

        window.setTimeout(function () {
          document.getElementById("textMessage").style.display = "none";
          window.location.reload();
        }, 2000);
      } else {
        document.getElementById(
          "errorMessage"
        ).innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>Error!</strong> Lead are not updated.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
            </div>`;
      }
    }
  };
  let updateleadProduct = async () => {
    var userId = localStorage.getItem("userId");
    productValidation();
    if (productValidation() == false) {
      productValidation();
    } else {
      var checkBoxes = document.querySelectorAll('input[type="checkbox"]');
      let result = [];
      let resp;
      let sendDataval = "";
      checkBoxes.forEach(async (item) => {
        // loop all the checkbox item
        if (item.checked) {
          //if the check box is checked
          sendDataval = {
            dealId: dealid,
            productId: item.value,
            userID: userId
          };
          result.push(sendDataval);
          resp = await SaveDealProduct(sendDataval);
          if (resp.status === 1) {
            document.getElementById(
              "proMessage"
            ).innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Success!</strong> New product are added successfully.
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                    </button>
                    </div>`;

            getLeadDetails();
            uncheckAllCheckboxes();
            window.setTimeout(function () {
              document.getElementById("textMessage").style.display = "none";
              //window.location.reload();
            }, 2000);
          }
        }
      });

      //console.log(resp);
    }
  };
  function isChecked(e) {
    let checkboxes = document.querySelectorAll('input[name="productname"]');
    for (let i = 0; i < e; i++) {
      if (checkboxes[i].checked) return true;
    }

    return false;
  }
  function uncheckAllCheckboxes() {
    document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = false;
    });
  }
  function productValidation() {
    let allcheckboxes = document.querySelectorAll('input[name="productname"]');

    const errorMessage = !isChecked(allcheckboxes.length)
      ? `<div class="alert alert-danger alert-dismissible fade show" role="alert">
    <strong>Error!</strong> Choose at least one product.
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
    </button>
    </div>`
      : "";
    if (errorMessage != "") {
      document.getElementById("errCheckMessage").innerHTML = errorMessage;
      return false;
    }
  }
  function otherValidation() {
    if (document.getElementById("primarycontact").value === "") {
      document.getElementById("errorPrimarycontact").innerText =
        "Enter primary contact";
    }
    if (document.getElementById("techContactEmail").value === "") {
      document.getElementById("errorTechEmail").innerText =
        "Enter technical email";
    }
    if (document.getElementById("techcontactname").value === "") {
      document.getElementById("errorTechnicalcontactname").innerText =
        "Enter technical contact name.";
    }

    if (document.getElementById("techContactNo").value === "") {
      document.getElementById("errorTechMobile").innerText =
        "Enter technical contact no.";
    } else {
      if (document.getElementById("techContactNo").length < 10) {
        document.getElementById("errorTechMobile").innerText =
          "Enter valid contact no.";
        return false;
      }
    }
    if (document.getElementById("competitor").value === "") {
      document.getElementById("errorCompetitor").innerText =
        "Choose Competitor";
    }

    return true;
  }
  const handleSelectCompetitor = async (selectedOption) => {
    setSelectedOption(selectedOption);
    
    selectedOption.map((val, ind) => {
    if(val.value === '5'){
     
    document.querySelector("#otherCompetitorCompany").style.display = "block";
    }
    else {
      document.querySelector("#otherCompetitorCompany").style.display = "none";
    }
  })
    setCompetitor(selectedOption);
    showothercompeter(selectedOption);

    console.log(selectedOption);
  };

  //competitorData = {
  //   jsonCompetitor: JSON.stringify(resultcom),
  //   userId: userId
  // };
  //const initialSelectedOptions = resultcom;
  const options = [
    { value: "1", label: "Siemens" },
    { value: "2", label: "Philips" },
    { value: "3", label: "Dräger" },
    { value: "4", label: "G Health Care" },
    { value: "5", label: "Other" }
  ];

  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber); // starts with 0, so 0 is January
    return date.toLocaleString("en-EN", { month: "long" });
  }
  useEffect(() => {
    var pageView = localStorage.getItem("userName");
    var role = localStorage.getItem("userRole");
    var companyName = localStorage.getItem("companyName");
    var customerId = localStorage.getItem("customerId");
    setCustomerId(customerId);
    if (pageView == null && role == null) {
      window.location.href = "/";
    } else {
      setCompanyname(companyName);
    }
    getTrackStep();
    getCountry();
    getState();
    getCity();
    getProduct();
    getIndustryType();
    getMonthName(1);
    getLeadDetails();
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CriterionTech - Lead Details</title>
        <link
          rel="canonical"
          href="http://localhost:5000/download-certificate"
        />
      </Helmet>
      <div className="main-wrapper" id="mobilemenu">
        <ManageHeader />
        <ManageSidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {dealdetails && dealdetails.length > 0 ? (
                      dealdetails.map((val, ind) => {
                        return (
                          <>
                            <h4 className="header-title mb-4"> Edit Lead</h4>
                            <div id="textMessage"></div>
                            <div id="errorMessage"></div>
                            <ul className="nav nav-tabs nav-bordered nav-justified">
                              <li className="nav-item nevhead">
                                <a
                                  href="#home-b2"
                                  data-bs-toggle="tab"
                                  aria-expanded="true"
                                  className="nav-link active"
                                >
                                  Company Details
                                </a>
                              </li>
                              <li className="nav-item nevhead">
                                <a
                                  href="#profile-b2"
                                  data-bs-toggle="tab"
                                  aria-expanded="false"
                                  className="nav-link "
                                >
                                  Products
                                </a>
                              </li>
                              <li className="nav-item nevhead">
                                <a
                                  href="#messages-b2"
                                  data-bs-toggle="tab"
                                  aria-expanded="false"
                                  className="nav-link"
                                >
                                  Other Details
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div className="tab-pane active" id="home-b2">
                                <div className="mb-4">
                                  <h5>Customer Details</h5>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Orgnization Name{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <input
                                        type="hidden"
                                        name="dealid"
                                        id="dealid"
                                        value={dealid}
                                        onChange={handlechage}
                                      />
                                      <input
                                        className="form-control textcaptlizes"
                                        type="text"
                                        value={companyName}
                                        required
                                        placeholder="Enter orgnization name"
                                        name="companyname"
                                        id="companyname"
                                        onChange={handlechage}
                                      />
                                      <span
                                        id="errorCompanyName"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                  </div>

                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Contact Person{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <input
                                        className="form-control textcaptlizes"
                                        value={contectperson}
                                        type="text"
                                        required
                                        placeholder="Enter name"
                                        name="contectperson"
                                        id="contectperson"
                                        onChange={handlechage}
                                      />
                                      <span
                                        id="errorName"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Email{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="email"
                                        value={email}
                                        required
                                        placeholder="Enter email"
                                        name="email"
                                        id="email"
                                        onChange={handlechage}
                                      />
                                      <span
                                        id="errorEmail"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Mobile No.{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        value={mobile}
                                        type="number"
                                        required
                                        placeholder="Enter mobile"
                                        name="mobile"
                                        id="mobile"
                                        onChange={handlechage}
                                      />
                                      <span
                                        id="errorMobile"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Country{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <select
                                        className="selectstyle select"
                                        name="country"
                                        id="country"
                                        required
                                        onChange={handlechage}
                                      >
                                        <option value={-1}>
                                          Select country
                                        </option>
                                        {country &&
                                          country.map((val, ind) => {
                                            return (
                                              <option
                                                selected={
                                                  countryname === val.id
                                                }
                                                value={val.id}
                                              >
                                                {val.countryName}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      <span
                                        id="errorCountry"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        State{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <select
                                        className="selectstyle select"
                                        name="state"
                                        required
                                        id="state"
                                        onChange={handlechage}
                                      >
                                        <option value={-1}>Select state</option>
                                        <option
                                          value={val.companyStateID}
                                          selected
                                        >
                                          {val.companyStateName}
                                        </option>
                                        {state &&
                                          state.map((val, ind) => {
                                            return (
                                              <option value={val.id}>
                                                {val.stateName}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      <span
                                        id="errorState"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        City{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <select
                                        className="selectstyle select"
                                        name="city"
                                        required
                                        id="city"
                                        onChange={handlechage}
                                      >
                                        <option value={-1}>Select city</option>
                                        <option
                                          value={val.companyCityID}
                                          selected
                                        >
                                          {val.companyCityName}
                                        </option>
                                        {city &&
                                          city.map((val, ind) => {
                                            return (
                                              <option value={val.id}>
                                                {val.name}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      <span
                                        id="errorCity"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms ">
                                      <label>
                                        Customer Address
                                        <span className="login-danger">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={companyaddress}
                                        required
                                        placeholder="Enter company address"
                                        name="companyaddress"
                                        id="companyaddress"
                                        onChange={handlechage}
                                      />
                                      <span
                                        id="errorAddress"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms ">
                                      <label>
                                        Postal Code/Zip Code
                                        <span className="login-danger">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="number"
                                        value={postalcode}
                                        required
                                        placeholder="Enter postal code"
                                        name="postalcode"
                                        id="postalcode"
                                        onChange={handlechage}
                                      />
                                      <span
                                        id="errorPostal"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms ">
                                      <label>
                                        Website
                                        <span className="login-danger">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={website}
                                        required
                                        placeholder="Enter website"
                                        name="website"
                                        id="website"
                                        onChange={handlechage}
                                      />
                                      <span
                                        id="errorWebsite"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Industry{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <select
                                        className="selectstyle select"
                                        name="industry"
                                        required
                                        id="industry"
                                        onChange={handlechage}
                                      >
                                        <option value={-1}>
                                          Select Industry{" "}
                                        </option>
                                        {industrylist &&
                                          industrylist.map((val, ind) => {
                                            return (
                                              <option
                                                selected={industry === val.id}
                                                value={val.id}
                                              >
                                                {val.industryType}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      <span
                                        id="errorIndustry"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-4">
                                    <div className="form-group local-forms">
                                      <label>Existing Customer </label>
                                      <br />
                                      <div className="radio">
                                        &nbsp;&nbsp;
                                        <lable>
                                          {val.isExixtingCustomer === 1 ? (
                                            <>
                                              <input
                                                type="radio"
                                                name="noofexcustomer"
                                                value="1"
                                                checked={val.isExixtingCustomer === 1}
                                                onClick={handlechage}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <input
                                                type="radio"
                                                name="noofexcustomer"
                                                value="1"
                                                onClick={handlechage}
                                              />
                                            </>
                                          )}
                                          Yes
                                        </lable>
                                        &nbsp;&nbsp;
                                        <lable>
                                          {val.isExixtingCustomer === 0
                                           ? (
                                            <>
                                              <input
                                                type="radio"
                                                name="noofexcustomer"
                                                value="0"
                                                checked={val.isExixtingCustomer === 0}
                                                onClick={handlechage}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <input
                                                type="radio"
                                                name="noofexcustomer"
                                                value="0"
                                                onClick={handlechage}
                                              />
                                            </>
                                          )}
                                          No{" "}
                                        </lable>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-4 mb-2">
                                    <button
                                      className="btn btn-primary"
                                      name="saveDeal"
                                      id="saveDeal"
                                      onClick={() => {
                                        SaveDeal();
                                      }}
                                    >
                                      {" "}
                                      Update{" "}
                                      <i className="bx bx-chevron-right ms-1"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane" id="profile-b2">
                                <div className="mb-4">
                                  <span id="errCheckMessage"></span>
                                  <span id="deleteproMsg"></span>
                                  <span id="proMessage"></span>
                                  <h5>Selected Product</h5>
                                </div>

                                <ul className="productsec">
                                  {selectproductlist &&
                                    selectproductlist.map((val, ind) => {
                                      return (
                                        <li className="productitem" key={ind}>
                                          <label>
                                            <span class="badge bg-primary badge-size">
                                              {val.productName}{" "}
                                              <i
                                                class="fa fa-times"
                                                onClick={() => {
                                                  deleteDealProduct(val.id);
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </label>
                                        </li>
                                      );
                                    })}
                                </ul>
                                <div className="mb-4">
                                  <span id="errCheckMessage"></span>
                                  <h5>New Product</h5>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <h5>Software</h5>
                                    <ul className="productsec">
                                      {productlist &&
                                        productlist.map((val, ind) => {
                                          return (
                                            val.categoryId === 1 && (
                                              <li
                                                className="productitem"
                                                key={ind}
                                              >
                                                <label>
                                                  <input
                                                    type="checkbox"
                                                    value={val.id}
                                                    name="productname"
                                                    id="productname"
                                                    onChange={handlechage}
                                                  />

                                                  <span>
                                                    &nbsp;{val.productName}
                                                  </span>
                                                </label>
                                              </li>
                                            )
                                          );
                                        })}
                                    </ul>
                                  </div>
                                  <div className="col-lg-6">
                                    <h5>Hardware</h5>
                                    <ul className="productsec">
                                      {productlist &&
                                        productlist.map((val, ind) => {
                                          return (
                                            val.categoryId === 2 && (
                                              <li className="productitem">
                                                <label>
                                                  <input
                                                    type="checkbox"
                                                    value={val.id}
                                                    name="productname"
                                                    id="productname"
                                                    onChange={handlechage}
                                                  />

                                                  <span>
                                                    &nbsp;{val.productName}
                                                  </span>
                                                </label>
                                              </li>
                                            )
                                          );
                                        })}
                                    </ul>
                                  </div>
                                  <div className="col-12 col-sm-4 mb-2">
                                    <button
                                      className="btn btn-primary"
                                      name="updateDealproduct"
                                      id="updateDealproduct"
                                      onClick={() => {
                                        updateleadProduct();
                                      }}
                                    >
                                      {" "}
                                      Update{" "}
                                      <i className="bx bx-chevron-right ms-1"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane" id="messages-b2">
                                <div className="mb-4">
                                  <h5>Other Details</h5>
                                </div>

                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="form-group local-forms">
                                      <label
                                        for="progresspill-cardno-input"
                                        className="form-label"
                                      >
                                        Primary Contact Name
                                      </label>
                                      <input
                                        type="text"
                                        name="primarycontact"
                                        value={primarycontact}
                                        className="form-control textcaptlizes"
                                        placeholder="Primary Contact Name"
                                        id="primarycontact"
                                        onChange={handlechage}
                                      />
                                      <span
                                        id="errorPrimarycontact"
                                        class="errormsg"
                                      ></span>
                                    </div>

                                    <div className="form-group local-forms">
                                      <label
                                        for="progresspill-card-verification-input"
                                        className="form-label"
                                      >
                                        Designation
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control textcaptlizes"
                                        value={techcontactname}
                                        placeholder="Designation"
                                        name="techcontactname"
                                        id="techcontactname"
                                        onChange={handlechage}
                                      />
                                      <span
                                        id="errorTechnicalcontactname"
                                        class="errormsg"
                                      ></span>
                                    </div>

                                    <div className="form-group local-forms">
                                      <label
                                        for="progresspill-expiration-input"
                                        className="form-label"
                                      >
                                        Phone No.
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={techContactNo}
                                        placeholder="Phone No."
                                        name="techContactNo"
                                        id="techContactNo"
                                        onChange={handlechage}
                                      />
                                      <span
                                        id="errorTechMobile"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                    <div className="form-group local-forms">
                                      <label
                                        for="progresspill-expiration-input"
                                        className="form-label"
                                      >
                                        Remark
                                      </label>
                                      <textarea
                                        required
                                        className="form-control"
                                        placeholder="Remark"
                                        value={remark}
                                        name="remark"
                                        id="remark"
                                        onChange={handlechage}
                                      ></textarea>
                                      <span
                                        id="errorTechEmail"
                                        className="errormsg"
                                      ></span>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group local-forms">
                                      <label
                                        for="progresspill-expiration-input"
                                        className="form-label"
                                      >
                                        Email Id
                                      </label>
                                      <input
                                        type="email"
                                        required
                                        className="form-control"
                                        placeholder="Email Id"
                                        value={techContactEmail}
                                        name="techContactEmail"
                                        id="techContactEmail"
                                        onChange={handlechage}
                                      />
                                      <span
                                        id="errorTechEmail"
                                        className="errormsg"
                                      ></span>
                                    </div>

                                    <div className="form-group local-forms">
                                      <label>
                                        Competitor{" "}
                                        <span className="login-danger"></span>
                                      </label>
                                      <Select
                                        className="selectstyle select"
                                        name="competitor"
                                        id="competitor"
                                        isMulti
                                        placeholder={
                                          <div>Select Competitor</div>
                                        }
                                        options={options}
                                        onChange={handleSelectCompetitor}
                                        value={selectedOption}
                                      />
                                      
                                      <span
                                        id="errorCompetitor"
                                        className="errormsg"
                                      ></span>
                                    </div>
                                    
                                    <div
                                      className="form-group local-forms"
                                      id="otherCompetitorCompany"
                                    >
                                      <label
                                        for="progresspill-expiration-input"
                                        className="form-label"
                                      >
                                        Other Competitor
                                      </label>
                                      <input
                                        type="text"
                                        required
                                        className="form-control"
                                        placeholder="Other Competitor Name"
                                        value={otherCompetitor}
                                        name="otherCompetitor"
                                        id="otherCompetitor"
                                        onChange={handlechage}
                                      />
                                      <span
                                        id="errorTechEmail"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                    <div className="form-group local-forms">
                                      <label
                                        for="progresspill-expiration-input"
                                        className="form-label"
                                      >
                                        Estimated Budget
                                      </label>
                                      <input
                                        type="text"
                                        required
                                        className="form-control"
                                        placeholder="Estimated Budget"
                                        value={estimatebudget}
                                        name="estimateBudget"
                                        id="estimateBudget"
                                        onChange={handlechage}
                                      />
                                      <span
                                        id="errorTechEmail"
                                        class="errormsg"
                                      ></span>
                                    </div>
                                   
                                  </div>
                                  <div className="col-12 col-sm-4">
                                    <span id="errStepMessage"></span>
                                    <span id="deletestepMsg"></span>
                                    <ul className="productsec">
                                      <h6>Selected Next Step</h6>
                                      {nextdealdeatils &&
                                      nextdealdeatils.length > 0 ? (
                                        nextdealdeatils.map((val, ind) => {
                                          return (
                                            <>
                                              <li
                                                className="productitem"
                                                key={ind}
                                              >
                                                <label>
                                                  <span class="badge bg-primary badge-size">
                                                    {val.stepName}{" "}
                                                    <i
                                                      class="fa fa-times"
                                                      onClick={() => {
                                                        deletestep(val.id);
                                                      }}
                                                      aria-hidden="true"
                                                    ></i>
                                                  </span>
                                                </label>
                                              </li>
                                            </>
                                          );
                                        })
                                      ) : (
                                        <span>No results found</span>
                                      )}
                                    </ul>
                                    <h6>Next Step</h6>
                                    <ul>
                                      {tractlist &&
                                        tractlist.map((val, ind) => {
                                          return (
                                            <li
                                              className="productitem"
                                              key={ind}
                                            >
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  value={val.id}
                                                  name="nextstep"
                                                  id="nextstep"
                                                />{" "}
                                                <span>{val.name}</span>
                                              </label>
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  </div>
                                  <div className="col-12 col-sm-4 mb-2">
                                    <button
                                      className="btn btn-primary"
                                      name="saveDeal"
                                      id="saveDeal"
                                      onClick={() => {
                                        SaveDeal();
                                      }}
                                    >
                                      {" "}
                                      Update{" "}
                                      <i className="bx bx-chevron-right ms-1"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <div className="col-md-6" style={{ textAlign: "center" }}>
                        No results found
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
