export default async function SubLogin(loginForm) {
    let userId = loginForm.Email;
    let password = loginForm.Password;
    let url =window.UserbaseUrl+"/api/SubApplicationRequest/LoginSubApplicationRequestUser?Email="+userId+"&Password="+password;
    let head={ 'Content-Type': 'application/json', 'accept': '*/*'};
    let responsonse = await fetch(url,{
        method:'GET',
        headers : head
        // body : JSON.stringify(data),
    })
    
    .then(res=>res.json())
    
    .then(loginForm)
    
    return responsonse
}





