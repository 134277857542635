
import React,{useEffect,useState} from 'react';
import axios from 'axios';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import Footer from "../components/Footer";
import GetPartnerById from "../API/GetPartnerById";
import ManageSidebar from '../components/ManageSidebar';
import GetCountry from "../API/GetCountry";
import GetState from "../API/GetState";
import GetCity from "../API/GetCity";
import Getindustry from '../API/Getindustry';
import UpdateProfile from "../API/UpdateProfile";
import UpdatePassword from "../API/UpdatePassword";

export default function Profile(){  
    let [country,CountryList] = useState();
    let [state, StateList] = useState();
    let [city, CityList] = useState();
    let [contectpersonval, setContectpersonval] = useState();
    let [emailval, setEmailval] = useState("");
    let [mobileval, setMobileval] = useState();
    let [companyNameval, setCompanynameval] = useState();
    let [companycontactval,setCompanycontactval] = useState();
    let [countryval, setCountry] = useState();
    let [statenameval, setState] = useState();
    let [citynameval, setCity] = useState();
    let [companyaddressval, setCompanyaddress] = useState();
    let [postalcodeval, setPostalcodeval] = useState("");
    let [websiteval, setWebsite] = useState();
    let [password, setPassword] = useState("");
    let [newpassword, setNewpassword] = useState("");
  
    let [noofemployeeval, setPerson] = useState();
    let [noofexcustomerval, setNoofexcustomer] = useState();
    let [profileval, setProfileval] = useState();
    let [dealid,setDealid] = useState();
    let [partnerprofile, setPartnerData] = useState();
    let [joblist, setJobList] = useState();
    let [industry,setIndustry] = useState();
    let [profileimage, setProfileImage] = useState('assets/img/icons/certificate-log.png');
    const [file, setFile] = useState();

    
    let clear = async () =>{
      document.getElementById("updatepassword").reset();
  }

    let getCountry= async () => {
        let resp = await GetCountry();
        if(resp.status === 1)
        {
            CountryList(resp.responseValue);
            getState();
        }
      };

      let getIndustry = async()=>{
        let resp = await Getindustry();
        if(resp.status === 1)
        {
            setIndustry(resp.responseValue);  
        }
        
      }

      let getState = async(e)=>{
        
        let resp = await GetState(e);
        if(resp.status === 1)
        {
            StateList(resp.responseValue);
        }
      }
      let getCity = async(e)=>{
        
        let resp = await GetCity(e);
        if(resp.status === 1)
        {
            CityList(resp.responseValue);
        }
      }

    
    let getPartner = async () => {
        var userId = localStorage.getItem("userId");
       
        let resp = await GetPartnerById(userId);
        if(resp.status === 1)
        {
           setPartnerData(resp.responseValue) 
           document.getElementById('dealid').value = resp.responseValue[0].id;
           document.getElementById('contectpersonval').value = resp.responseValue[0].contactPerson;
           document.getElementById('emailval').value = resp.responseValue[0].emailID;
           document.getElementById('mobileval').value = resp.responseValue[0].mobile;
           document.getElementById('companycontactval').value = resp.responseValue[0].companyMobile;
           //document.getElementById('companynameval').value = resp.responseValue[0].companyName;
           document.getElementById('companynameval').value = resp.responseValue[0].companyName;
          //  document.getElementById('countryval').value = resp.responseValue[0].companyCountryID;
          //  document.getElementById('companyaddressval').value = resp.responseValue[0].companyAddress;
           window.localStorage.setItem('profileimage', resp.responseValue[0].profileImage);
           setProfileImage(window.UserbaseUrl+'/files/'+resp.responseValue[0].profileImage);
           document.getElementById('websiteval').value = resp.responseValue[0].website;
           document.getElementById('noofemployeeval').value = resp.responseValue[0].noOfEmployee;
           document.getElementById('noofexcustomerval').value = resp.responseValue[0].noOfCustomer;
           document.getElementById('profileval').value = resp.responseValue[0].jobTitleID;
           // password
           document.getElementById('password').value = resp.responseValue[0].password;

          
        }
      };
      let handlechage = async (e) => {
        //clear();
        let name = e.target.name;
        let value = e.target.value;
        if(name === 'addDeal'){
          setDealid(value)
        }
        if (name === 'contectpersonval') {
       
          setContectpersonval(value)
          
      }
      if (name === 'emailval') {
        setEmailval(value)
      }
      if (name === 'mobileval') {
        setMobileval(value)
       }
       if (name === 'companynameval') {
        setCompanynameval(value)
       }
       if(name === 'companycontactval'){
        setCompanycontactval(value)
       }
        
        if (name === 'websiteval') {
          setWebsite(value)
        }
        if (name === 'noofemployeeval') {
          setPerson(value)
        }
        if (name === 'noofexcustomerval') {
          setNoofexcustomer(value)
        }
        if (name === 'profileval') {
            setProfileval(value)
        }
        if (name === 'password') {
            setPassword(value)
          }
          if (name === 'newpassword') {
              setNewpassword(value)
          }
        
        
      };
      let getPic = async() =>{
        document.getElementById('uploadpic').click();
      }
      const handleFileChange = (e) => {
        var emailID = localStorage.getItem("emailID");
        
        if (e.target.files) {
          setFile(e.target.files[0]);
          setProfileImage(URL.createObjectURL(e.target.files[0]));

          const imgFile = document.getElementById('uploadpic');
        
        if(imgFile.files.length>0){
        const formData = new FormData();
        formData.append(
          "attachmentFiles",
          imgFile.files[0],
          imgFile.files[0].name
      );
      formData.append('emailid',emailID);
      let url =window.UserbaseUrl+"/api/ApplicationRequest/UpdateProfileImage";
      const resps = axios.post(url, formData);
      console.log(resps);
      
      clear();
      // if(resps.status === 1){
        document.getElementById("proMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
        <strong>Success!</strong> Profile image are updated successfully.
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
        </button>
        </div>`;
        window.setTimeout(function() {
          setProfileImage(window.UserbaseUrl+'/files/'+imgFile.files[0].name);
          document.getElementById("proMessage").style.display='none';
          window.location.href = '/profile';
          }, 2000);
        }
      }
      };
      let togglePass = async() =>{
        let field = document.getElementById('password');
        let iconsImg = document.getElementById('passicon');
        if(field.getAttribute('type') == 'password')
        {
          field.setAttribute("type","text");
        }
        else 
        {if(field.getAttribute('type') == 'text')
        {
          field.setAttribute("type","password");
        }
      }
      iconsImg.classList.toggle('feather-eye-off');
        }
        let togglePass1 = async() =>{
          let field1 = document.getElementById('newpassword');
          let iconsImg1 = document.getElementById('newpasswordicon');
          if(field1.getAttribute('type') == 'password')
          {
            field1.setAttribute("type","text");
          }
          else 
          {if(field1.getAttribute('type') == 'text')
          {
            field1.setAttribute("type","password");
          }
        }
        
        iconsImg1.classList.toggle('feather-eye-off');
          }
      let updateProfile = async()=>{
        var customerId = localStorage.getItem("customerId");
        if(document.getElementById('companycontactval').value === ""){
          document.getElementById("errorCompanyMobileval").innerText = 'Enter Company Contact No';
          return false;
        }
        else if(document.getElementById('websiteval').value === ""){

          var weburl = /^(http|https):\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
        var websiteurl = document.getElementById('websiteval').value;
        if(!weburl.test(websiteurl))
        {
          document.getElementById("errorWebsite").innerText = 'Enter valid company website';
          return false;
        }
  
          document.getElementById("errorWebsite").innerText = 'Enter company website';
          return false;
        }
        else if(document.getElementById('noofemployeeval').value === ""){
          document.getElementById("errorNoemployee").innerText = 'No of Employee';
          return false;
        }
        
        else if(document.getElementById('noofexcustomerval').value ===""){
         
            document.getElementById("errorNoCustomer").innerText = 'Enter No of Customer';
            return false;
        }
        else if(document.getElementById('profileval').value === "-1"){
            document.getElementById("errorIndustry").innerText = 'Select industry type';
            return false;
        }
        else {
        const sendData = {
          "customerID" : customerId,
          "companyMobile" : document.getElementById('companycontactval').value,
          "website" : document.getElementById('websiteval').value,
          "noOfEmployee" : document.getElementById('noofemployeeval').value,
          "noOfCustomer" : document.getElementById('noofexcustomerval').value,
          "jobTitleID" : document.getElementById('profileval').value
          }
        
          const resp = await UpdateProfile(sendData);
          //clear();
          
          if(resp.status === 1){
            document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>Success!</strong> Profile are updated successfully.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
            </div>`;
            window.setTimeout(function() {
                document.getElementById('textMessage').style.display='none';
                window.location.href = '/profile';
                }, 1000);
          }
          else{
            document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>Error!</strong> Profile are not updated.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
            </div>`;
            window.setTimeout(function() {
                document.getElementById('errorMessage').style.display='none';
                }, 1000);
          }
        }
      }
      let updatePass = async() =>{
        var emailID = localStorage.getItem("emailID");
        if(document.getElementById('password').value =='' || document.getElementById('password').value == ''){
          document.getElementById("errorCheckpass").innerText = 'Enter password.';
      }
      else if(document.getElementById('newpassword').value ==''){
        document.getElementById("errorRepasspass").innerText = 'Enter confirm password';
       }
        else if(document.getElementById('password').value != document.getElementById('newpassword').value){
            document.getElementById("errorRepasspass").innerText = 'Password are not Matched.';
        }
        else {
        
        const sendData = {
          "emailID" : emailID,
          "password" : document.getElementById('password').value,
          }
        
          const resp = await UpdatePassword(sendData);
       
          clear();
          if(resp.status === 1){
            document.getElementById("textMessage1").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>Success!</strong> Password are updated successfully.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
            </div>`;
            window.setTimeout(function() {
       
                 document.getElementById('textMessage1').style.display='none';
                }, 1000);
          }
          else{
            document.getElementById("errorMessage1").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>Error!</strong> Password are not updated.
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
            </div>`;
            window.setTimeout(function() {
                document.getElementById('errorMessage1').style.display='none';
                }, 1000);
          }
        }
      }
      useEffect(() => {
        getPartner();
        getCountry();
        getState();
        getCity();
        getIndustry();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
      }, []);

        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Profile</title>
                <link rel="canonical" href="http://localhost:5000/profile" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar/>
                <div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col">
<h3 className="page-title">Profile</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active">Profile</li>
</ul>
</div>
</div>
</div>

<div className="row">
<div className="col-md-12">
<div className="profile-header">
<div className="row align-items-center">
  <span id="proMessage"></span>
<div className="col-auto profile-image">
<a href="#">
<img className="rounded-circle" alt="User Image" src={profileimage}/>
<input id="uploadpic" name='uploadpic' type="file" onChange={handleFileChange}/>
<a className="edit-profile_pic" href="javascript:;" onClick={()=>{getPic();}}><i className="far fa-edit me-1"></i></a>
</a>
</div>
<div className="col ms-md-n2 profile-user-info">
<h4 className="user-name mb-0" style={{"text-transform": "capitalize;"}}>{localStorage.getItem("userName")}</h4>
<h6 className="text-muted">{localStorage.getItem("companyName")}({localStorage.getItem("customerId")})</h6>
</div>

</div>
</div>
<div className="profile-menu">
<ul className="nav nav-tabs nav-tabs-solid">
<li className="nav-item">
<a className="nav-link active" data-bs-toggle="tab" href="#per_details_tab">About</a>
</li>
<li className="nav-item">
<a className="nav-link" data-bs-toggle="tab" href="#password_tab">Password</a>
</li>
</ul>
</div>
<div className="tab-content profile-tab-cont">

<div className="tab-pane fade show active" id="per_details_tab">
{
partnerprofile && partnerprofile.length > 0 ?
partnerprofile.map((val, ind) => { return (
<div className="row">
<div className="col-lg-12">
<div className="card">
<div className="card-body">
<h5 className="card-title d-flex justify-content-between">
<span>Personal Details</span>
<a className="edit-link" data-bs-toggle="modal" href="#edit_personal_details"><i className="far fa-edit me-1"></i>Edit</a>
</h5>
<div className="row">
<p className="col-sm-2 text-muted fw-bold text-sm-start mb-0 mb-sm-3">Name</p>
<p className="col-sm-4" style={{"text-transform": "capitalize;"}}>{val.contactPerson}</p>
<p className="col-sm-2 text-muted fw-bold text-sm-start mb-0 mb-sm-3">Email</p>
<p className="col-sm-4">{val.emailID}</p>
</div>
<div className="row">
<p className="col-sm-2 text-muted fw-bold text-sm-start mb-0 mb-sm-3">Contact No.</p>
<p className="col-sm-4">{val.mobile}</p>
<p className="col-sm-2 text-muted fw-bold text-sm-start mb-0 mb-sm-3">Company Name</p>
<p className="col-sm-4">{val.companyName}</p>
</div>
<div className="row">

<p className="col-sm-2 text-muted fw-bold text-sm-start mb-0 mb-sm-3">Company Mobile</p>
<p className="col-sm-4">{val.companyMobile}</p>
<p className="col-sm-2 text-muted fw-bold text-sm-start mb-0 mb-sm-3">Company Address</p>
<p className="col-sm-4">{val.companyAddress}</p>
</div>
<div className="row">

<p className="col-sm-2 text-muted fw-bold text-sm-start mb-0 mb-sm-3">Total Employee</p>
<p className="col-sm-4">{val.noOfEmployee}</p>
<p className="col-sm-2 text-muted fw-bold text-sm-start mb-0 mb-sm-3">Total Customer</p>
<p className="col-sm-4">{val.noOfCustomer}</p>
</div>
<div className="row">

<p className="col-sm-2 text-muted fw-bold text-sm-start mb-0 mb-sm-3">Website</p>
<p className="col-sm-4">{val.website}</p>
</div>

</div>
</div>
</div>

</div>
);
})
: <h5>No results found</h5>
}
</div>


<div id="password_tab" className="tab-pane fade">
<div className="card">
<div className="card-body">
<h5 className="card-title">Change Password</h5>
<div className="row">
<div className="col-md-10 col-lg-6">
<div id='textMessage1'></div><div id='errorMessage1'></div>
<form method='post' action='javascript:;' name='updatepassword' id='updatepassword' onChange={handlechage}>
<div className="form-group">
<label>New Password</label>
<input type="password" id='password' name='password' className="form-control" placeholder='New Password'/>
<span className="profile-toggle feather-eye toggle-password" id="passicon" onClick={()=>{togglePass();}}></span>
<span id="errorCheckpass" className='errormsg'></span>
</div>

<div className="form-group">
<label>Confirm Password</label>
<input type="password" className="form-control" id='newpassword' name="newpassword" placeholder='Confirm Password' onChange={handlechage}/>
<span className="profile-toggle feather-eye toggle-password" id="newpasswordicon" onClick={()=>{togglePass1();}}></span>
<span id="errorRepasspass" className='errormsg'></span><span className='errormsg' id='errorMatchpass'></span>
</div>
<button className="btn btn-primary" type="submit" name='editPassword' id='editPassword' onClick={()=>{updatePass();}}>Save Changes</button>
</form>
</div>
</div>
</div>
</div>
</div>

</div>
</div>
</div>
<Footer />
</div>
</div>
            </div>
            <div id="edit_personal_details" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display:"none", }}>
<div className="modal-dialog modal-lg">
<div className="modal-content">
<div className="modal-header">
<h4 className="modal-title">Update Profile</h4>
<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div className="modal-body p-4">
<div className="card comman-shadow">
<div className="card-body">
  <div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='updateLead' id='updateLead' onChange={handlechage}>
  <input type='hidden' id='dealid' name='dealid' />
<div className="row">
<div className="col-12">
<h5 className="form-title student-info">Profile Information <span><a href="javascript:;"><i className="feather-more-vertical"></i></a></span></h5>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Contact Name <span className="login-danger">*</span></label>
<input className="form-control" readOnly type="text" required placeholder="Enter name" name='contectpersonval' id="contectpersonval" onChange={handlechage}/>

</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Email <span className="login-danger">*</span></label>
<input className="form-control" type="email" readOnly required placeholder="Enter email" name='emailval' id='emailval' onChange={handlechage} />
<span id="errorEmailval"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Mobile No. <span className="login-danger">*</span></label>
<input className="form-control" type="number" readOnly size="15" required placeholder="Enter mobile" name='mobileval' id='mobileval' onChange={handlechage}/>
<span id="errorMobileval"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Company Contact No. <span className="login-danger">*</span></label>
<input className="form-control" type="number" size="15" required placeholder="Enter Company Contact no." name='companycontactval' id='companycontactval' onChange={handlechage}/>
<span id="errorCompanyMobileval" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Company Name <span className="login-danger">*</span></label>
<input className="form-control" type="text" readOnly required placeholder="Enter company Name" name='companynameval' id="companynameval" onChange={handlechage} />
<span id="errorCompanyNameval" class="errormsg"></span>
</div>
</div>
{/* <div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Country <span className="login-danger">*</span></label>
<select className="form-control select" name="countryval" id="countryval" required onChange={handlechage}>
<option value={-1}>Select Country</option>
{country &&  country.map((val, ind) => { return (<option value={val.id}>{val.countryName}</option>);
})} 
</select>
<span id="errorCountryval"></span>
</div>
</div> */}
{/* <div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>State <span className="login-danger">*</span></label>
<select className="form-control select" name="stateval" required id="stateval" onChange={handlechage}>
<option value={-1}>Select state</option>
{state &&  state.map((val, ind) => { return (<option value={val.id}>{val.stateName}</option>);
})} 
</select>
<span id="errorStateval"></span>
</div>
</div> */}
{/* <div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>City <span className="login-danger">*</span></label>
<select className="form-control select" name='cityval' required id="cityval" onChange={handlechage}>
<option>Select city</option>
{city &&  city.map((val, ind) => { return (<option value={val.id}>{val.name}</option>);
})} 
</select>
<span id="errorCityval"></span>
</div>
</div> */}
{/* <div className="col-12 col-sm-6">
<div className="form-group local-forms ">
<label>Company Address.<span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter company adddrss" name='companyaddressval' id='companyaddressval' onChange={handlechage}/>
</div>
</div> */}
{/* <div className="col-12 col-sm-6">
<div className="form-group local-forms ">
<label>Postal Code/zip code.<span className="login-danger">*</span></label>
<input className="form-control" type="text" maxlength="6" size="6" required placeholder="postal code" name='postalcodeval' id='postalcodeval' onChange={handlechage}/>
</div>
</div> */}
<div className="col-12 col-sm-6">
<div className="form-group local-forms ">
<label>Website<span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter company contact" name='websiteval' id='websiteval' onChange={handlechage} />
<span id="errorWebsite" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>No of Employee </label>
<input className="form-control" type="text" placeholder="No. of employee" name='noofemployeeval' id="noofemployeeval" onChange={handlechage} />
<span id="errorNoemployee" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>No of Customer </label>
<input className="form-control" type="text" placeholder="No. of exixting Customer" name='noofexcustomerval' id="noofexcustomerval" onChange={handlechage} />
<span id="errorNoCustomer" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Industry <span className="login-danger">*</span></label>
<select className="form-control select" name='profileval' required id="profileval" onChange={handlechage}>
<option value={-1}>Select Industry Type </option>
{industry &&  industry.map((val, ind) => { return (<option value={val.id}>{val.industryType}</option>);
})} 

</select>
<span id="errorIndustry" class="errormsg"></span>
</div>
</div>

</div>
</form>
</div>
</div>
</div>
<div className="modal-footer">
<button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal">Close</button>
<button type="submit" className="btn btn-info waves-effect waves-light" name='editProfile' id='editProfile' onClick={()=>{updateProfile();}} >Save changes</button>
</div>
</div>
</div>
</div>
    </React.Fragment>
    )
}

