export default async function Getproduct() {
    
    let url =window.UserbaseUrl+"/api/ProductMaster/SelectProduct";
    let head={ 'Content-Type': 'application/json-patch+json'};
    let response = await fetch(url,{
      method: "GET",
      headers: head
    }).then((res) => res.json());
    return response;
  }
