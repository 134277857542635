import React, {useState} from "react";
import {Helmet} from "react-helmet";
import SubLogin from '../API/SubLogin';

export default function SubUserLogin(){  
	const [userid, setUserId] = useState("");
	const [password, setPassword] = useState("");
	

	let clear = () => {
	  setPassword('')
	  setUserId('')
	
	};
	const loginSubUser = async () => {
		if(userid === ''){
			alert('Enter email id')
			return false;
		}
		else if(password === ''){
			alert('Enter password');
			return false;
		}
		else {
		const sendData = {
			"Email": userid,
			"Password": password,
			}
		
		  const resp = await SubLogin(sendData);
        if(resp.responseValue.status === 1)
		 { 
			var dataVal = resp.responseValue;
			if(dataVal.data[0].approvalStatus === 1)
		  {
		  window.localStorage.setItem('userId' , dataVal.data[0].id);
		  window.localStorage.setItem('emailID' , dataVal.data[0].email);
		  window.localStorage.setItem('subUserId' , dataVal.data[0].subUserId);
		  window.localStorage.setItem('userRole' , 'Sub User');
		  window.localStorage.setItem('companyName' , dataVal.data[0].companyName);
		  window.localStorage.setItem('companyId' , dataVal.data[0].requestId);
		  window.localStorage.setItem('userName' , dataVal.data[0].username);
		  window.localStorage.setItem('profileimage', dataVal.data[0].profileImage);
		  
		  if(window.localStorage.getItem('userId') !== "")
		  {      clear();
			   window.location.href = '/user-dashboard';
		  }
		}
		else{
			if(resp.responseValue[0].approvalStatus === 0)
			{
				document.getElementById("errorMsg").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
		  <strong>Sorry! </strong> You don't have access to the portal.<br/>Please contact our <a href="https://criteriontechnologies.com/contacts.html">Support Team</a>.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
			}
		}
	}
	else{
		if(resp.status === 0)
		{
		  document.getElementById("errorMsg").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
		  <strong>Sorry! </strong> Invalid Login id and Password.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
	}
	}
	}
	  }
	  let togglePass = async() =>{
		let field = document.getElementById('password');
		let iconsImg = document.getElementById('passicon');
		if(field.getAttribute('type') == 'password')
		{
			field.setAttribute("type","text");
		}
		else 
		{if(field.getAttribute('type') == 'text')
		{
			field.setAttribute("type","password");
		}
	}
	iconsImg.classList.toggle('feather-eye-off');
	  }
	  let handlechage = async (e) => {
    
		let name = e.target.name;
		let value = e.target.value;
		if (name === 'userid') {
		  setUserId(value)
		}
		else {
			setPassword(value)
		}
	  };
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Channel Partner Login</title>
                <link rel="canonical" href="http://localhost:5000/admin" />
            </Helmet>
         
    {/* <!-- /Preload --> */}
	
	<div className="main-wrapper login-body">
<div className="login-wrapper">
<div className="container">
<div className="loginbox">
<div className="login-left">
<div className="d-flex loginOption">
<a className="btn btn-primary btn-block" href="/"><i className="fa fa-lock"></i> Login to Partner</a>
<a className="btn btn-primary btn-block" href="subuserlogin"><i className="fa fa-lock"></i> Login to User</a></div>
<img className="img-fluid" src="assets/img/login_bg.jpg" alt="Logo"/>
</div>
<div className="login-right">
<div className="login-right-wrap">
<h1>Welcome to CriterionTech</h1>
<p class="account-subtitle">Need an account? <a href="/register">Sign Up</a></p>
<h2>Channel Partner Sub User Login</h2>
<div id="errorMsg"></div>
<form action="javascript:;"  method="post" className='loginform' id="loginform">
<div className="form-group">
<label>Email Id <span className="login-danger">*</span></label>
<input className="form-control" required type="email" id='userid' name="userid" value={userid} onChange={handlechage} placeholder="Enter registered email id*"/>
<span className="profile-views"><i className="fas fa-user-circle"></i></span>
</div>
<div className="form-group">
<label>Password <span className="login-danger">*</span></label>
<input className="form-control pass-input" required type="password" id="password" value={password} onChange={handlechage} placeholder='*******'/>
<span className="profile-views feather-eye toggle-password" id="passicon" onClick={()=>{togglePass();}}></span>
</div>
<div className="forgotpass">
<div className="remember-me">
<label className="custom_check mr-2 mb-0 d-inline-flex remember-me"> Remember me
<input type="checkbox" name="radio"/>
<span className="checkmark"></span>
</label>
</div>
<a href="/forgot-password">Forgot Password?</a>
</div>

<div className="form-group">
<button className="btn btn-primary btn-block" type="submit" onClick={() => {loginSubUser();}}>Login</button>
</div>

</form>
</div>
</div>
</div>
</div>
</div>
</div>
    </React.Fragment>
    )
}

