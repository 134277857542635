export default async function GetProductByDealId(data) {
    let url =window.UserbaseUrl+"/api/DealProduct/GetDealProductByDealId?DealId="+data;
     let head={ 'accept': ': */*'};
     //let data = loginForm;
     let responsonse = await fetch(url,{
         method:'GET',
         headers : head
     })
     .then(res=>res.json())
     .then(data)
     return responsonse
     }