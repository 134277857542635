import React , { useEffect, useState } from 'react'; 

import Sidebar from './Sidebar';

export default function Header() {  
    
const [userName, setUser] = useState(); 

// const logOut = () => {
//     window.localStorage.removeItem('userName');
//     window.localStorage.removeItem('userId');
//     window.localStorage.removeItem('clientId');
//     window.location.href = '/';
//   };
useEffect(() => {
//     var pageView = window.localStorage.getItem("userName");
//     if(pageView == null){
//         window.location.href = '/'; 
//     }
//     else {
//     setUser(pageView);
// }
  }, []);  
        return (
            <React.Fragment>  
               <footer>
<p>Copyright © 2023 CriterionTech.</p>
</footer>
    </React.Fragment>
    )
}