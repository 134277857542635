import React , { useEffect, useState } from 'react'; 
import AdminSidebar from './AdminSidebar';
import Sidebar from './Sidebar';
export default function ManageSidebar() {  
const [userRole, setRole] = useState(); 
useEffect(() => {
    var role = window.localStorage.getItem("userRole");
    setRole(role);
  }, []);  
        return (
            <React.Fragment>  
         {userRole == 'Channel Partner' && <Sidebar/>}   
         {userRole == 'Sub User' && <Sidebar/>}  
         {userRole == 'Administrator' && <AdminSidebar/>}     
    </React.Fragment>
    )
}