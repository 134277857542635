
import React,{useEffect,useState} from 'react';
import axios from 'axios';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import Footer from "../components/Footer";
import GetProductMaterial from "../API/GetProductMaterial";
import RemoveProductMaterial from "../API/RemoveProductMaterial";
// import GetCountry from "../API/GetCountry";
import UpdateProductMaterial from "../API/UpdateProductMaterial";
import GetproductMeterialbyId from "../API/GetproductMeterialbyId";
import Getproduct from "../API/Getproduct";
import ManageSidebar from '../components/ManageSidebar';

export default function ProductsMaterial(){  

    let [productmateriallist, setProductMaterial] = useState();
    let [productlist, setProduct] = useState();
    let [producttype,setProductType] = useState();
    let [documenttype, setDocumentType] = useState();
    let [productdocument, setDocumentFile] = useState();
    let [documentimage,setDocumentImage] = useState();
    let[metiralid,setProductMaterialId] = useState();
    const [file, setFile] = useState();

    let getProductMaterial = async () => {
        let resp = await GetProductMaterial();
        if(resp.status === 1)
        {
            setProductMaterial(resp.responseValue);  
        }
      };
      let getProduct = async () => {
        let resp = await Getproduct();
        if(resp.status === 1)
        {
            setProduct(resp.responseValue);  
        }
      };
      let clear = async () =>{
        document.getElementById("editproductmat").reset();
    }
      let removeProductMaterial = async (e) =>{
        let con = window.confirm('Are you sure want to delete this Record?');
        if(con == true)
        {
        const sendData = {
            "id": e,
               }
               const resp = await RemoveProductMaterial(sendData);
               getProductMaterial();
               if(resp.status === 1){
                   document.getElementById("deleteMsg").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong>Product Material are deleted successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
               window.setTimeout(function() {
                document.getElementById("deleteMsg").style.display='none';
                }, 2000);
               }
              }
              else {
                return false;
              }
      }
      let handlechage = async (e) => {
        //clear();
        let name = e.target.name;
        let value = e.target.value;
        let label = e.target.text;
      
        if (name === 'producttype') {
       
          setProductType(value)
      
      
      }
      if(name === 'documenttype'){
        setDocumentType(value)
        setDocumentFile('');
        var filedata = document.querySelector("#productdocument");
        if(value == 1)
        {
          filedata.setAttribute('accept', 'audio/*');
        }
        if(value == 2)
        {
          filedata.setAttribute('accept', 'video/*');
        }
        if(value == 3)
        {
          filedata.setAttribute('accept', '.ppt');
        }
        if(value == 4)
        {
          filedata.setAttribute('accept', '.pdf');
        }
      }
      if(name === 'metiralid'){
        setProductMaterialId()
      }
      if(name === 'productdocument'){
        setDocumentFile(value);
      
      }
      
      };
      const handleFileChange = (e) => {
        if (e.target.files) {
          setFile(e.target.files[0]);
          setDocumentImage(URL.createObjectURL(e.target.files[0]));
      }
      };
    let editProductMaterial = async(e) =>{
      const resp = await GetproductMeterialbyId(e);
        if(resp.status === 1)
         {
          document.getElementById('metiralid').value = resp.responseValue[0].id;
          document.getElementById('producttype').value = resp.responseValue[0].productID;
          document.getElementById('documenttype').value = resp.responseValue[0].documentType;
          document.getElementById('documentfile').value = resp.responseValue[0].documentFile;

          var filedata = document.querySelector("#productdocument");
          if(resp.responseValue[0].documentType == 1)
          {
            filedata.setAttribute('accept', 'audio/*');
          }
          if(resp.responseValue[0].documentType == 2)
          {
            filedata.setAttribute('accept', 'video/*');
          }
          if(resp.responseValue[0].documentType == 3)
          {
            filedata.setAttribute('accept', '.ppt');
          }
          if(resp.responseValue[0].documentType == 4)
          {
            filedata.setAttribute('accept', '.pdf');
          }
          
         }
    }
    let updateProductMateiral = async()=>{
      var userId = localStorage.getItem("userId");
      if(document.getElementById('producttype').value === "-1"){
        document.getElementById("errorName").innerText = 'select product Type';
      }
      else if(document.getElementById('documenttype').value === "-1"){
        document.getElementById("errorProvalue").innerText = 'select document type';
      }
      else{
        const formData = new FormData();
        if(document.getElementById('productdocument').value !="")
        {
          const imgFile = document.getElementById('productdocument');
        console.log(imgFile.files.length);
        if(imgFile.files.length>0){
      
      
        formData.append(
          "attachmentFiles",
          imgFile.files[0],
          imgFile.files[0].name
      );
      formData.append('DocumentFile', null);
        }
      }
        else{
          formData.append('DocumentFile', document.getElementById('documentfile').value);
        }
        
        formData.append('Id', document.getElementById('metiralid').value);
        formData.append('ProductID', document.getElementById('producttype').value);
        formData.append('DocumentTypeId', document.getElementById('documenttype').value);
        formData.append('UserId',userId);
        let url =window.UserbaseUrl+"/api/ProductMaterial/UpdateUploadDoc";
    
        const resps = axios.post(url, formData);
        getProductMaterial();
        clear();
        
          document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
          <strong>Success!</strong> Product Material are updated successfully.
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
          </button>
          </div>`;
         
          window.setTimeout(function() {
           
            document.getElementById("textMessage").style.display='none';
            window.location.href = '/product-material';
            }, 2000);
        
      }
    }
    let  searchTable = async()=> {
      // Declare variables
      var input, filter, table, tr, td, i, j, txtValue,found;
      input = document.getElementById("searchText");
      filter = input.value.toUpperCase();
      table = document.getElementById("tableData");
      tr = table.getElementsByTagName("tr");
    
      // Loop through all table rows, and hide those who don't match the search query
    
        for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td");
        for (j = 0; j < td.length; j++) {
            if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
                found = true;
            }
            
        }
        if (found) {
            tr[i].style.display = "";
            found = false;
        } else {
           tr[i].style.display = "none";

        }
    }
  
    }

       let changeFronTDate = (e) =>{
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(e)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
        }
      useEffect(() => {
        getProductMaterial();
        getProduct();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Product Material</title>
                <link rel="canonical" href="http://localhost:5000/product-material" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
                <div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Product Material</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href="/add-product-material" className="btn btn-primary p-2 text-white"><i className="fas fa-plus"></i> Add Product Material</a></li>
</ul>
</div>
</div>
</div>
</div>
<div className="row">
<div className="col-sm-12">
<div className="card card-table comman-shadow">
<div className="card-body">

<div className="page-header">
<div className="row align-items-center">
<span id="deleteMsg"></span>
<div className="col-9">
    
<h3 className="page-title">Products Material List</h3>
</div>
<div className="col-3 text-end float-end ms-auto download-grp">
<ul className='headerlist'><li className='headerlistitem'><div className="form-group">
<input type="text" className="form-control" placeholder="Search ..." id='searchText' name='searchText' onChange={()=>{searchTable();}}/>
</div></li>

</ul>
</div>
<div className="col-auto text-end float-end ms-auto download-grp">

{/* <a href="#" className="btn btn-outline-primary me-2"><i className="fas fa-download"></i> Download</a> */}
{/* <a href="/add-product-material" className="btn btn-primary"><i className="fas fa-plus"></i></a> */}
</div>
</div>
</div>

<div className="table-responsive fixTableHead">
<table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
<thead className="student-thread">
<tr>
<th>
S.No
</th>
<th>Product Name</th>
<th>Product Price</th>
<th>Documentation</th>
<th className="text-end">Action</th>
</tr>
</thead>
<tbody id="tableData">
{
productmateriallist && productmateriallist.length > 0 ?
productmateriallist.map((val, ind) => { return (
<tr>
<td>
{ind+1}
</td>
<td>{val.productName}</td>  
<td>{val.productValue === 0 && '--'}</td>   
<td>
  <a href={`https://demo.medvantage.tech:7098/files/${val.documentFile}`} target='_blank' >
{val.documentFile}</a></td>  
<td className="text-end">
<div className="actions ">
{/* <a href="javascript:;" className="btn btn-sm bg-success-light me-2 ">
<i className="feather-eye"></i>
</a> */}

<a href="javascript:;" data-bs-toggle="modal" onClick={() => {editProductMaterial(val.id);}} data-bs-target="#con-close-modal" className="btn btn-sm bg-danger-light">
<i className="feather-edit"></i>
</a>&nbsp;
{/* <a href="javascript:;" onClick={() => {approveDeal(val.id);}} className="btn btn-sm bg-danger-light">
<i className="feather-trash"></i>
</a> */}
<a href="javascript:;" onClick={() => {removeProductMaterial(val.id);}} className="btn btn-sm bg-danger-light">
<i className="feather-trash"></i>
</a>
</div>
</td>
</tr>
);
})
: <tr><td colspan="12" style={{textAlign :"center"}}>No results found</td></tr>
}
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div id="con-close-modal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display:"none", }}>
<div className="modal-dialog modal-lg">
<div className="modal-content">
<div className="modal-header">
<h4 className="modal-title">Update Product Material</h4>
<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div className="modal-body p-4">
<div className="card comman-shadow">
<div className="card-body">
  <div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='editproductmat' id='editproductmat' enctype="multipart/form-data">
  <input type='hidden' name='metiralid' id='metiralid' onChange={handlechage}/>
<div className="row">

<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Product Type <span className="login-danger">*</span></label>
<select className="form-control select" name='producttype' required id="producttype" onChange={handlechage}>
<option value={-1}>Select Product type </option>
{productlist &&  productlist.map((val, ind) => { return (<option value={val.id}>{val.productName}</option>);
})} 
</select>
<span id="errorName" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Document Type <span className="login-danger">*</span></label>
<select className="form-control select" name='documenttype' required id="documenttype" onChange={handlechage}>
<option value={-1}>Select Document Type </option>
<option value="1">Audio</option>
<option value="2">Video</option>
<option value="3">PPT</option>
<option value="4">PDF</option>
</select>
<span id="errorProvalue" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Document File <span className="login-danger">*</span></label>
<input type='text' className='form-control' name='documentfile' id='documentfile'/><br />
<input type="file" className='form-control' accept="" required placeholder="product document" name='productdocument' id="productdocument" onChange={handleFileChange}/>
<span id="errorProvalue" className='errormsg'></span>
{/* <div className='imgsec'>
<img src={documentimage} style={{"width":"150px"}} />
</div> */}
</div>


</div>
</div>
</form>
</div>
</div>
</div>
<div className="modal-footer">
<button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal">Close</button>
<button type="submit" className="btn btn-info waves-effect waves-light" name='updateProduct' id='updateProductMateiral' onClick={()=>{updateProductMateiral();}}>Save changes</button>
</div>
</div>
</div>
</div>
< Footer />

</div>
            </div>
    </React.Fragment>
    )
}

