
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import GetCountry from "../API/GetCountry";
import GetState from "../API/GetState";
import GetCity from "../API/GetCity";
import SaveTraining from "../API/SaveTraining";
import ManageSidebar from '../components/ManageSidebar';
import GetJobTitle from "../API/GetJobTitle";
import Getindustry from '../API/Getindustry';
//import Footer from "../components/Footer";

export default function Addtraining(){  

    let [country,CountryList] = useState();
    let [state, StateList] = useState();
    let [city, CityList] = useState();
    let [contectperson, setContectperson] = useState();
    let [email, setEmail] = useState();
    let [mobile, setMobile] = useState();
    let [companyName, setCompanyname] = useState();
    let [companycontact,setCompanycontact] = useState();
    let [countryname, setCountry] = useState();
    let [statename, setState] = useState();
    let [cityname, setCity] = useState();
    let [address, setAddress] = useState();
    let [designationname, setDesignationName] = useState();
    let [industryname, setIndustryName] = useState();
    let [industry,setIndustry] = useState();
    let [designation, setJobTitle] = useState();

    let clear = async () =>{
      document.getElementById("addDeal").reset();
      setContectperson('');
      setCompanycontact('');
      setCountry('');
      setState('');
      setCity('');


  }
  let getJobTitle = async()=> {
        
    let resp = await GetJobTitle();
    if(resp.status === 1)
    {
        setJobTitle(resp.responseValue);  
    }
  }
  let getIndustry = async()=>{
    let resp = await Getindustry();
    if(resp.status === 1)
    {
        setIndustry(resp.responseValue);  
    }
    
  }
    let getCountry= async () => {
        let resp = await GetCountry();
        if(resp.status === 1)
        {
            CountryList(resp.responseValue);
            getState();
        }
      };

      let getState = async(e)=>{
        
        let resp = await GetState(e);
        if(resp.status === 1)
        {
            StateList(resp.responseValue);
        }
      }
      let getCity = async(e)=>{
        
        let resp = await GetCity(e);
        if(resp.status === 1)
        {
            CityList(resp.responseValue);
        }
      }

      
let submitTraining = async() =>{
  var userId = localStorage.getItem("userId");
  var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  if(document.getElementById('contectperson').value === ""){
    document.getElementById("errorName").innerText = 'Enter name';
    return false;
  }
//  else if(document.getElementById('email').value === ""){
//     document.getElementById("errorEmail").innerText = 'Enter email';
//     return false;
//   }
  else if(regex.test(document.getElementById('email').value) === false){
    document.getElementById("errorEmail").innerText = 'Enter email';
    return false;
  }
  else if(document.getElementById('mobile').value === ""){
    document.getElementById("errorMobile").innerText = 'Enter mobile no';
    return false;
  }
  else if(document.getElementById('mobile').value.length < 10){
    document.getElementById("errorMobile").innerText = 'Enter valid phone no';
    return false;
  }
  else if(document.getElementById('country').value === "-1"){
    document.getElementById("errorCountry").innerText = 'select country';
    return false;
  }
  else if(document.getElementById('state').value === "-1"){
    document.getElementById("errorState").innerText = 'Select state';
    return false;
  }
  else if(document.getElementById('city').value === "-1"){
    document.getElementById("errorCity").innerText = 'Select city';
    return false;
  }
  else if(document.getElementById('address').value === ""){
    document.getElementById("errorAddress").innerText = 'Enter address';
    return false;
  }
  else if(document.getElementById('industryname').value === "-1"){
    document.getElementById("errorCompanyName").innerText = 'Select company';
    return false;
  }
  else if(document.getElementById('designationname').value === "-1"){
    document.getElementById("errorDesignation").innerText = 'Select designation';
    return false;
  }
  else{
  const sendData = {
    "email": email,
    "traineeName": contectperson,
    "mobile" : mobile,
    "countryID" : countryname,
    "stateID" : statename,
    "cityID" : cityname,
    "address" : address,
    "designationID" : designationname,
    "companyID" : industryname,
    "userID" : userId
    }
  console.log(sendData);
    const resp = await SaveTraining(sendData);
    
    if(resp.status === 1){
      clear();
      document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
		  <strong>Success!</strong> Trainee are added successfully.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
      window.setTimeout(function() {
        document.getElementById("textMessage").style.display='none';
        window.location.href = '/training';
        }, 2000);
    }
    else{
      if(resp.status === 0){
      document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
		  <strong>Error!</strong> Trainee are already exists!!".
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
    }
  }
  }
}

const checkmobileValidation = (e) =>{
  if(e.length < 10 || e.length >12){
    document.getElementById("errorMobile").innerText = 'Enter valid phone no';
    return false;
  }
  else{
    document.getElementById("errorMobile").innerText = '';
    return false;
  }
}

      let handlechage = async (e) => {
        //clear();
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'contectperson') {
       
          setContectperson(value)
          
      }
      if (name === 'email') {
        setEmail(value)
      }
      if (name === 'mobile') {
        setMobile(value)
        checkmobileValidation(value)
       }
       
       
        if (name === 'country') {
            getState(value)
            setCountry(value);
        }
        if (name === 'state') {
          getCity(value);
          setState(value);
          }
          if (name === 'city') {
            setCity(value);
        }
        if (name === 'company') {
          setCompanyname(value)
         }
        if (name === 'address') {
          setAddress(value)
        }
        
        if (name === 'designationname') {
          setDesignationName(value)
        }
        if (name === 'industryname') {
          setIndustryName(value)
        }
        
        
      };
     
      useEffect(() => {
        getCountry();
        getState();
        getCity();
        getJobTitle();
        getIndustry();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Add lead</title>
                <link rel="canonical" href="http://localhost:5000/add-dealer" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
              
<div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row align-items-center">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Add Training</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href='/training' >All Trainee</a></li>
</ul>
</div>
</div>
</div>
</div>

<div className="row">
<div className="col-sm-12">
<div className="card comman-shadow">
<div className="card-body">
  <div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='addDeal' id='addDeal'>
<div className="row">
<div className="col-12">
<h5 className="form-title student-info">Training Information <span><a href="javascript:;"><i className="feather-more-vertical"></i></a></span></h5>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Contact Person <span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter name" name='contectperson' id="contectperson" onChange={handlechage}/>
<span id="errorName" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Email <span className="login-danger">*</span></label>
<input className="form-control" type="email" required placeholder="Enter email" name='email' id='email' onChange={handlechage} />
<span id="errorEmail" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Mobile No. <span className="login-danger">*</span></label>
<input className="form-control" type="number" size="15" required placeholder="Enter mobile" name='mobile' id='mobile' onChange={handlechage}/>
<span id="errorMobile" className='errormsg'></span>
</div>
</div>


<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Country <span className="login-danger">*</span></label>
<select className="form-control select" name="country" id="country" onChange={handlechage}>
<option value={-1}>Select Country</option>
{country &&  country.map((val, ind) => { return (<option value={val.id}>{val.countryName}</option>);
})} 
</select>
<span id="errorCountry" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>State <span className="login-danger">*</span></label>
<select className="form-control select" name="state" required id="state" onChange={handlechage}>
<option value={-1}>Select state</option>
{state &&  state.map((val, ind) => { return (<option value={val.id}>{val.stateName}</option>);
})} 
</select>
<span id="errorState" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>City <span className="login-danger">*</span></label>
<select className="form-control select" name='city' required id="city" onChange={handlechage}>
<option value={-1}>Select city</option>
{city &&  city.map((val, ind) => { return (<option value={val.id}>{val.name}</option>);
})} 
</select>
<span id="errorCity" className='errormsg'></span>
</div>
</div>

<div className="col-12 col-sm-4">
<div className="form-group local-forms ">
<label>Address<span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter address" name='address' id='address' onChange={handlechage}/>
<span id="errorAddress" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Company <span className="login-danger">*</span></label>
<select className="form-control select" name='industryname' required id="industryname" onChange={handlechage}>
<option value={-1}>Select company </option>
{industry &&  industry.map((val, ind) => { return (<option value={val.id}>{val.industryType}</option>);
})} 
</select>
<span id="errorCompanyName" className='errormsg'></span>

</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Designation <span className="login-danger">*</span></label>
<select className="form-control select" name='designationname' required id="designationname" onChange={handlechage}>
<option value={-1}>Select designation </option>
{designation &&  designation.map((val, ind) => { return (<option value={val.id}>{val.designation}</option>);
})} 
</select>
<span id="errorDesignation" className='errormsg'></span>
</div>
</div>

<div className="col-12">
<div className="student-submit">
<button type="submit" name='saveDeal' id='saveDeal' onClick={()=>{submitTraining();}} className="btn btn-primary">Submit</button>
</div>
</div>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
            </div>
    </React.Fragment>
    )
}

