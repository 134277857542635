export default async function GetMeetsByUser(data) {
    
    let url =window.UserbaseUrl+"/api/CompanyMeeting/SelectCompanyMeetingByUser?UserID="+data;
        let head={ 'accept': ': */*'};
        //let data = loginForm;
        let responsonse = await fetch(url,{
            method:'GET',
            headers : head
        })
        .then(res=>res.json())
        .then(data)
        return responsonse
        }

