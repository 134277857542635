export default async function GetdealdyId(data) {
    // let url =window.MasterbaseUrl+"/api/StateMaster/GetCityMasterByStateId?id="+data;
    let url = window.UserbaseUrl+"/api/DealMaster/GetDealById?Id="+data;
     let head={ 'accept': ': */*'};
     //let data = loginForm;
     let responsonse = await fetch(url,{
         method:'GET',
         headers : head
     })
     .then(res=>res.json())
     .then(data)
     return responsonse
     }
   