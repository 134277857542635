import React, {useState,useEffect} from "react";
import axios from 'axios';
import {Helmet} from "react-helmet";
import GetPartner from '../API/GetPartner';
//import RegiserSubuser from '../API/RegiserSubuser';

export default function RegisterUser(){  
	const [company, setCompanyName] = useState("")
	const [username, setUserName] = useState("")
	const [password, setUserPassword] = useState("")
	const [email, setUserEmail] = useState("")
	const [contact, setUserContact] = useState("")
	const [confirmpassword, setUserConfirmPassword] = useState("")
	let [partnerlist, getPartner] = useState()
	

	let clear = () => {
		setCompanyName('')
		setUserName('')
		setUserPassword('')
		setUserEmail('')
		setUserContact('')
		setUserConfirmPassword('')
		document.getElementById('registerform').reset();
	};

	let getChannelPartner = async () => {
        let resp = await GetPartner();
        if(resp.status === 1)
        {
            getPartner(resp.responseValue);  
        }
      };

	  function formvalidation(){
		if(document.getElementById('company').value === ""){
			alert('Select Company')
			document.getElementById('company').focus();
			return false;
		}
		else if(username === ''){
			alert('Enter User name')
			document.getElementById('username').focus();
			return false;
		}
		else if(email === ''){
			alert('Enter email');
			document.getElementById('email').focus();
			return false;
		}
		else if(contact === ''){
			alert('Enter contact');
			document.getElementById('contact').focus();
			return false;
		}
		else if(password === ''){
			alert('Enter password');
			document.getElementById('password').focus();
			return false;
		}
		else if(confirmpassword === ''){
			alert('Enter Confirm password');
			document.getElementById('confirmpassword').focus();
			return false;
		}
		else if(confirmpassword !== password){
			alert('Both password are not matched!');
			document.getElementById('confirmpassword').focus();
			return false;
		}
		else{
			return true;
		}
	  }
	const RegisterSubUser = async () => {
		var check = formvalidation();
		const d = new Date();
		let time = d.getTime();
		if(check == true)
		{
			const formData = new FormData();
			formData.append('RequestId', company);
			formData.append('SubUserId', '#CT-'+time);
			formData.append('UserName', username);
			formData.append('Email', email);
			formData.append('Contact', contact);
			formData.append('Password', password);
			formData.append('DocumentFile', null);

			let url =window.UserbaseUrl+"/api/SubApplicationRequest/InsertSubApplicationRequest";

			axios({
				method: "post",
				url: url,
				data: formData,
				headers: { "Content-Type": "multipart/form-data" },
			  })
				.then(function (response) {
				  //handle success
				  if(response.data.status == 1)
				  {
					clear();
					window.location.href = '/subuserlogin';
				  }
				  else{
                   return false;
				  }

				})
				.catch(function (response) {
				  //handle error
				  console.log(response);
				});
		
			//const resp = axios.post(url, formData);
			//console.log(resp.responseValue);
			
        
	}
	else{
		return false;
	}
}

	  
	  let togglePass = async() =>{
		let field = document.getElementById('password');
		let iconsImg = document.getElementById('passicon');
		if(field.getAttribute('type') == 'password')
		{
			field.setAttribute("type","text");
		}
		else 
		{if(field.getAttribute('type') == 'text')
		{
			field.setAttribute("type","password");
		}
	}
	iconsImg.classList.toggle('feather-eye-off');
	  }
	  let togglePass1 = async() =>{
		let field = document.getElementById('confirmpassword');
		let iconsImg = document.getElementById('passicon1');
		if(field.getAttribute('type') == 'password')
		{
			field.setAttribute("type","text");
		}
		else 
		{if(field.getAttribute('type') == 'text')
		{
			field.setAttribute("type","password");
		}
	}
	iconsImg.classList.toggle('feather-eye-off');
	  }
	  let handlechage = async (e) => {
    
		let name = e.target.name;
		let value = e.target.value;
		if (name === 'company') {
			setCompanyName(value)
		  }
		if (name === 'username') {
		  setUserName(value)
		}
		if (name === 'email') {
			setUserEmail(value)
		  }
		  if (name === 'contact') {
			setUserContact(value)
		  }
		  if (name === 'password') {
			setUserPassword(value)
		  }
		  if (name === 'confirmpassword') {
			setUserConfirmPassword(value)
		  }
	  };


	  useEffect(() => {
        getChannelPartner();
	}, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>Channel Partner Sub User Register</title>
                <link rel="canonical" href="http://localhost:5000/register" />
            </Helmet>
         
    {/* <!-- /Preload --> */}
	
	<div className="main-wrapper login-body">
<div className="login-wrapper">
<div className="container">
<div className="loginbox">
<div className="login-left">
<img className="img-fluid" src="assets/img/login.jpg" alt="Logo"/>
</div>
<div className="login-right">
<div className="login-right-wrap">
<h1>Partner User Sign Up</h1>
<p className="account-subtitle">Enter details to create your account</p>

<form action="javascript:;"  method="post" className='registerform' id="registerform" enctype="multipart/form-data">
<div className="form-group">
<label>Company <span className="login-danger">*</span></label>
<select className="selectstyle select" name='company' required id="company" onChange={handlechage}>
<option value="">Select </option>
{partnerlist &&  partnerlist.map((val, ind) => { return (<option value={val.id}>{val.companyName}</option>);
})} 
</select>
</div>
<div className="form-group">
<label>Username <span className="login-danger">*</span></label>
<input className="form-control" type="text" name="username" id="username" value={username} placeholder="User name" onChange={handlechage}/>
<span className="profile-views"><i className="fas fa-user-circle"></i></span>
</div>
<div className="form-group">
<label>Email <span className="login-danger">*</span></label>
<input className="form-control" type="email" name="email" id="email" value={email} onChange={handlechage} placeholder="Email Id."/>
<span className="profile-views"><i className="fas fa-envelope"></i></span>
</div>
<div className="form-group">
<label>Contact <span className="login-danger">*</span></label>
<input className="form-control" type="number" name="contact" id="contact" value={contact} onChange={handlechage} placeholder="Contact"/>
<span className="profile-views"><i className="fas fa-phone"></i></span>
</div>
<div className="row">
	<div className="col-md-6 col-sm-12">
	<div className="form-group">
<label>Password <span className="login-danger">*</span></label>
<input className="form-control pass-input" type="password" name="password" id="password" value={password} onChange={handlechage}  placeholder='*******'/>
<span className="profile-views feather-eye toggle-password" id="passicon" onClick={()=>{togglePass();}}></span>
</div>
	</div>
	<div className="col-md-6 col-sm-12">
	<div className="form-group">
<label>Confirm password <span className="login-danger">*</span></label>
<input className="form-control pass-confirm" type="password" name="confirmpassword" id="confirmpassword" value={confirmpassword} onChange={handlechage} placeholder="*******"/>
<span className="profile-views feather-eye toggle-password" id="passicon1" onClick={()=>{togglePass1();}}></span>
</div>
	</div>
</div>


<div className=" dont-have">Already Registered? <a href="/subuserlogin">Login</a></div>
<div className="form-group mb-0">
<button className="btn btn-primary btn-block" type="submit" onClick={() => {RegisterSubUser();}}>Register</button>
</div>
</form>

{/* <div className="login-or">
<span className="or-line"></span>
<span className="span-or">or</span>
</div>

<div className="form-group mb-0">
<button className="btn btn-primary btn-block" type="submit">Sub User Login</button>
</div> */}

</div>
</div>
</div>
</div>
</div>
</div>
    </React.Fragment>
    )
}

