import React , { useEffect, useState } from 'react'; 
import AdminHeader from './AdminHeader';
import Header from './Header';
export default function ManageHeader() {  
const [userRole, setRole] = useState(); 
useEffect(() => {
    var role = window.localStorage.getItem("userRole");
    setRole(role);
  }, []);  
        return (
            <React.Fragment>  
         {userRole == 'Channel Partner' && <Header/>}  
         {userRole == 'Sub User' && <Header/>}  
         {userRole == 'Administrator' && <AdminHeader/>}     
    </React.Fragment>
    )
}