import React , { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom'; 
export default function Sidebar() {  
const [userRole, setRole] = useState(); 

useEffect(() => {
    var role = window.localStorage.getItem("userRole");
    setRole(role);
    
  }, []);  
        return (
            <React.Fragment>        
<div className="sidebar" id="sidebar chnave">
<div className="sidebar-inner slimscroll">
<div id="sidebar-menu" className="sidebar-menu">
<ul>

        <li className="submenu" >
        <Link  to="/partner-dashboard"><i className="feather-grid"></i> <span> Dashboard</span> </Link>
        </li>
        <li className="menu-title">
        <span>Leads</span>
        </li>
        {userRole === 'Channel Partner' &&
        <>
        <li className="submenu">
        <Link to="/leads"><i className="fas fa-chalkboard-teacher"></i> <span> Partner Leads</span> </Link>
        </li>
        <li className="submenu">
        <Link to="/register-deal"><i className="fas fa-chalkboard-teacher"></i> <span> Register Deals</span> </Link>
        </li>
         {/* <li className="submenu">
        <Link to="/company-meets"><i className="fas fa-chalkboard-teacher"></i> <span> Customer Meetings</span> </Link>
        </li> */}
        <li className="menu-title">
        <span>User</span>
        </li>
        <li className="submenu">
        <Link to="/products"><i className="fa fa-cart-plus"></i> <span> Products</span></Link>
        </li>
       {/* <li className="submenu">
        <Link to="/user-leads"><i className="fas fa-chalkboard-teacher"></i> <span> User Leads</span> </Link>
        </li> */}
        {/* <li className="menu-title">
        <span>User</span>
        </li>
        <li className="submenu">
        <Link to="/subuser"><i className="fas fa-chalkboard-teacher"></i> <span> User</span> </Link>
        </li>
        <li className="submenu">
        <Link to="/training"><i className="fas fa-book-reader"></i> <span> Training</span> </Link>
        </li> */}
        <li className="submenu">
        <Link to="/download-certificate"><i className="fa fa-download"></i> <span>Certificate</span></Link>
        </li>
        </>
        }
        {userRole === 'Sub User' &&
        <>
        <li className="submenu">
        <Link to="/leads"><i className="fas fa-chalkboard-teacher"></i> <span> Partner Leads</span> </Link>
        </li>
        <li className="submenu">
        <Link to="/register-deal"><i className="fas fa-chalkboard-teacher"></i> <span> Register Deals</span> </Link>
        </li>
   
        <li className="submenu">
        <Link to="/company-meets"><i className="fas fa-chalkboard-teacher"></i> <span> Company Meets</span> </Link>
        </li>
        </>
        }
       
        {/* <li className="submenu">
        <a href="#"><i className="fas fa-calendar-day"></i> <span> Events</span></a>
        </li> */}
        
        
</ul>
</div>
</div>
</div>
    </React.Fragment>
    )
}