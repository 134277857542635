import React , { useEffect, useState } from 'react'; 
import PartnerDashboard from '../src/layouts/Partnerdashboard';
import Admin from '../src/layouts/Admin';
import Admindashboard from '../src/layouts/Admindashboard';
import Downloadcertificate from '../src/layouts/Downloadcertificate';
import Login from '../src/layouts/Login';
import RegisterUser from '../src/layouts/Register'
import Leads from '../src/layouts/Leads';
import Profile from '../src/layouts/Profile';
import Products from '../src/layouts/Products';
import Addproduct from '../src/layouts/Addproduct';
import Channelpartner from './layouts/Channelpartner';
import Training from './layouts/Training';
import Addtraining from './layouts/Addtraining';
import PartnerTypeMaster from './layouts/PartnerTypeMaster';
import AddPartnerType from './layouts/AddPartnerType';
import ProductsMaterial from './layouts/ProductsMaterial';
import Addproductsmaterial from './layouts/AddproductMaterial';
import IndustryTypeMaster from './layouts/IndustryTypeMaster';
import Registerdeal from './layouts/Registerdeal';
import AddIndustryType from './layouts/AddIndustryType';
import ViewDeal from './layouts/ViewDeal';
import Forgotpassword from './layouts/Forgotpassword';
import ProductIndustry from './layouts/ProductIndustry';
import AddProductIndustry from './layouts/AddProductIndustry';
import DesignationMaster from './layouts/DesignationMaster';
import Adddesignation from './layouts/Adddesignation';
import User from './layouts/User';
import SubUser from './layouts/SubUser';
import ViewProduct from './layouts/ViewProduct';
import EditLead from './layouts/EditLead';
import Editregisterdeal from './layouts/Editregisterdeal';

import AddUser from './layouts/AddUser';
import SubUserLogin from './layouts/SubUserLogin';
import CompanyMeets from './layouts/CompanyMeets';
import UserDashboard from './layouts/UserDashboard';
import UserLeads from './layouts/UserLeads'
// import TextToSpeechConverter from './layouts/TextToSpeechConverter'
import {Button, Row, Col, Toast} from 'react-bootstrap';


import {
  BrowserRouter as Router,
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";
import AddLead from './layouts/AddLead'; 
import AddRegisterLead from './layouts/AddRegisterLead'
import Register from '../src/layouts/Partnerdashboard';
function App() {
// const [isTokenFound, setTokenFound] = useState(false);
// getToken(setTokenFound);
  //window.UserbaseUrl = "http://172.16.61.31:7098"
  // window.MasterbaseUrl = "http://172.16.61.31:7083"
  
  //window.UserbaseUrl = "http://182.156.200.178:7098"
 window.UserbaseUrl = "https://demo.medvantage.tech:7098"
  window.MasterbaseUrl = "https://api.medvantage.tech:7083"
  // const [show, setShow] = useState(false);
  return (
    <div className="App">
      <header className="App-header">
     
      <Router>
          <Routes>
            <Route path="/partner-dashboard" element={<PartnerDashboard />} />
          </Routes>
          <Routes>
            <Route path="/admin-dashboard" element={<Admindashboard />} />
          </Routes>
          <Routes>
            <Route path="/download-certificate" element={<Downloadcertificate />} />
          </Routes>
          <Routes>
            <Route path="/leads" element={<Leads />} />
          </Routes>
          <Routes>
            <Route path="/register-deal" element={<Registerdeal />} />
          </Routes>
          <Routes>
            <Route path="/add-lead" element={<AddLead />} />
          </Routes>
          <Routes>
            <Route path="/add-register-lead" element={<AddRegisterLead />} />
          </Routes>
          <Routes>
            <Route path="/view-deal" element={<ViewDeal />} />
          </Routes>
          <Routes>
            <Route path="/edit-lead" element={<EditLead />} />
          </Routes>
          <Routes>
            <Route path="/edit-registerdeal" element={<Editregisterdeal />} />
          </Routes>
          <Routes>
            <Route path="/view-product" element={<ViewProduct />} />
          </Routes>
          <Routes>
            <Route path="/products" element={<Products />} />
          </Routes>
          <Routes>
            <Route path="/add-product" element={<Addproduct />} />
          </Routes>
          <Routes>
            <Route path="/add-industry-type" element={<AddIndustryType />} />
          </Routes>
          <Routes>
            <Route path="/product-material" element={<ProductsMaterial />} />
          </Routes>
          <Routes>
            <Route path="/add-product-material" element={<Addproductsmaterial />} />
          </Routes>
          <Routes>
            <Route path="/admin" element={<Admin />} />
          </Routes>
          <Routes>
            <Route path="/" element={<Login />} />
          </Routes>
          <Routes>
            <Route path="/channel-partner" element={<Channelpartner />} />
          </Routes>
          <Routes>
            <Route path="/training" element={<Training />} />
          </Routes>
          <Routes>
            <Route path="/add-training" element={<Addtraining />} />
          </Routes>
          <Routes>
            <Route path="/partner-type" element={<PartnerTypeMaster />} />
          </Routes>
          <Routes>
            <Route path="/add-partner-type" element={<AddPartnerType />} />
          </Routes>
          <Routes>
            <Route path="/add-designation" element={<Adddesignation />} />
          </Routes>
          <Routes>
            <Route path='/designation-master' element={<DesignationMaster />} />
          </Routes>
          <Routes>
            <Route path="/industry-type-master" element={<IndustryTypeMaster />} />
          </Routes>

          <Routes>
            <Route path="/profile" element={<Profile />} />
          </Routes>
          <Routes>
            <Route path='/add-product-industry' element={<AddProductIndustry />} />
          </Routes>
          <Routes>
            <Route path="/forgot-password" element={<Forgotpassword />} />
          </Routes>
          <Routes>
            <Route path="/product-industry" element={<ProductIndustry />} />
          </Routes>
          <Routes>
            <Route path="/user" element={<User />} />
          </Routes>
          <Routes>
            <Route path="/subuser" element={<SubUser />} />
          </Routes>
          
          <Routes>
            <Route path="/user-leads" element={<UserLeads />} />
          </Routes>
          <Routes>
            <Route path="/add-user" element={<AddUser />} />
          </Routes>
          <Routes>
            <Route path="/company-meets" element={<CompanyMeets />} />
          </Routes>
          <Routes>
            <Route path="/register" element={<RegisterUser />} />
          </Routes>
          <Routes>
            <Route path="/subuserlogin" element={<SubUserLogin />} />
          </Routes>
          <Routes>
            <Route path="/user-dashboard" element={<UserDashboard />} />
          </Routes>
          {/* <Routes>
            <Route path="/text-audio" element={<TextToSpeechConverter />} />
          </Routes> */}
          
      </Router>  
      </header>
    </div>
  );
}

export default App;
