export default async function GelCompteterById(data) {
    const url = `${window.UserbaseUrl}/api/Competitor/GetCompetitor?LeadId=${data}`;
    const head = { 'accept': '*/*' }; // Fixed header value
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: head
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error fetching competitor data:", error);
      throw error; // Optionally rethrow the error after logging it
    }
  }
   