export default async function GetPartnerTypeId(data) {
    
    let url =window.UserbaseUrl+"/api/PartnerTypeMaster/GetPartnerTypeById?Id="+data;
    let head={ 'accept': ': */*'};
    //let data = loginForm;
    let responsonse = await fetch(url,{
        method:'GET',
        headers : head
    })
    .then(res=>res.json())
    .then(data)
    return responsonse
    }