export default async function UpdateLead(data) {
    
    let url =window.UserbaseUrl+"/api/DealMaster/UpdateDeal";
    let head={ 'Content-Type': 'application/json', 'accept': '*/*'};
    //let data = loginForm;
    let responsonse = await fetch(url,{
        method:'PUT',
        headers : head,
        body : JSON.stringify(data),
    })
    
    .then(res=>res.json())
    
    .then(data)
    
    return responsonse
}
