import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import Footer from "../components/Footer";
import Getproduct from "../API/Getproduct";
import RemoveProduct from "../API/RemoveProduct";
// import GetCountry from "../API/GetCountry";
import UpdateProduct from "../API/UpdateProduct";
import GetproductyId from "../API/GetproductyId";
import ManageSidebar from "../components/ManageSidebar";

export default function Dealers() {
  let [country, setProductName] = useState();
  let [state, setDescription] = useState();
  let [productnameval, setProductNameVal] = useState();
  let [producttypeval, setProductTypeVal] = useState();
  let [descriptionval, setDescriptionVal] = useState();
  let [productvalueval, setProductValueVal] = useState();
  let [productimageval, setProductImageVal] = useState();
  let [productmodelval, setProductModelVal] = useState();
  let [productid, setProductId] = useState();
  const [userRole, setRole] = useState();
  let [proimage, setProImage] = useState();
  const [file, setFile] = useState();
  let [productlist, setProduct] = useState();

  let getProduct = async () => {
    let resp = await Getproduct();
    if (resp.status === 1) {
      setProduct(resp.responseValue);
    }
  };
  let clear = async () => {
    document.getElementById("editproduct").reset();
  };
  let removeProduct = async (e) => {
    let con = window.confirm("Are you sure want to delete this Record?");
    if (con == true) {
      const sendData = {
        id: e
      };
      const resp = await RemoveProduct(sendData);
      getProduct();
      if (resp.status === 1) {
        document.getElementById(
          "deleteMsg"
        ).innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong>Product are deleted successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
        getProduct();
      }
    } else {
      return false;
    }
  };
  let handlechage = async (e) => {
    //clear();
    let name = e.target.name;
    let value = e.target.value;
    if (name === "productid") {
      setProductId(value);
    }
    if (name === "productnameval") {
      setProductNameVal(value);
    }
    if(name === "productmodelval"){
      setProductModelVal(value);
    }
    if (name === "producttypeval") {
      setProductTypeVal(value);
    }
    if (name === "productcostval") {
      setProductValueVal();
    }
    if (name === "descriptionval") {
      setDescriptionVal(value);
    }
  };

  let editProduct = async (e) => {
    const resp = await GetproductyId(e);
    if (resp.status === 1) {
      document.getElementById("productid").value = resp.responseValue[0].id;
      document.getElementById("productnameval").value =
        resp.responseValue[0].productName;
      document.getElementById("producttypeval").selectedIndex =
        resp.responseValue[0].categoryId;
      document.getElementById("descriptionval").value =
        resp.responseValue[0].description;
      setProImage(resp.responseValue[0].supportingDocument);
      document.getElementById("proImage").src =
        window.UserbaseUrl +
        "/files/" +
        resp.responseValue[0].supportingDocument;
      document.getElementById("productcostval").value =
        resp.responseValue[0].productValue;
    }
  };
  let updateProduct = async () => {
    var userId = localStorage.getItem("userId");
    // const imgFile = document.getElementById('productdocument');

    if (document.getElementById("productnameval").value === "") {
      document.getElementById("errorName").innerText = "Enter product name";
    } else if (document.getElementById("producttypeval").value === "-1") {
      document.getElementById("errorProType").innerText = "Choose Product type";
    } else if (
      document.getElementById("productcostval").value === "" &&
      Number.isInteger(document.getElementById("productcostval").value)
    ) {
      document.getElementById("errorProCost").innerText = "Enter product value";
    } else if (document.getElementById("descriptionval").value === "") {
      document.getElementsByClassName("errorDescription").innerText =
        "Enter description";
    } else {
      const formData = new FormData();
      if (document.getElementById("productdocumentval").value != "") {
        const imgFile = document.getElementById("productdocumentval");

        if (imgFile.files.length > 0) {
          formData.append(
            "attachmentFiles",
            imgFile.files[0],
            imgFile.files[0].name
          );
        }
      } else {
        formData.append("supportingDocument", proimage);
      }

      formData.append("Id", document.getElementById("productid").value);
      formData.append(
        "ProductName",
        document.getElementById("productnameval").value
      );
      formData.append(
        "categoryId",
        document.getElementById("producttypeval").value
      );
      formData.append(
        "Description",
        document.getElementById("descriptionval").value
      );
      formData.append(
        'modelNo',
        document.getElementById("productmodelval").value
      )
      formData.append(
        "ProductValue",
        parseFloat(document.getElementById("productcostval").value).toFixed(2)
      );
      formData.append("UserId", userId);
      console.log(formData);
      let url = window.UserbaseUrl + "/api/ProductMaster/UpdateUploadDoc";

      const resps = axios.post(url, formData);
      console.log(resps);

      clear();
      // if(resps.status === 1){
      document.getElementById(
        "textMessage"
      ).innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
          <strong>Success!</strong> Product are updated successfully.
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
          </button>
          </div>`;
      window.setTimeout(function () {
        setProductImageVal("");
        document.getElementById("textMessage").style.display = "none";
        window.location.href = "/products";
      }, 2000);
    }
  };
  let searchTable = async () => {
    // Declare variables
    var input, filter, table, tr, td, i, j, txtValue, found;
    input = document.getElementById("searchText");
    filter = input.value.toUpperCase();
    table = document.getElementById("tableData");
    tr = table.getElementsByTagName("tr");

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td");
      for (j = 0; j < td.length; j++) {
        if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
          found = true;
        }
      }
      if (found) {
        tr[i].style.display = "";
        found = false;
      } else {
        tr[i].style.display = "none";
      }
    }
  };
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setProductImageVal(URL.createObjectURL(e.target.files[0]));
    }
  };
  let changeFronTDate = (e) => {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(e);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  };
  useEffect(() => {
    getProduct();
    var pageView = localStorage.getItem("userName");
    var role = localStorage.getItem("userRole");
    setRole(role);
    if (pageView == null && role == null) {
      window.location.href = "/";
    }
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CriterionTech - Partner Dashboard</title>
        <link rel="canonical" href="http://localhost:5000/dealers" />
      </Helmet>
      <div className="main-wrapper" id="mobilemenu">
        <ManageHeader />
        <ManageSidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <div className="page-sub-header">
                    <h3 className="page-title">Products</h3>
                    {userRole === "Channel Partner" ? (
                                        ""
                                      ) : (
                                        <>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item active">
                        <a
                          href="/add-product"
                          className="btn btn-primary p-2 text-white"
                        >
                          <i className="fas fa-plus"></i> Add Product
                        </a>
                      </li>
                    </ul></> ) }
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="student-group-form">
<div className="row">
<div className="col-lg-3 col-md-6">
<div className="form-group">
<input type="text" className="form-control" placeholder="Search by ID ..."/>
</div>
</div>
<div className="col-lg-3 col-md-6">
<div className="form-group">
<input type="text" className="form-control" placeholder="Search by Name ..."/>
</div>
</div>
<div className="col-lg-4 col-md-6">
<div className="form-group">
<input type="text" className="form-control" placeholder="Search by Phone ..."/>
</div>
</div>
<div className="col-lg-2">
<div className="search-student-btn">
<button type="btn" className="btn btn-primary">Search</button>
</div>
</div>
</div>
</div> */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table comman-shadow">
                  <div className="card-body">
                    <div className="page-header">
                      <div className="row align-items-center">
                        <span id="deleteMsg"></span>
                        <div className="col-9">
                          <h3 className="page-title">Product Details</h3>
                        </div>

                        <div className="col-3 text-end float-end ms-auto download-grp">
                          <ul className="headerlist">
                            <li className="headerlistitem">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search ..."
                                  id="searchText"
                                  name="searchText"
                                  onChange={() => {
                                    searchTable();
                                  }}
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive fixTableHead">
                      <table
                        className="table border-0 star-student table-hover table-center mb-0 datatable table-striped"
                        id="tbl_exporttable_to_xls"
                      >
                        <thead className="student-thread">
                          <tr>
                            <th>#</th>
                            <th>Product Name</th>
                            <th>Description</th>
                            <th>Part Code</th>
                            <th className="text-end">{userRole === "Channel Partner" ? (
                                        'View') : ('Action') }</th>
                          </tr>
                        </thead>
                        <tbody id="tableData">
                          {productlist && productlist.length > 0 ? (
                            productlist.map((val, ind) => {
                              return (
                                <tr>
                                  <td>{ind + 1}</td>
                                  <td>{val.productName}</td>
                                  <td  style={{ width: "45%" }}>{val.description}</td>
                                  <td>{val.modelNo}</td>
                                  {/* <td>
                                    <a
                                      href={`${window.UserbaseUrl}/files/${val.supportingDocument}`}
                                      target="_blank"
                                    >
                                      <img
                                        src={`${window.UserbaseUrl}/files/${val.supportingDocument}`}
                                        style={{ width: "150px" }}
                                      />
                                    </a>
                                  </td> */}
                                  {/* <td>{val.productValue == 0 && "--"}</td> */}
                                 
                                  <td className="text-end">
                                    <div className="actions ">
                                      <a
                                        href={`/view-product?id=${val.id}`}
                                        title="view"
                                        className="btn btn-sm bg-danger-light"
                                      >
                                        <i className="feather-eye"></i>
                                      </a>
                                      {userRole === "Channel Partner" ? (
                                        ""
                                      ) : (
                                        <>
                                          <a
                                            href="javascript:;"
                                            data-bs-toggle="modal"
                                            onClick={() => {
                                              editProduct(val.id);
                                            }}
                                            data-bs-target="#con-close-modal"
                                            className="btn btn-sm bg-danger-light"
                                          >
                                            <i className="feather-edit"></i>
                                          </a>
                                          &nbsp;
                                          <a
                                            href="javascript:;"
                                            onClick={() => {
                                              removeProduct(val.id);
                                            }}
                                            className="btn btn-sm bg-danger-light"
                                          >
                                            <i className="feather-trash"></i>
                                          </a>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colspan="12" style={{ textAlign: "center" }}>
                                No results found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="con-close-modal"
            className="modal fade"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
            style={{ display: "none" }}
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Product</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body p-4">
                  <div className="card comman-shadow">
                    <div className="card-body">
                      <div id="textMessage"></div>
                      <div id="errorMessage"></div>
                      <form
                        method="post"
                        action="javascript:;"
                        name="editproduct"
                        id="editproduct"
                      >
                        <input
                          type="hidden"
                          name="productid"
                          id="productid"
                          onChange={handlechage}
                        />
                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <div className="form-group local-forms">
                              <label>
                                Product Name{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                required
                                placeholder="Enter product name"
                                name="productnameval"
                                id="productnameval"
                                onChange={handlechage}
                              />
                              <span id="errorName"></span>
                            </div>
                            <div className="form-group local-forms">
                              <label>
                                Product Type{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <select
                                className="selectstyle select"
                                name="producttypeval"
                                required
                                id="producttypeval"
                                onChange={handlechage}
                              >
                                <option value={-1}>Select product type </option>
                                <option value="1">Software</option>
                                <option value="2">Hardware</option>
                              </select>
                              <span
                                id="errorProType"
                                className="errormsg"
                              ></span>
                            </div>
                            <div className="form-group local-forms">
                              <label>
                                Product cost{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                required
                                placeholder="product cost"
                                name="productcostval"
                                id="productcostval"
                                onChange={handlechage}
                              />
                              <span
                                id="errorProCost"
                                className="errormsg"
                              ></span>
                            </div>

                            <div className="form-group local-forms">
                              <label>
                                Product Image{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                accept="image/jpeg, image/png, image/jpg"
                                required
                                placeholder="product document"
                                name="productdocumentval"
                                id="productdocumentval"
                                onChange={handleFileChange}
                              />
                            </div>
                            <span id="errorDoc" className="errormsg"></span>
                            <div className="imgsec">
                              <img
                                id="proImage"
                                src={productimageval}
                                style={{ width: "150px" }}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                          <div className="form-group local-forms">
                              <label>
                                Product Model{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                required
                                placeholder="Enter product model"
                                name="productmodelval"
                                id="productmodelval"
                                onChange={handlechage}
                              />
                              <span id="errorName"></span>
                            </div>
                            <div className="form-group local-forms">
                              <label>
                                Description{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                type="text"
                                rows="50"
                                style={{ height: "214px" }}
                                required
                                placeholder="Enter product description"
                                name="descriptionval"
                                id="descriptionval"
                                onChange={handlechage}
                              ></textarea>
                              <span id="errorDescription"></span>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary waves-effect"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-info waves-effect waves-light"
                    name="updateProduct"
                    id="updateProduct"
                    onClick={() => {
                      updateProduct();
                    }}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
}
