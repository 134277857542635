export default async function GetState(data) {
    
  let url =window.MasterbaseUrl+"/api/StateMaster/GetStateMasterByCountryId?id="+data;
  let head={ 'Content-Type': 'application/json-patch+json'};
  //let data = loginForm;
  let responsonse = await fetch(url,{
      method:'GET',
      headers : head
  })
  .then(res=>res.json())
  .then(data)
  return responsonse
  }
