
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import axios from 'axios';
import ManageHeader from "../components/ManageHeader";
import GetPartner from '../API/GetPartner';
import ManageSidebar from '../components/ManageSidebar';

//import Footer from "../components/Footer";

export default function AddUser(){  
    let [contectperson, setContectperson] = useState();
    let [email, setEmail] = useState();
    let [mobile, setMobile] = useState();
    let [password, setPassword] = useState();
    let [companyName, setCompanyname] = useState();
    let [profileimage, setProfileImage] = useState();
    let [companylist,getPartner] = useState();
    const [file, setFile] = useState();
  

    let clear = async () =>{
      document.getElementById("addUser").reset();
      setContectperson('');

  }
  let getChannelPartner = async () => {
    let resp = await GetPartner();
    if(resp.status === 1)
    {
        getPartner(resp.responseValue);  
    }
  };

   
  let handlechage = async (e) => {
    //clear();
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'contectperson') {
   
      setContectperson(value)
      
  }
  if (name === 'email') {
    setEmail(value)
  }
  if (name === 'mobile') {
    setMobile(value)
    checkmobileValidation(value)
   }
   if(name === 'password'){
    setPassword(value)
   }
   
    
    if (name === 'company') {
      setCompanyname(value)
     }

  };
  const checkmobileValidation = (e) =>{
    if(e.length < 10 || e.length >12){
      document.getElementById("errorMobile").innerText = 'Enter valid phone no';
      return false;
    }
    else{
      document.getElementById("errorMobile").innerText = '';
      return false;
    }
  }
      
let submitUser = async() =>{
  var userId = localStorage.getItem("userId");
  var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  if(document.getElementById('contectperson').value === ""){
    document.getElementById("errorName").innerText = 'Enter name';
    return false;
  }
  else if(regex.test(document.getElementById('email').value) === false){
    document.getElementById("errorEmail").innerText = 'Enter email';
    return false;
  }
  else if(document.getElementById('password').value === ""){
    document.getElementById("errorPassword").innerText = 'Enter password';
    return false;
  }
  else if(document.getElementById('mobile').value === ""){
    document.getElementById("errorMobile").innerText = 'Enter mobile no';
    return false;
  }
  else if(document.getElementById('mobile').length < 10){
    document.getElementById("errorMobile").innerText = 'Enter valid phone no';
    return false;
  }
  else if(document.getElementById('company').value === "-1"){
    document.getElementById("errorCompanyName").innerText = 'Select company';
    return false;
  }
  else{
    const imgFile = document.getElementById('profile');
    const formData = new FormData();
    formData.append(
      "attachmentFiles",
      imgFile.files[0],
      imgFile.files[0].name
  );
  //alert(document.getElementById('company').value);
  const d = new Date();
	let time = d.getTime();
  formData.append('email', email);
  formData.append('UserName', contectperson);
  formData.append('SubUserId', '#CT-'+time);
  formData.append('Contact', mobile);
  formData.append('Password',password);
  formData.append('RequestId',document.getElementById('company').value);
  formData.append('ApprovalStatus',1);
  formData.append('UserId',userId);
  let url =window.UserbaseUrl+"/api/SubApplicationRequest/InsertSubApplicationRequest";

    axios({
      method: "post",
      url: url,
      data: formData,
      headers: { "accept" : "text/plain", "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        //handle success
        if(response.data.responseValue[0].status == 1)
        {
        clear();
        document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
        <strong>Success!</strong> User are added successfully.
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
        </button>
        </div>`;
        window.setTimeout(function() {
          setProfileImage('');
          document.getElementById("textMessage").style.display='none';
          window.location.href = '/add-user';
          }, 2000);
        }
        else{
                return false;
        }

      })
    .catch(function (response) {
      //handle error
      console.log(response);
    });
  
  }
}

 
      const handleFileChange = (e) => {
        if (e.target.files) {
          setFile(e.target.files[0]);
          setProfileImage(URL.createObjectURL(e.target.files[0]));
      }
      };
      let togglePass = async() =>{
        let field = document.getElementById('password');
        let iconsImg = document.getElementById('passicon');
        if(field.getAttribute('type') == 'password')
        {
          field.setAttribute("type","text");
        }
        else 
        {if(field.getAttribute('type') == 'text')
        {
          field.setAttribute("type","password");
        }
      }
      iconsImg.classList.toggle('feather-eye-off');
        }
      useEffect(() => {
        getChannelPartner();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Add User</title>
                <link rel="canonical" href="http://localhost:5000/add-user" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
              
<div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row align-items-center">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Add User</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href='/user' >All User</a></li>
</ul>
</div>
</div>
</div>
</div>

<div className="row">
<div className="col-sm-12">
<div className="card comman-shadow">
<div className="card-body">
  <div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='addUser' id='addUser' enctype="multipart/form-data">
<div className="row">
<div className="col-12">
<h5 className="form-title student-info">User Information <span><a href="javascript:;"><i className="feather-more-vertical"></i></a></span></h5>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Person Name <span className="login-danger">*</span></label>
<input className="form-control" type="text"  placeholder="Enter name" name='contectperson' id="contectperson" onChange={handlechage}/>
<span id="errorName" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Email <span className="login-danger">*</span></label>
<input className="form-control" type="email"  placeholder="Enter email" name='email' id='email' onChange={handlechage} />
<span id="errorEmail" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Password <span className="login-danger">*</span></label>
<input className="form-control pass-input" type="password" name='password' id="password" value={password} onChange={handlechage} placeholder='*******'/>
<span className="profile-views feather-eye toggle-password" id="passicon" onClick={()=>{togglePass();}}></span>
<span id="errorPassword" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Mobile No. <span className="login-danger">*</span></label>
<input className="form-control" type="number" size="15" placeholder="Enter mobile" name='mobile' id='mobile' onChange={handlechage}/>
<span id="errorMobile" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Company <span className="login-danger">*</span></label>
<select className="form-control select" name='company' id="company" onChange={handlechage}>
<option value={-1}>Select company</option>
<option value={localStorage.getItem("companyId")} selected>{localStorage.getItem("companyName")}</option>
</select>
<span id="errorCompanyName" className='errormsg'></span>

</div>
</div>

<div className="col-12 col-sm-4">
<div className="form-group local-forms ">
<label>Profile Image<span className="login-danger">*</span></label>
<input className="form-control" accept="image/jpeg, image/png, image/jpg" type="file" name='profile' id='profile' onChange={handleFileChange}/>
<span id="errorDoc" className='errormsg'></span>
{/* <div className='imgsec'>
<img src={profileimage} style={{"width":"150px"}} />
</div> */}
</div>
</div>

<div className="col-12">
<div className="student-submit">
<button type="submit" name='saveDeal' id='saveDeal' onClick={()=>{submitUser();}} className="btn btn-primary">Submit</button>
</div>
</div>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
            </div>
    </React.Fragment>
    )
}

