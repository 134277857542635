
import React , { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter
  } from "react-router-dom"
import ManageHeader from "../components/ManageHeader";
import GelCompteterById from "../API/GelCompteterById";
import GetdealdyId from '../API/GetdealdyId';
import Getdealproduct from '../API/Getdealproduct';
import GetNextDealByid from '../API/GetNextDealByid';
import ManageSidebar from '../components/ManageSidebar';


export default function ViewDeal(){  
        
const [companyName, setCompanyName] = useState(); 
const  [customerId, setCustomerId] = useState();
const[dealdetails, setDealDetails] = useState();
const[productdeatils, setDealProductDetails] = useState();
const[nextdealdeatils, setNextDealDetails] = useState();
const[competerlist, setCompteter] = useState();
 const getLeadDetails = async()=>{
    const queryParameters = new URLSearchParams(window.location.search)
    const Id = queryParameters.get("id")
   
          const rescom = await GelCompteterById(Id);
          if (rescom.status === 1) {
            setCompteter(JSON.parse(rescom.responseValue[0].competitorDetails)); 
          } 
        
        const resp = await GetdealdyId(Id);

        if(resp.status === 1)
        {
            setDealDetails(resp.responseValue);  
        }
        const resppro = await Getdealproduct(Id);
      
        if(resppro.status === 1)
        {
            setDealProductDetails(resppro.responseValue);  
        }
        const respnext = await GetNextDealByid(Id);
       // console.log(respnext)
        if(respnext.status === 1)
        {
            setNextDealDetails(respnext.responseValue);  
        }

        
        
 } 
 // alert(leadid);
//const [monthname, getMonthName] = useState(); 


    let printDiv = async(e)=>{
        var printContents = document.getElementById(e).innerHTML;
        //alert(printContents);
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }
//     let now = new Date().toLocaleDateString('en-us', { month:"long"});
// console.log(now)
    function getMonthName(monthNumber) {
        const date = new Date()
        date.setMonth(monthNumber) // starts with 0, so 0 is January
        return date.toLocaleString('en-EN', { month: "long" })
      }
    useEffect(() => {
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        var companyName = localStorage.getItem("companyName");
        var customerId = localStorage.getItem("customerId");
        setCustomerId(customerId);
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
        else {
        setCompanyName(companyName);
    }
    getMonthName(1);
    getLeadDetails();
      }, []); 
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Lead Details</title>
                <link rel="canonical" href="http://localhost:5000/download-certificate" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar/>
                <div className="page-wrapper">
<div className="content container-fluid">
<div className="row justify-content-center">
<div className="col-xl-10">
<div className="card invoice-info-card">
<div className="card-body">
{
dealdetails && dealdetails.length > 0 ?
dealdetails.map((val, ind) => { return (
    <>
<div className="invoice-item invoice-item-one">
<div className="row">
<div className="col-md-6">

<div className="invoice-head">
<h2>{val.companyName}</h2>
<div className="invoice-info">
<h6 className="invoice-name">{val.contactPerson}</h6>
<p className="invoice-details">
{val.emailID}<br/>
{val.mobile}<br/>
{val.companyAddress} {val.companyCityName}, <br />
{val.companyStateName} - {val.companyZipCode}, {val.companyCountryName}
</p>
</div>
</div>
</div>
</div>
</div>

<div className="invoice-item invoice-item-two">
<div className="row">
<div className="col-md-8">
<div className="invoice-info">
<strong className="customer-text-one">Customer Details</strong>
</div>
</div>
<div className="col-md-4">
<div className="invoice-info">
<strong className="customer-text-one">Other Details</strong>
</div>
</div>

<div className="col-md-4">
<div className="invoice-info">
<p className="invoice-details invoice-details-two">
{val.contactPerson}<br />
{val.emailID}<br/>
{val.mobile}<br/>
{val.companyAddress} {val.companyCityName}, {val.companyStateName} - {val.companyZipCode}, {val.companyCountryName}
</p>
</div>
</div>
<div className="col-md-4">
<div className="invoice-info">
<p className="invoice-details invoice-details-two">
<strong>Industry Type :</strong>  {val.industryType}<br/>
<strong>Website :</strong> {val.website}<br/>
<strong>Deal Status :</strong> {val.dealStatus === 1 && 'Active'} {val.dealStatus === 0 && 'Active'} 
{val.dealStatus === 2 && 'Under Review'} {val.dealStatus === 3 && 'Rejected'} {val.dealStatus === 4 && 'Approved'}
{val.dealStatus === 2 && <><strong>Reason :</strong>  {val.reason}</> } {val.dealStatus === 3 && <><strong>Reason :</strong> {val.reason} </> } <br/>
<strong>Estimated Budget :</strong> {val.estimatebudget}/- <span>INR</span><br />
{val.dealStatus === 1 ? <><strong>Expected Closure Date :</strong> {val.expectedClosuerDate}</> : <></> } 
</p>
</div>
</div>
<div className="col-md-4">
<div className="invoice-info">
<p className="invoice-details invoice-details-two"><strong>Primary Contact Name :</strong>  {val.primaryContact}</p>
<p className="invoice-details invoice-details-two">
<strong>Designation :</strong> {val.techinicalContactName} <br/>
<strong>Phone No.:</strong> {val.techinicalContactPhone} <br/>
<strong>Email Id :</strong> {val.techinicalContactEmail} <br/>
<strong>Competitor :</strong> 
{
competerlist && competerlist.length > 0 ?
competerlist.map((val,ind) => { return ( 
    <>
{val.competitorName}, 
    </>
);
})
: <span>No results found</span>
}
<br />
{val.competitorName != '' ? <>
    <strong>Other Competitor : </strong> 

{val.competitorName}</> : <></>}
<br />
<strong>Next Step : </strong>  {
nextdealdeatils && nextdealdeatils.length > 0 ?
nextdealdeatils.map((val,ind) => { return ( 
    <>

{val.stepId == 1 && 'Demo Product, '}
{val.stepId == 2 && 'Meeting,'}
{val.stepId == 3 && 'Phone Call,'}
{val.stepId == 4 && 'Webcast'}
    </>

);
})
: <span>No results found</span>
}

</p>
</div>
</div>
</div>
<div className='col-md-12'>
<strong>Remark :</strong> {val.remark != '' ? val.remark : '---'}<br/>
</div>
</div>
</>
);
})
: <div className="col-md-6" style={{textAlign :"center"}}>No results found</div>
}

<div className="invoice-issues-box">
<div className="row">
<div className="col-lg-4 col-md-4">
<div className="invoice-issues-date">
<p>Product Details</p>
</div>
</div>

</div>
</div>


<div className="invoice-item invoice-table-wrap">
<div className="row">
<div className="col-md-12">
<div className="table-responsive">
<table className="invoice-table table table-center mb-0">
<thead>
<tr>
<th>#</th>
<th>Name</th>
</tr>
</thead>
<tbody>

{
productdeatils && productdeatils.length > 0 ?
productdeatils.map((val, ind) => { return (
<tr>
<td>{ind+1}</td>
<td>{val.productName}</td>
</tr>
);
})
: <tr><td colspan="4" style={{textAlign :"center"}}>No results found</td></tr>
}
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>


    </React.Fragment>
    )
}

