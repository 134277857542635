import React, {useState} from "react";
import {Helmet} from "react-helmet";
import GatPassword from '../API/GatPassword';

export default function Forgotpassword(){  
	const [emailid, setEmailId] = useState("");

	let clear = () => {
        setEmailId('')
	
	};
	const getLoginCredential = async () => {
		if(emailid === ''){
			alert('Enter register email id')
			return false;
		}
		
		else {
		// const sendData = {
		// 	"email": emailid
		// 	}
		
		  const resp = await GatPassword(emailid);
        if(resp.status === 1)
		 { 
			document.getElementById("successMsg").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
			<strong>Hi! </strong> Your login credentials sent on register email account. Please check your email inbox.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
		       clear();
			//    window.location.href = '/login';
		}
		else{
			if(resp.status === 0)
		 { 
			document.getElementById("errorMsg").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
			<strong>Sorry! </strong> You don't have an account.<br/>Please <a href="https://partners.criteriontechnologies.com/partnerregistration/">Register Now</a>.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
			
		}
	}
	}
	
	}
	 
	  let handlechage = async (e) => {
    
		let name = e.target.name;
		let value = e.target.value;
		if (name === 'emailid') {
		  setEmailId(value)
		}
		
	  };
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>Criterion Tech - Channel Partner Forgot Password</title>
                <link rel="canonical" href="http://localhost:5000/forgot-password" />
            </Helmet>
         
    {/* <!-- /Preload --> */}
	
	<div className="main-wrapper login-body">
<div className="login-wrapper">
<div className="container">
<div className="loginbox">
<div className="login-left">
<img className="img-fluid" src="assets/img/login.jpg" alt="Logo"/>
</div>
<div className="login-right">
<div className="login-right-wrap">
<h1>Welcome to Criterion Tech</h1>
<p class="account-subtitle">Already have an account? <a href="https://partners.criteriontechnologies.com/">Sign In</a></p>
<h2>forgot password</h2>
<div id="errorMsg"></div>
<div id="successMsg"></div>
<form action="javascript:;"  method="post" className='loginform' id="loginform">
<div className="form-group">
<label>Email Id <span className="login-danger">*</span></label>
<input className="form-control" required type="email" id='emailid' name="emailid" value={emailid} onChange={handlechage} placeholder="Enter registered email id*"/>
<span className="profile-views"><i className="fas fa-user-circle"></i></span>
</div>

<div className="form-group">
<button className="btn btn-primary btn-block" type="submit" onClick={() => {getLoginCredential();}}>Submit</button>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
    </React.Fragment>
    )
}

