export default async function GetCity(data) {
 let url =window.MasterbaseUrl+"/api/CityMaster/GetCityMasterByStateId?id="+data;
  let head={ 'Content-Type': 'application/json-patch+json'};
  //let data = loginForm;
  let responsonse = await fetch(url,{
      method:'GET',
      headers : head
  })
  .then(res=>res.json())
  .then(data)
  return responsonse
  }
