
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import Footer from "../components/Footer";
// import Getdeal from "../API/Getdeal";

import GetCountry from "../API/GetCountry";
import GetState from "../API/GetState";
import GetCity from "../API/GetCity";
import ManageSidebar from '../components/ManageSidebar';
import GetPartnerTypeId from '../API/GetPartnerTypeId';
// import UpdateLead from '../API/UpdateLead';
import GetPartnerType from '../API/GetPartnerType';
import UpdatePartnerType from '../API/UpdatePartnerType';
import RemovePartnerType from '../API/RemovePartnerType';

export default function PartnerTypeMaster(){  
  let [partnertypename,setPartnerTypeName] = useState();
    
  let [description, setDescription] = useState();

    let [partnerlist, getPartnerList] = useState();
    

    // let [dealbyid,DealListById] = useState();

    let getPartnerType = async () => {
        let resp = await GetPartnerType();
        if(resp.status === 1)
        {
            getPartnerList(resp.responseValue);  
        }
      };

      
      let removePartnerType = async (e) =>{
        let con = window.confirm('Are you sure want to delete this Record?');
        if(con == true)
        {
          const sendData = {
            "id": e,
               }
               const resp = await RemovePartnerType(sendData);
               
               if(resp.status === 1){
                   document.getElementById("deleteMsg").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong>Record are deleted successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
               getPartnerType();
               window.setTimeout(function() {

                document.getElementById("deleteMsg").style.display='none';
                //window.location.href = '/partner-type';
                }, 2000);
               }
              }
              else{
                return false;
              }
      }
      let editPartner = async(e) =>{
        const resp = await GetPartnerTypeId(e);
        if(resp.status === 1)
         {
          document.getElementById('partnerid').value = resp.responseValue[0].id;
          document.getElementById('partnertypename').value = resp.responseValue[0].partnerType;
          document.getElementById('description').value = resp.responseValue[0].remark;
         }
 
     }
     let clear = async () =>{
      document.getElementById("updatePartnerType").reset();

  }
  let updatePartnetType = async()=>{
    var userId = localStorage.getItem("userId");
  if(document.getElementById('partnertypename').value === ""){
    document.getElementById("errorName").innerText = 'Enter partner type name';
  }
 else if(document.getElementById('description').value === ""){
    document.getElementsByClassName("errorDescription").innerText = 'Enter description';
  }
  
  else{
  const sendData = {
    'id': document.getElementById('partnerid').value,
    "partnerType": document.getElementById('partnertypename').value,
    "remark": document.getElementById('description').value,
    "userID" : userId
    }
    
    const resp = await UpdatePartnerType(sendData);
    console.log(resp);
    clear();
    if(resp.status === 1){
      document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
		  <strong>Success!</strong> Partner Type are updated successfully.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
      getPartnerType();
      window.setTimeout(function() {
       // window.location ='/partner-type';
        document.getElementById("textMessage").classList.toggle("hide");
        }, 2000);
    }
    else{
      document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
		  <strong>Error!</strong> Partner Type are not updated.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
    }
  }
  }
   
    
      let handlechage = async (e) => {
        //clear();
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'partnertypename') {
            setPartnerTypeName(value)
        }
        if (name === 'description') {
          setDescription(value)
        }
      
        };
  
    
       let changeFronTDate = (e) =>{
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(e)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
        }
      useEffect(() => {
        getPartnerType();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Partner Type List</title>
                <link rel="canonical" href="http://localhost:5000/training" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
                <div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Partner Type</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href="/add-partner-type" className="btn btn-primary p-2 text-white"><i className="fas fa-plus"></i> Add Partner Type</a></li>
</ul>
</div>
</div>
</div>
</div>

<div className="row">
<div className="col-sm-12">
<div className="card card-table comman-shadow">
<div className="card-body">

<div className="page-header">
<div className="row align-items-center">

<div className="col">
    
<h3 className="page-title">My Partner Type</h3>
</div>
<div className="col-auto text-end float-end ms-auto download-grp">

{/* <a href="#" className="btn btn-outline-primary me-2"><i className="fas fa-download"></i> Download</a> */}
{/* <a href="/add-partner-type" className="btn btn-primary"><i className="fas fa-plus"></i></a> */}
</div>
</div>
</div>

<div className="table-responsive">
<span id="deleteMsg"></span>
<table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
<thead className="student-thread">
<tr>
<th>
#
</th>
<th>Partner Type</th>
<th>Description</th>
<th className="text-end">Action</th>
</tr>
</thead>
<tbody>
{
partnerlist && partnerlist.length > 0 ?
partnerlist.map((val, ind) => { return (
<tr>
<td>
{ind+1}
</td>
<td>{val.partnerType}</td>   
<td>{val.remark}</td>  
<td className="text-end">
<div className="actions ">
{/* <a href="javascript:;" className="btn btn-sm bg-success-light me-2 ">
<i className="feather-eye"></i>
</a> */}

<a href="javascript:;" id="editpartner" title='Edit' onClick={() => {editPartner(val.id);}} data-bs-toggle="modal" data-bs-target="#con-close-modal" className="btn btn-sm bg-danger-light">
<i id="partnerstatusicon" class="feather-edit"></i>
</a>&nbsp;
<a href="javascript:;" title='Remove' onClick={() => {removePartnerType(val.id);}} className="btn btn-sm bg-danger-light">
<i className="feather-trash"></i>
</a>
</div>
</td>
</tr>
);
})
: <tr><td colspan="12" style={{textAlign :"center"}}>No results found</td></tr>
}
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div id="con-close-modal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display:"none", }}>
<div className="modal-dialog modal-lg">
<div className="modal-content">
<div className="modal-header">
<h4 className="modal-title">Update Partner Type</h4>
<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div className="modal-body">
<div className="card comman-shadow">
<div className="card-body">
<div id='textMessage'></div><div id='errorMessage'></div>
  <form method='post' action='javascript:;' name='updatePartnerType'  id='updatePartnerType' enctype="multipart/form-data"> 
<div className="row">
<div className="col-12 col-sm-12">
<h5 className="form-title student-info">Partner Type Information <span><a href="javascript:;"><i className="feather-more-vertical"></i></a></span></h5>
</div>
<input type='hidden' name='partnerid' id='partnerid' onChange={handlechage}/>
<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Partner Type Name <span className="login-danger">*</span></label>
<input className="form-control" style={{"text-transform":"capitalize"}} type="text" required placeholder="Enter Partner Type Name" name='partnertypename' id="partnertypename" onChange={handlechage}/>
<span id="errorName" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Description <span className="login-danger">*</span></label>
<textarea className="form-control" required placeholder="Enter description" style={{"height":"207px"}} name='description' id='description' onChange={handlechage} ></textarea>
<span id="errorDescription" className='errormsg'></span>
</div>
</div>

</div>
</form>
</div>
</div>
</div>
<div className="modal-footer">
<button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal">Close</button>
<button type="submit" className="btn btn-info waves-effect waves-light" name='updateProduct' id='updateProduct' onClick={()=>{updatePartnetType();}}>Save changes</button>
</div>
</div>
</div>
</div>
< Footer />

</div>
            </div>
    </React.Fragment>
    )
}

