export default async function GatPassword(email) {
    let url =window.UserbaseUrl+"/api/ApplicationRequest/SendPasswordOnGmail?email="+email;
    let head={ 'Content-Type': 'application/json-patch+json'};
    let response = await fetch(url,{
      method: "POST",
      headers: head
    }).then((res) => res.json())
    .then(email)
    return response;
  }
