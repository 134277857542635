export default async function RemoveProductMaterial(data) {
    
    let url =window.UserbaseUrl+"/api/ProductMaterial/DeleteProductMaterial";
    let head={ 'Content-Type': 'application/json'};
    //let data = loginForm;
    let responsonse = await fetch(url,{
        method:'DELETE',
        headers : head,
        body : JSON.stringify(data),
    })
    
    .then(res=>res.json())
    
    .then(data)
    
    return responsonse
}