
import React , { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter
  } from "react-router-dom"
import GetproductyId from "../API/GetproductyId";
import ManageHeader from "../components/ManageHeader";
import GetAllproductMeterialbyId from '../API/GetAllproductMeterialbyId';
import ManageSidebar from '../components/ManageSidebar';


export default function ViewProduct(){  
        
const[productdetails, setProductDetails] = useState();
const[productmeterialdeatils, setProductMeterialDetails] = useState();
const[baseurl, setBaseUrl] = useState();

 const getLeadDetails = async()=>{
    
    setBaseUrl(window.UserbaseUrl);
    const queryParameters = new URLSearchParams(window.location.search)
    const Id = queryParameters.get("id")
        const resp = await GetproductyId(Id);

        if(resp.status === 1)
        {
            setProductDetails(resp.responseValue);  
        }
        const resppro = await GetAllproductMeterialbyId(Id);
      
        if(resppro.status === 1)
        {
            console.log(JSON.parse(resppro.responseValue[0].productDetails));
            var metlist  = JSON.parse(resppro.responseValue[0].productDetails);
            setProductMeterialDetails(metlist);  
        }
 } 

    let printDiv = async(e)=>{
        var printContents = document.getElementById(e).innerHTML;
        //alert(printContents);
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }
    useEffect(() => {
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
    
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
       
    getLeadDetails();
      }, []); 
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Lead Details</title>
                <link rel="canonical" href="http://localhost:5000/download-certificate" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar/>
                <div className="page-wrapper">
<div className="content container-fluid">
<div className="row justify-content-center">
<div className="col-xl-10">
<div className="card invoice-info-card">
<div className="card-body">
{
productdetails && productdetails.length > 0 ?
productdetails.map((val, ind) => { return (
    <>
<div className="invoice-item invoice-item-one">
<div className="row">
<div className="col-md-6">

<div className="invoice-head productImg">
    {/* <img src={`${baseurl}/files/${val.supportingDocument}`} alt={val.productName} /> */}
<h2>{val.productName}</h2>
</div>
</div>
<div className="col-md-6">
<div className="invoice-info">
<p className="invoice-details text-justify" style={{'text-align':'justify'}}>
{val.description}<br/>
</p>
</div>
</div>
</div>
</div>

</>
);
})
: <div className="col-md-6" style={{textAlign :"center"}}>No results found</div>
}

<div className="invoice-issues-box">
<div className="row">
<div className="col-lg-4 col-md-4">
<div className="invoice-issues-date">
<p>Product Meterial Details</p>
</div>
</div>

</div>
</div>


<div className="invoice-item invoice-table-wrap">
<div className="row">
<div className="col-md-12">
<div className="table-responsive">
<table className="invoice-table table table-center mb-0">
<thead>
<tr>
<th>#</th>
<th>Name</th>
<th>Document Type</th>
<th>Part Code</th>
<th className="text-end">Document</th>
</tr>
</thead>
<tbody>

{
productmeterialdeatils && productmeterialdeatils.length > 0 ?
productmeterialdeatils.map((val, ind) => { return (
<tr>
<td>{ind+1}</td>
<td>{val.productName}</td>
<td>{val.documentType === 1 ? 'Audio' : '' || val.documentType === 2 ? 'Video' : '' || val.documentType === 3 ? 'PPT' : '' || val.documentType === 4 ? 'PDF' : ''}</td>
<td>{val.modelNo}</td>
<td className="text-end">
    <a href={`${baseurl}/files/${val.productfile}`} alt={val.productName} target='_blank'><i className="feather-eye"></i></a></td>
</tr>
);
})
: <tr><td colspan="4" style={{textAlign :"center"}}>No results found</td></tr>
}
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>


    </React.Fragment>
    )
}

