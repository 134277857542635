
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import GetCountry from "../API/GetCountry";
import GetState from "../API/GetState";
import GetCity from "../API/GetCity";
import Getproduct from "../API/Getproduct";
import SaveDealer from "../API/SaveDealer";
import SavedDeal from "../API/SavedDeal";
import SaveDealProduct from "../API/SaveDealProduct";
import ManageSidebar from '../components/ManageSidebar';
import SaveNextStep from '../API/SaveNextStep';
import GetIndustryType from '../API/GetIndustryType';
import GetFollowStep from '../API/GetFollowStep';
import SaveCompteter from '../API/SaveCompteter';

//import Footer from "../components/Footer";
import Select from 'react-select';
export default function AddLead(){  

    let [country,CountryList] = useState();
    let [state, StateList] = useState();
    let [city, CityList] = useState();
    let [contectperson, setContectperson] = useState();
    let [email, setEmail] = useState();
    let [mobile, setMobile] = useState();
    let [companyName, setCompanyname] = useState();
    // let [companycontact,setCompanycontact] = useState();
    let [countryname, setCountry] = useState();
    let [statename, setState] = useState();
    let [cityname, setCity] = useState();
    let [companyaddress, setCompanyaddress] = useState();
    let [postalcode, setPostalcode] = useState();
    let [website, setWebsite] = useState();

    let [noofemployee, setPerson] = useState();
    let [noofexcustomer, setNoofexcustomer] = useState();
    let [industry, setIndustry] = useState();
    let [productlist, setProduct] = useState();
    const [checkboxes, setCheckboxes] = useState([]);
    // other details
    let [primarycontact, setPrimaryContact] = useState();
    let [techcontactname, setTechContactName] = useState();
    let [techContactEmail, setTechContactEmail] = useState();
    let [techContactNo, setTechContactNo] = useState();
    let [competitor, setCompetitor] = useState([]);
    let [otherCompetitor, setotherCompetitor] = useState();
    let [estimatebudget, setEstimateBudget] = useState();
    let [expclosuredate, setClosureDate] = useState();
    let [remark, setRemark] = useState();
    let [dealid,setDealId] = useState();
    let [industrylist, getIndustryList] = useState();
    let [tractlist, setTractStep] = useState();
    

    let [selectedOption,setSelectedOption] = useState([]);

    let clear = async () =>{
      document.getElementById("addDeal").reset();
      setContectperson('');
      // setCompanycontact('');
      setCountry('');
      setState('');
      setCity('');
      setCompanyaddress('');
      setPostalcode('');
      setWebsite('');
      setPerson('');
      setNoofexcustomer('');
      setIndustry('');
  }

  let getProduct = async () => {
    let resp = await Getproduct();
    if(resp.status === 1)
    {
      
        setProduct(resp.responseValue); 
        
    }
  };

  let getTrackStep = async ()=> {
    let resp = await GetFollowStep();
    if(resp.status === 1){
      setTractStep(resp.responseValue); 
    }
  }

 
    
    let getCountry= async () => {
        let resp = await GetCountry();
        if(resp.status === 1)
        {
            CountryList(resp.responseValue);
            getState();
        }
      };

      

      // let options = country.map(val => ({
      //   label: val.countryName,
      //   value: val.id
      // }))
      const options = [
        { value: '1', label: 'Siemens' },
        { value: '2', label: 'Philips' },
        { value: '3', label: 'Dräger' },
        { value: '4', label: 'G Health Care' },
        { value: '5', label: 'Other' },
      ];
      let getState = async(e)=>{
        
        let resp = await GetState(e);
        if(resp.status === 1)
        {
            StateList(resp.responseValue);
        }
      }
      let getCity = async(e)=>{
        
        let resp = await GetCity(e);
        if(resp.status === 1)
        {
            CityList(resp.responseValue);
        }
      }
      let getIndustryType = async () => {
        let resp = await GetIndustryType();
        if(resp.status === 1)
        {
            getIndustryList(resp.responseValue);  
        }
      };
      
      let submitDeal = async() =>{
        var userId = localStorage.getItem("userId");
        var customerId = localStorage.getItem("customerId");
        companyValidation();
        let sendData ;
        if(companyValidation() === false){
          return false;
        }
        else{
        sendData = {
          "emailID": email,
          "contactPerson": contectperson,
          "mobile" : mobile,
          "companyName" : companyName,
          "companyCountryID" : countryname,
          "companyStateID" : statename,
          "companyCityID" : cityname,
          "companyZipCode" : postalcode,
          "companyAddress" : companyaddress,
          // "companyContact" : "",
          "website" : website,
          "noOfEmployee" : noofemployee,
          "userId" : userId,
          "partnerID" : customerId,
          "dealStatus" : 0,
          "isExixtingCustomer" : noofexcustomer,
          "industryTypeID" : industry
          }
        console.log(sendData);
        }
         const resp = await SaveDealer(sendData);
      
         console.log(resp.responseValue);
          // clear();
          var dataVal = resp.responseValue;
          //if(dataVal.sucessMessage.status === 1){
            
            if(document.getElementById('dealid').value === ''){
              setDealId(dataVal.data[0].id);
            }
            //productValidation();
          //}
          
        
      }

function companyValidation(){
  var tabid = document.getElementById('progress-seller-details');
  var tabid2 = document.getElementById('progress-company-document');
  var tabid3 = document.getElementById('progress-bank-detail');
  var companyIcon = document.getElementById('companyIcon');
  var productIcon = document.getElementById('productIcon');
  var otherIcon = document.getElementById('otherIcon');
  companyIcon.classList.add("active");
  productIcon.classList.remove("active");
  otherIcon.classList.remove("active");

  tabid.classList.add("active");
    tabid2.classList.remove("active");
    document.getElementById('progressbar').style.width ='33.3333%';
    tabid3.classList.remove("active");
  if(document.getElementById('companyname').value  === ""){
    document.getElementById("errorCompanyName").innerText = 'Enter company name';
    return false;
  }
  else if(document.getElementById('contectperson').value === ""){
    document.getElementById("errorName").innerText = 'Enter contact person';
    return false;
 
  }
 else if(document.getElementById('email').value === ""){
  let emailtext = document.getElementById('email').value;
    document.getElementById("errorEmail").innerText = 'Enter email Id';
    return false;

  }
  else if(document.getElementById('mobile').value === ""){

      document.getElementById("errorMobile").innerText = 'Enter mobile no';
      return false;

}
else if(document.getElementById('mobile').value != ""){
  let mobileno = document.getElementById('mobile').value;
  if(isNaN(mobileno))
  {
    document.getElementById("errorMobile").innerText = 'Enter number';
    return false;
  }
  else
  {if(mobileno.length <10)
  {
    document.getElementById("errorMobile").innerText = 'Enter valid mobile no';
    return false;
  }
}
}
 
  else if(document.getElementById('country').value === "-1"){
    document.getElementById("errorCountry").innerText = 'Select country';
    return false;
    
  }
  else if(document.getElementById('state').value === "-1"){
    document.getElementById("errorState").innerText = 'Select state';
    return false;
   
  }
  else if(document.getElementById('city').value === "-1"){
    document.getElementById("errorCity").innerText = 'Select city';
    return false;
  }
  else if(document.getElementById('companyaddress').value === ""){
    document.getElementById("errorAddress").innerText = 'Enter company address';
    return false;
  }
  
  else if(document.getElementById('postalcode').value === ""){
    let postalcode = document.getElementById('postalcode').value;
      document.getElementById("errorPostal").innerText = 'Enter postalcode';
      return false;
  }
  else if(document.getElementById('postalcode').value != ""){
   
    let postalcode = document.getElementById('postalcode').value;
    if(isNaN(postalcode))
    {
      document.getElementById("errorPostal").innerText = 'Enter number';
      return false;
    }
    else
    {if(postalcode.length <6)
    {
      document.getElementById("errorPostal").innerText = 'Enter valid postal code';
      return false;
    }
  }
  }
  else if(document.getElementById('website').value === ""){
  
    document.getElementById("errorWebsite").innerText = 'Enter company website';
    return false;
  }
  else {
  var weburl = /^(http|https):\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
  var websiteurl = document.getElementById('website').value;
  if(!weburl.test(websiteurl))
  {
    document.getElementById("errorWebsite").innerText = 'Enter valid company website';
    return false;
  }
}
if(document.getElementById('industry').value === "-1"){
    document.getElementById("errorIndustry").innerText = 'Select industry';
    return false;
  }
  
  else {
   // return true;
 
    companyIcon.classList.remove("active");
    productIcon.classList.add("active");
    otherIcon.classList.remove("active");
    tabid.classList.remove("active");
    tabid2.classList.add("active");
    document.getElementById('progressbar').style.width ='66.6666%';
    tabid3.classList.remove("active");
    return true;
  }
}
let saveleadProduct = async() =>{
  var userId = localStorage.getItem("userId");
  productValidation();
  if(productValidation() == false){
    productValidation();
  }
  else{
  var checkBoxes = document.querySelectorAll('input[type="checkbox"]');
  let result = [];
  let resp ;
  let sendDataval ='';
  checkBoxes.forEach( async item => { // loop all the checkbox item
      if (item.checked) {  //if the check box is checked
          // let data = {    // create an object
          //   course: item.value,
          // }
          // result.push(data); 
     
 
  sendDataval = {
    'dealId' : dealid,
    "productId" : item.value,
    "userID" : userId
    }
    result.push(sendDataval);
    resp = await SaveDealProduct(sendDataval);
  }
  })

  console.log(resp);
}
}
function previous(e){
  if(e === 1)
  {
    var tabid = document.getElementById('progress-seller-details');
  var tabid2 = document.getElementById('progress-company-document');
  var tabid3 = document.getElementById('progress-bank-detail');
  var companyIcon = document.getElementById('companyIcon');
  var productIcon = document.getElementById('productIcon');
  var otherIcon = document.getElementById('otherIcon');
  companyIcon.classList.add("active");
  productIcon.classList.remove("active");
  otherIcon.classList.remove("active");

  tabid.classList.add("active");
    tabid2.classList.remove("active");
    document.getElementById('progressbar').style.width ='33.3333%';
    tabid3.classList.remove("active");
  }
}
function productValidation(){
  var tabid = document.getElementById('progress-seller-details');
  var tabid2 = document.getElementById('progress-company-document');
  var tabid3 = document.getElementById('progress-bank-detail');
  var companyIcon = document.getElementById('companyIcon');
  var productIcon = document.getElementById('productIcon');
  var otherIcon = document.getElementById('otherIcon');
  companyIcon.classList.remove("active");
  productIcon.classList.add("active");
  otherIcon.classList.remove("active");
    tabid.classList.remove("active");
    tabid2.classList.add("active");
    document.getElementById('progressbar').style.width ='66.6666%';
    tabid3.classList.remove("active");
  let allcheckboxes = document.querySelectorAll('input[name="productname"]');

  const errorMessage = !isChecked(allcheckboxes.length) ? `<div class="alert alert-danger alert-dismissible fade show" role="alert">
  <strong>Error!</strong> Choose at least one product.
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
  </button>
  </div>` : '';
  if(errorMessage !='')
   { document.getElementById("errCheckMessage").innerHTML = errorMessage;
    return false;
}

    tabid.classList.remove("active");
    tabid2.classList.remove("active");
    document.getElementById('progressbar').style.width ='100%';
    tabid3.classList.add("active");
    companyIcon.classList.remove("active");
    productIcon.classList.remove("active");
    otherIcon.classList.add("active");
    return true;

}
function isChecked(e) {
  let checkboxes = document.querySelectorAll('input[name="productname"]');
  for (let i = 0; i < e; i++) {
      if (checkboxes[i].checked) return true;
  }

  return false;
}
let SaveDeal = async() =>{
  var userId = localStorage.getItem("userId");
 
  otherValidation();
  if(otherValidation() == false){
    otherValidation();
  }
  else{
  const sendData1 = {
    "id": dealid,
    "primaryContact": primarycontact,
    "techinicalContactName" : techcontactname,
    "techinicalContactEmail" : techContactEmail,
    "techinicalContactPhone" : techContactNo,
    //"competitor" : 0,
    'competitorName' : otherCompetitor,
    'estimatebudget' : estimatebudget,
    'expectedClosuerDate' : expclosuredate,
    "remark" : remark,
    "dealStatus" : 0,
    "emailID" : localStorage.getItem("emailID"),
    "userID" : userId
    }

    let resultcom = [];
    let respscom ;
    let valobj ;
    let competitorData ;
      competitor.map( async val => {
        if(val.value !== 5)
         {valobj = {
          "leadId" : dealid,
          "competitorId" : val.value,
          "competitorName" : val.label
        }
        resultcom.push(valobj);
      }
       
      })
      competitorData = {
        jsonCompetitor: JSON.stringify(resultcom),
        userId: userId
      };
      respscom = await SaveCompteter(competitorData);
      
    var checkBoxes = document.querySelectorAll('input[name="nextstep"]');
    stepValidation();
    if(stepValidation() == false){
      stepValidation();
    }
    else{
  let result = [];
  let resps ;
  let sendDataval ='';
 
  checkBoxes.forEach( async item => { // loop all the checkbox item
      if (item.checked) {  //if the check box is checked

  sendDataval = {
    'dealId' : dealid,
    "stepId" : item.value,
    "userID" : userId
    }
   
    result.push(sendDataval);
    resps = await SaveNextStep(sendDataval);
  }
  })
  console.log(sendData1);


    const resp = await SavedDeal(sendData1);
    clear();
    if(resp.status === 1){
      document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
		  <strong>Success!</strong> Lead are added successfully.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
      window.setTimeout(function() {
        document.getElementById("textMessage").style.display='none';
        window.location.href = '/leads';
        }, 2000);
    }
    else{
      document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
		  <strong>Error!</strong> Lead are not added.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
    }
  }
  }
}
function otherValidation(){
  var tabid = document.getElementById('progress-seller-details');
  var tabid2 = document.getElementById('progress-company-document');
  var tabid3 = document.getElementById('progress-bank-detail');
  var companyIcon = document.getElementById('companyIcon');
  var productIcon = document.getElementById('productIcon');
  var otherIcon = document.getElementById('otherIcon');
  companyIcon.classList.remove("active");
  productIcon.classList.remove("active");
  otherIcon.classList.add("active");
    tabid.classList.remove("active");
    tabid2.classList.remove("active");
    document.getElementById('progressbar').style.width ='100%';
    tabid3.classList.add("active");
      if(document.getElementById('primarycontact').value === ""){
    document.getElementById("errorPrimarycontact").innerText = 'Enter primary contact';
  }
  if(document.getElementById('techContactEmail').value === ""){
    document.getElementById("errorTechEmail").innerText = 'Enter technical email';
  }
 if(document.getElementById('techcontactname').value === ""){
    document.getElementById("errorTechnicalcontactname").innerText = 'Enter technical contact name.';
  }
 
  if(document.getElementById('techContactNo').value === ""){
    document.getElementById("errorTechMobile").innerText = 'Enter technical contact no.';
  }
  else
    {if(document.getElementById('techContactNo').length <10)
    {
      document.getElementById("errorTechMobile").innerText = 'Enter valid contact no.';
      return false;
    }
  }
  if(document.getElementById('competitor').value === ""){
    document.getElementById("errorCompetitor").innerText = 'Choose Competitor';
  }

  
    return true;

}
function stepValidation(){
    let allcheckboxes = document.querySelectorAll('input[name="nextstep"]');

  const errorMessage = !isCheckedStep(allcheckboxes.length) ? `<div class="alert alert-danger alert-dismissible fade show" role="alert">
  <strong>Error!</strong> Choose at one option.
  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
  </button>
  </div>` : '';
  if(errorMessage !='')
   { document.getElementById("errStepMessage").innerHTML = errorMessage;
    return false;
}
}
function isCheckedStep(e) {
  let checkboxes = document.querySelectorAll('input[name="nextstep"]');
  for (let i = 0; i < e; i++) {
      if (checkboxes[i].checked) return true;
  }

  return false;
}

const checkmobileValidation = (e) =>{
  if(e.length < 10 || e.length >12){
    document.getElementById("errorMobile").innerText = 'Enter valid phone no';
    return false;
  }
  else{
    document.getElementById("errorMobile").innerText = '';
    return false;
  }
}

// document.getElementById("otherCompetitor").style.visibility = 'hidden';
let showothercompeter = async (e) =>{
  // if(e == 5){
  //   document.querySelector('#otherCompetitorCompany').style.display = 'block';
  //   return false;
  // }
  // else{
  //   if(e != 5){
  //   document.querySelector('#otherCompetitorCompany').style.display = 'none';
  //   document.querySelector('#otherCompetitor').value = '';
  //   return false;
  //   }
  // }
}

      let handlechage = async (e) => {
        //clear();
        
        let name = e.target.name;
        let value = e.target.value;
        
        if (name === 'companyname') {
          setCompanyname(value)
         }
        if (name === 'contectperson') {
       
          setContectperson(value)
          
      }
      if (name === 'email') {
        setEmail(value)
      }
      if (name === 'mobile') {
       
        setMobile(value)
        checkmobileValidation(value)
       
       }

       if(e.target.checked === true && name === 'noofexcustomer')
       {
        setNoofexcustomer(e.target.value)
       }
      
        if (name === 'country') {
            getState(value)
            setCountry(value);
        }
        if (name === 'state') {
          getCity(value);
          setState(value);
          }
          if (name === 'city') {
            setCity(value);
        }
        if (name === 'companyaddress') {
          setCompanyaddress(value)
        }
        if (name === 'postalcode') {
          setPostalcode(value)
        }
        if (name === 'website') {
          setWebsite(value)
        }
        if (name === 'noofemployee') {
          setPerson(value)
        }
       
        if (name === 'industry') {
          setIndustry(value)
        }
        if (name === 'industry') {
          setIndustry(value)
        }
        if(name === 'primarycontact')
        {
        setPrimaryContact(value)
      }
      if(name === 'techcontactname')
        {
          setTechContactName(value)
      }
      if(name === 'techContactNo')
        {
        setTechContactNo(value)
      }
      if(name === 'techContactEmail')
        {
        setTechContactEmail(value)
      }
      // if(name === 'competitor')
      //   {
      //     document.querySelector('#otherCompetitorCompany').style.display = 'none';
      //   setCompetitor(value)
      //   showothercompeter(value);
      //   // if(value == 5){
      //   //   document.getElementById('otherCompetitorCompany').display = 'block';
      //   // }
      //   // else{
      //   //   document.getElementById('otherCompetitorCompany').display = 'block';
      //   // }
  
      // }
      if(name === 'estimateBudget'){
        setEstimateBudget(value)
      }
      if(name === 'expectedClosuerDate')
        {
          setClosureDate(value)
        }
      if(name === 'remark')
      {
      setRemark(value)
    }
      if(name === 'otherCompetitor')
      {
      setotherCompetitor(value)
    }
     
    
      };
    
      const handleSelectCompetitor = async (selectedOption) => {

        setSelectedOption(selectedOption);
        selectedOption.map((val, ind) => {
          if(val.value === '5'){
           
          document.querySelector("#otherCompetitorCompany").style.display = "block";
          }
          else {
            document.querySelector("#otherCompetitorCompany").style.display = "none";
          }
        })
      
        setCompetitor(selectedOption)
        showothercompeter(selectedOption);


        console.log(selectedOption);

      };
     
      useEffect(() => {
        getCountry();
        getState();
        getCity();
        getProduct();
        getIndustryType();
        getTrackStep();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Add lead</title>
                <link rel="canonical" href="http://localhost:5000/add-dealer" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
              
<div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row align-items-center">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Add Lead</h3>
{/* <ul className="breadcrumb">
<li className="breadcrumb-item active">Add Lead</li>
</ul> */}
</div>
</div>
</div>
</div>

<div className="row">
<div className="col-sm-12">
<div className="card comman-shadow">
<div className="card-body">
  



<div id="progrss-wizard" className="twitter-bs-wizard">
<div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='addDeal' id='addDeal' autoComplete='off'>
  <input type='hidden' name='dealid' id='dealid' value={dealid} />
<ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
<li className="nav-item">
<a href="#progress-seller-details" id='companyIcon' className="nav-link" data-toggle="tab">
<div className="step-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Company Details">
<i className="far fa-user"></i>
</div>
</a>
</li>
<li className="nav-item">
<a href="#progress-company-document" id='productIcon' className="nav-link" data-toggle="tab">
<div className="step-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Products">
<i className="fa fa-cart-plus"></i>
</div>
</a>
</li>
<li className="nav-item">
<a href="#progress-bank-detail" id='otherIcon' className="nav-link" data-toggle="tab">
<div className="step-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Other Details">
<i className="fa fa-check"></i>
</div>
</a>
</li>
</ul>

<div id="bar" className="progress mt-4">
<div className="progress-bar bg-success progress-bar-striped progress-bar-animated" id='progressbar'></div>
</div>
<div className="tab-content twitter-bs-wizard-tab-content">
<div className="tab-pane" id="progress-seller-details">
<div className="mb-4">
<h5>Customer Details</h5>
</div>

<div className="row">
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Orgnization Name <span className="login-danger">*</span></label>
<input className="form-control textcaptlizes" type="text"  required placeholder="Enter orgnization name" name='companyname' id="companyname" onChange={handlechage} />
<span id="errorCompanyName" class="errormsg"></span>
</div>
</div>

{/* <div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Company Contact No. <span className="login-danger">*</span></label>
<input className="form-control" type="number" size="15" required placeholder="Enter Company Contact no." name='companycontact' id='companycontact' onChange={handlechage}/>
<span id="errorCompanyMobile"></span>
</div>
</div> */}
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Contact Person <span className="login-danger">*</span></label>
<input className="form-control textcaptlizes" type="text" required placeholder="Enter name" name='contectperson' id="contectperson" onChange={handlechage}/>
<span id="errorName" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Email <span className="login-danger">*</span></label>
<input className="form-control" type="email" required placeholder="Enter email" name='email' id='email' onChange={handlechage} />
<span id="errorEmail" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Mobile No. <span className="login-danger">*</span></label>
<input className="form-control" type="number" required placeholder="Enter mobile" name='mobile' id='mobile' onChange={handlechage}/>
<span id="errorMobile" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Country <span className="login-danger">*</span></label>

<select className="selectstyle select" name="country" id="multiple" required onChange={handlechage}>
<option value={-1}>Select country</option>
{country &&  country.map((val, ind) => { return (<option value={val.id}>{val.countryName}</option>);
})} 
</select>
<span id="errorCountry" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>State <span className="login-danger">*</span></label>
<select className="selectstyle select" name="state" required id="state" onChange={handlechage}>
<option value={-1}>Select state</option>
{state &&  state.map((val, ind) => { return (<option value={val.id}>{val.stateName}</option>);
})} 
</select>
<span id="errorState" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>City <span className="login-danger">*</span></label>
<select className="selectstyle select" name='city' required id="city" onChange={handlechage}>
<option value={-1}>Select city</option>
{city &&  city.map((val, ind) => { return (<option value={val.id}>{val.name}</option>);
})} 
</select>
<span id="errorCity" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms ">
<label>Customer Address<span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter company address" name='companyaddress' id='companyaddress' onChange={handlechage}/>
<span id="errorAddress" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms ">
<label>Postal Code/Zip Code<span className="login-danger">*</span></label>
<input className="form-control" type="number" required placeholder="Enter postal code" name='postalcode' id='postalcode' onChange={handlechage}/>
<span id="errorPostal" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms ">
<label>Website<span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter website" name='website' id='website' onChange={handlechage} />
<span id="errorWebsite" class="errormsg"></span>
</div>
</div>
{/* <div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>No of Employees </label>
<input className="form-control" type="text" placeholder="Enter No. of employees" name='noofemployee' id="noofemployee" onChange={handlechage} />
</div>
</div> */}
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Industry <span className="login-danger">*</span></label>
<select className="selectstyle select" name='industry' required id="industry" onChange={handlechage}>
<option value={-1}>Select Industry </option>
{industrylist &&  industrylist.map((val, ind) => { return (<option value={val.id}>{val.industryType}</option>);
})} 
</select>
<span id="errorIndustry" class="errormsg"></span>
</div>
</div>
<div className="col-12 col-sm-4">
<div className="form-group local-forms">
<label>Existing Customer </label>
<br />
<div className="radio">

&nbsp;&nbsp;<lable><input type="radio" name="noofexcustomer" value="1" onClick={handlechage} /> Yes </lable>&nbsp;&nbsp;

<lable><input type="radio" name="noofexcustomer" value="0" onChange={handlechage} /> No </lable>
</div>
{/* <input className="form-control" type="text" placeholder="Enter no. of existing customers" name='noofexcustomer' id="noofexcustomer" onChange={handlechage} /> */}
</div>
</div>

</div>

<ul className="pager wizard twitter-bs-wizard-pager-link">
<li className="next"><button className="btn btn-primary" onClick={()=>{submitDeal();}}>Next <i className="bx bx-chevron-right ms-1"></i></button></li>
</ul>
</div>
<div className="tab-pane" id="progress-company-document">
<div>
<div className="mb-4">
<span id='errCheckMessage'></span>
<h5>Select Product</h5>
</div>

<div className="row">
<div className="col-lg-6">
  <h5>Software</h5>
<ul className='productsec'>
{productlist &&  productlist.map((val, ind) => { return ( val.categoryId === 1 && <li className='productitem'><label><input type='checkbox' value={val.id} name='productname' id='productname' /> <span>{val.productName}</span></label></li>);
})} 
  

</ul>
</div>
<div className="col-lg-6">
  <h5>Hardware</h5>
<ul className='productsec'>
{productlist &&  productlist.map((val, ind) => { return ( val.categoryId === 2 && <li className='productitem'><label><input type='checkbox' value={val.id} name='productname' id='productname' /> <span>{val.productName}</span></label></li>);
})} 
  

</ul>
</div>
</div>


<ul className="pager wizard twitter-bs-wizard-pager-link">
<li className="previous"><a href="javascript: void(0);" className="btn btn-primary" onClick={()=>{previous(1);}}><i className="bx bx-chevron-left me-1"></i> Previous</a></li>
<li className="next"><a href="javascript: void(0);" className="btn btn-primary" onClick={()=>{saveleadProduct();}}>Next <i className="bx bx-chevron-right ms-1"></i></a></li>
</ul>
</div>
</div>
<div className="tab-pane" id="progress-bank-detail">
<div>
<div className="mb-4">
<h5>Other Details</h5>
</div>


<div className="row">
<div className="col-lg-4">
<div className="form-group local-forms">
<label for="progresspill-cardno-input" className="form-label">Primary Contact Name</label>
<input type="text" name='primarycontact' className="form-control textcaptlizes" placeholder='Primary Contact Name' id="primarycontact" onChange={handlechage}/>
<span id="errorPrimarycontact" class="errormsg"></span>
</div>

<div className="form-group local-forms">
<label for="progresspill-card-verification-input" className="form-label">Designation</label>
<input type="text" className="form-control textcaptlizes" placeholder='Designation' name='techcontactname' id="techcontactname" onChange={handlechage}/>
<span id="errorTechnicalcontactname" class="errormsg"></span>
</div>

<div className="form-group local-forms">
<label for="progresspill-expiration-input" className="form-label">Phone No.</label>
<input type="number" className="form-control" placeholder='Phone No.' name='techContactNo' id="techContactNo" onChange={handlechage}/>
<span id="errorTechMobile" class="errormsg"></span>

</div>
<div className="form-group local-forms">
<label for="progresspill-expiration-input" className="form-label">Remark</label>
<textarea required className="form-control" placeholder='Remark' name='remark' id="remark" onChange={handlechage}></textarea>
<span id="errorTechEmail" className="errormsg"></span>
</div>
</div>
<div className="col-lg-4">
<div className="form-group local-forms">
<label for="progresspill-expiration-input" className="form-label">Email Id</label>
<input type="email" required className="form-control" placeholder='Email Id' name='techContactEmail' id="techContactEmail" onChange={handlechage}/>
<span id="errorTechEmail" className="errormsg"></span>
</div>


<div className="form-group local-forms">
<label>Competitor <span className="login-danger"></span></label>
<Select className="selectstyle select" name='competitor' id="competitor" isMulti placeholder={<div>Select Competitor</div>}
                            options={options}
                            onChange={handleSelectCompetitor}
                            value={selectedOption}
                        />
<span id="errorCompetitor" className="errormsg"></span>
</div>
<div className="form-group local-forms" id="otherCompetitorCompany">
<label for="progresspill-expiration-input" className="form-label">Other Competitor</label>
<input type="text" required className="form-control" placeholder='Other Competitor Name' name='otherCompetitor' id="otherCompetitor" onChange={handlechage}/>
<span id="errorTechEmail" class="errormsg"></span>
</div>
<div className="form-group local-forms">
<label for="progresspill-expiration-input" className="form-label">Estimated Budget</label>
<input type="text" required className="form-control" placeholder='Estimated Budget' name='estimateBudget' id="estimateBudget" onChange={handlechage}/>
<span id="errorTechEmail" class="errormsg"></span>
</div>

</div>
<div className="col-12 col-sm-4">
<span id="errStepMessage"></span>
  <h6>Next Step</h6>
  <ul>
  {tractlist &&  tractlist.map((val, ind) => { return (  <li className='productitem' key={ind}><label><input type='checkbox' value={val.id} name='nextstep' id='nextstep' /> <span>{val.name}</span></label></li>);
})} 
  {/* <li>
<label>
<input type='checkbox' name='nextstep' id='nextstep' value='1'/> <span>Demo Product</span> </label>
</li>
<li>
<label>
<input type='checkbox' name='nextstep' id='nextstep' value='2'/> <span>Meeting</span> </label>
</li>
<li>
<label>
<input type='checkbox' name='nextstep' id='nextstep' value='3'/> <span>Phone Call</span> </label>
</li>
<li>
<label>
<input type='checkbox' name='nextstep' id='nextstep' value='4'/> <span>Webcast</span> </label>
</li> */}
</ul>

</div>
</div>

<ul className="pager wizard twitter-bs-wizard-pager-link">
<li className="previous"><a href="javascript: void(0);" className="btn btn-primary" onclick="nextTab()"><i className="bx bx-chevron-left me-1"></i> Previous</a></li>
<li className="float-end"><button name='saveDeal' id='saveDeal' onClick={()=>{SaveDeal();}}  className="btn btn-primary" >Submit Lead</button></li>
</ul>
</div>
</div>
</div>
</form>
</div>

<div class="modal fade confirmModal" tabindex="-1" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered">
<div class="modal-content p-3">
<div class="modal-header border-bottom-0">
<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body">
<div class="text-center">
<h5 class="mb-3">Confirm Save Changes</h5>
<button type="button" class="btn btn-dark w-md" data-bs-dismiss="modal">Close</button>
<button type="button" class="btn btn-primary w-md" data-bs-dismiss="modal" onclick="nextTab()">Save changes</button>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
            </div>
    </React.Fragment>
    )
}

