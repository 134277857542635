
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import axios from 'axios';

import SaveProduct from "../API/SaveProduct";
import SaveProductImage from "../API/SaveProductImage";
import ManageSidebar from '../components/ManageSidebar';
//import Footer from "../components/Footer";

export default function AddDealers(){  

    let [productname,setProductName] = useState();
    let [modelNo,setModelNumber] = useState();
    let [producttype, setProductType] = useState();
    let [description, setDescription] = useState();
    let [productvalue,setProductValue] = useState();
    let [productdocument, setProductDocument] = useState();
    let [productimage, setProductImage] = useState();
    const [file, setFile] = useState();
    let clear = async () =>{
      document.getElementById("addProduct").reset();
      setProductName('');
      setProductType('');
      setDescription('');
      setProductValue('');
      //setProductDocument('');
  }
    


      
let submitProduct = async() =>{
  var userId = localStorage.getItem("userId");
  if(document.getElementById('productname').value === ""){
    document.getElementById("errorName").innerText = 'Enter product name';
  }
  else if(document.getElementById('productdocument').value === ""){
    document.getElementById("errorDoc").innerText = 'choose product image';
  }
  else if(document.getElementById('productvalue').value === "" && Number.isInteger(document.getElementById('productvalue').valu)){
    document.getElementById("errorProvalue").innerText = 'Enter product value';
  }
 else if(document.getElementById('description').value === ""){
    document.getElementsByClassName("errorDescription").innerText = 'Enter description';
  }

  else{
    const imgFile = document.getElementById('productdocument');
    // console.log(imgFile.files[]);
     
    // let formData = new FormData();
    // formData.append('file', productdocument);
    // 
    const formData = new FormData();
    // formData.append('name', 'Image Upload');
    // formData.append('file', file);
   
  const sendData = {
    "productName": productname,
    "categoryId" : producttype,
    "description": description,
    "supportingDocument": imgFile.files[0].name,
    "productValue": parseFloat(productvalue).toFixed(2),
    "userID" : userId
    }
    // const sendData = {
    //   "file": imgFile.files[0],
    //   }
    formData.append(
      "attachmentFiles",
      imgFile.files[0],
      imgFile.files[0].name
  );
  formData.append('ProductName', productname);
  formData.append('modelNo', modelNo);
  formData.append('Description', description);
  formData.append('ProductValue', parseFloat(productvalue).toFixed(2));
  formData.append('CategoryId',producttype);
  formData.append('UserId',userId);
  let url =window.UserbaseUrl+"/api/ProductMaster/UploadDoc";

    const resps = axios.post(url, formData);
    console.log(resps);
  
    clear();
    // if(resps.status === 1){
      document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
		  <strong>Success!</strong> Product are added successfully.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
      window.setTimeout(function() {
        setProductImage('');
        document.getElementById("textMessage").style.display='none';
        window.location.href = '/add-product';
        }, 2000);

     
    // }
    // else{
    //   document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
		//   <strong>Error!</strong> Product are not added.
		//   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		//   </button>
		//   </div>`;
    // }
  }
}
const handleFileChange = (e) => {
  if (e.target.files) {
    setFile(e.target.files[0]);
    setProductImage(URL.createObjectURL(e.target.files[0]));
}
};
let handlechage = async (e) => {
  //clear();
  let name = e.target.name;
  let value = e.target.value;

//   if (e.target.files) {
//     setFile(e.target.files[0]);
// }
  
  if (name === 'productname') {
 
    setProductName(value)
    
}
if(name === 'producttype'){
  setProductType(value)
}
if(name === 'productdocument'){
  setProductDocument(value)

  
}
if(name === 'productvalue'){
  setProductValue(value);
}
if (name === 'description') {
  setDescription(value)
}
if (name === 'productmodel') {
  setModelNumber(value)
}


};
     
      useEffect(() => {
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }

      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Add Product</title>
                <link rel="canonical" href="http://localhost:5000/add-product" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
              
<div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row align-items-center">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Add Product</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href='/products' >All Product</a></li>
</ul>
</div>
</div>
</div>
</div>

<div className="row">
<div className="col-sm-12">
<div className="card comman-shadow">
<div className="card-body">
  <div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='addProduct' id='addProduct' enctype="multipart/form-data"> 
<div className="row">
<div className="col-12">
<h5 className="form-title student-info">Product Information <span><a href="javascript:;"><i className="feather-more-vertical"></i></a></span></h5>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Product Name <span className="login-danger">*</span></label>
<input className="form-control" type="text" required placeholder="Enter product name" name='productname' id="productname" onChange={handlechage}/>
<span id="errorName" className='errormsg'></span>
</div>

<div className="form-group local-forms">
<label>Product Type <span className="login-danger">*</span></label>
<select className="selectstyle select" name='producttype' required id="producttype" onChange={handlechage}>
<option value={-1}>Select product type </option>
<option value="1">Software</option>
			<option value="2">Hardware</option>
      </select>
<span id="errorName" className='errormsg'></span>
</div>
<div className="form-group local-forms">
<div className="form-group local-forms">
<label>Product Image <span className="login-danger">*</span></label>
<input type="file" className="form-control" accept="image/jpeg, image/png, image/jpg" required placeholder="product document" name='productdocument' id="productdocument" onChange={handleFileChange}/>
</div>
<span id="errorDoc" className='errormsg'></span>
<div className='imgsec'>
<img src={productimage} style={{"width":"150px"}} />
</div>
</div>

<div className="form-group local-forms">
<label>Product Cost <span className="login-danger">*</span></label>
<input className="form-control" type="number" required placeholder="0.00" name='productvalue' id="productvalue" onChange={handlechage}/>
<span id="errorProvalue" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
                              <label>
                                Product Model{" "}
                                
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                required
                                placeholder="Enter product model"
                                name="productmodel"
                                id="productmodel"
                                onChange={handlechage}
                              />
                              
                            </div>
<div className="form-group local-forms">
<label>Description <span className="login-danger">*</span></label>
<textarea className="form-control" type="text" required placeholder="Enter product description" style={{"height":"350px"}} name='description' id='description' onChange={handlechage} ></textarea>
<span id="errorDescription" className='errormsg'></span>
</div>
</div>
<div className="col-12">
<div className="student-submit">
<button type="submit" name='saveProduct' id='saveProduct' onClick={()=>{submitProduct();}} className="btn btn-primary">Submit</button>
</div>
</div>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
            </div>
    </React.Fragment>
    )
}

