
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import Footer from "../components/Footer";
// import Getdeal from "../API/Getdeal";

import ManageSidebar from '../components/ManageSidebar';
import RemoveDesignation from '../API/RemoveDesignation';
import UpdateDesignation from '../API/UpdateDesignation';
import GetDesignation from '../API/GetDesignation';
import GetDesignationById from '../API/GetDesignationById';
import RemoveProductIndustry from '../API/RemoveProductIndustry';

export default function DesignationMaster(){  
    let [designationlist, getDesignationList] = useState();
    

    let [industrytypename,setIndustryTypeName] = useState();

    let getDesignation = async () => {
        let resp = await GetDesignation();
        console.log(resp);
        if(resp.status === 1)
        {
            getDesignationList(resp.responseValue);  
        }
      };

      
      let removeDesignation = async (e) =>{
        let con = window.confirm('Are you sure want to delete this Record?');
        if(con == true)
        {
          const sendData = {
            "id": e,
               }
               const resp = await RemoveDesignation(sendData);
               
               if(resp.status === 1){
                   document.getElementById("deleteMsg").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong>Record are deleted successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
               getDesignation();
               window.setTimeout(function() {

                document.getElementById("deleteMsg").style.display='none';
                //window.location.href = '/partner-type';
                }, 2000);
               }
              }
              else{
                return false;
              }
      }
    //   let editPartner = async(e) =>{
    //     const resp = await GetdealdyId(e);
    //     if(resp.status === 1)
    //      {
    //       document.getElementById('dealid').value = resp.responseValue[0].id;
    //       document.getElementById('contectpersonval').value = resp.responseValue[0].contactPerson;
    //       document.getElementById('emailval').value = resp.responseValue[0].emailID;
    //       document.getElementById('mobileval').value = resp.responseValue[0].mobile;
    //       document.getElementById('companycontactval').value = resp.responseValue[0].companyContact;
    //       document.getElementById('companynameval').value = resp.responseValue[0].companyName;
    //       document.getElementById('countryval').value = resp.responseValue[0].companyCountryID;
    //       document.getElementById('companyaddressval').value = resp.responseValue[0].companyAddress;
    //       document.getElementById('postalcodeval').value = resp.responseValue[0].companyZipCode;
    //       document.getElementById('websiteval').value = resp.responseValue[0].website;

    //       document.getElementById('noofemployeeval').value = resp.responseValue[0].noOfEmployee;
    //       document.getElementById('noofexcustomerval').value = resp.responseValue[0].isExixtingCustomer;
    //       document.getElementById('industryval').value = resp.responseValue[0].industryTypeID;
    //      }
 
    //  }
     let clear = async () =>{
      document.getElementById("updateIndustryType").reset();

  }
   
    
  let handlechage = async (e) => {
    //clear();
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'industrytypename') {
        setIndustryTypeName(value)
    }
    // if (name === 'description') {
    //   setDescription(value)
    // }
  
    };
  
      let editDesignation = async(e) =>{
        const resp = await GetDesignationById(e);
        if(resp.status === 1)
         {
          document.getElementById('desigId').value = resp.responseValue[0].id;
          document.getElementById('industrytypename').value = resp.responseValue[0].designation;
         }
 
     }

     let updateDesignation = async()=>{
      var userId = localStorage.getItem("userId");
      if(document.getElementById('industrytypename').value === ""){
        document.getElementById("errorName").innerText = 'Enter industry type name';
      }
      else{
      const sendData = {
        'id': document.getElementById('desigId').value,
        "designation": document.getElementById('industrytypename').value,
        "userId" : userId
        }
        
        const resp = await UpdateDesignation(sendData);
        console.log(resp);
        clear();
        if(resp.status === 1){
          document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
          <strong>Success!</strong> Designation are updated successfully.
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
          </button>
          </div>`;
          getDesignation();
          window.setTimeout(function() {
           window.location ='/designation-master';
            document.getElementById("textMessage").classList.toggle("hide");
            }, 2000);
        }
        else{
          document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Error!</strong> Designation are not updated.
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
          </button>
          </div>`;
        }
      }
     }
     let  searchTable = async()=> {
      // Declare variables
      var input, filter, table, tr, td, i, j, txtValue,found;
      input = document.getElementById("searchText");
      filter = input.value.toUpperCase();
      table = document.getElementById("tableData");
      tr = table.getElementsByTagName("tr");
    
      // Loop through all table rows, and hide those who don't match the search query
    
        for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td");
        for (j = 0; j < td.length; j++) {
            if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
                found = true;
            }
            
        }
        if (found) {
            tr[i].style.display = "";
            found = false;
        } else {
           tr[i].style.display = "none";

        }
    }
  
    }
       let changeFronTDate = (e) =>{
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(e)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
        }
      useEffect(() => {
        getDesignation();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
        
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Designation Master</title>
                <link rel="canonical" href="http://localhost:5000/designation-master" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
                <div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Designation</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href="/add-designation" className="btn btn-primary text-white"><i className="fas fa-plus"></i> Add Designation</a></li>
</ul>
</div>
</div>
</div>
</div>
<div className="row">
<div className="col-sm-12">
<div className="card card-table comman-shadow">
<div className="card-body">

<div className="page-header">
<div className="row align-items-center">

<div className="col-9">
    
<h3 className="page-title">Designation</h3>
</div>
<div className="col-3 text-end float-end ms-auto download-grp">
<ul className='headerlist'><li className='headerlistitem'><div className="form-group">
<input type="text" className="form-control" placeholder="Search ..." id='searchText' name='searchText' onChange={()=>{searchTable();}}/>
</div></li>

</ul>
</div>
</div>
</div>

<div className="table-responsive fixTableHead">
<span id="deleteMsg"></span>
<table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
<thead className="student-thread">
<tr>
<th>
#
</th>
<th>Name</th>
<th className="text-end">Action</th>
</tr>
</thead>
<tbody id="tableData">
{
designationlist && designationlist.length > 0 ?
designationlist.map((val, ind) => { return (
<tr>
<td>
{ind+1}
</td>
<td>{val.designation}</td>   
<td className="text-end">
<div className="actions ">
{/* <a href="javascript:;" className="btn btn-sm bg-success-light me-2 ">
<i className="feather-eye"></i>
</a> */}

<a href="javascript:;" id="partnerstatus" title='Edit' data-bs-toggle="modal" onClick={() => {editDesignation(val.id);}} data-bs-target="#con-close-modal" className="btn btn-sm bg-danger-light">
<i id="partnerstatusicon" class="feather-edit"></i>
</a>&nbsp;
<a href="javascript:;" title='Remove' onClick={()=>{removeDesignation(val.id);}}  className="btn btn-sm bg-danger-light">
<i className="feather-trash"></i>
</a>
</div>
</td>
</tr>
);
})
: <tr><td colspan="2" style={{textAlign :"center"}}>No results found</td></tr>
}
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div id="con-close-modal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display:"none", }}>
<div className="modal-dialog modal-lg">
<div className="modal-content">
<div className="modal-header">
<h4 className="modal-title">Update Designation</h4>
<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div className="modal-body">
<div className="card comman-shadow">
<div className="card-body">
<div id='textMessage'></div><div id='errorMessage'></div>
  <form method='post' action='javascript:;' name='updateIndustryType'  id='updateIndustryType' enctype="multipart/form-data"> 
<div className="row">

<input type='hidden' name='desigId' id='desigId' onChange={handlechage}/>
<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Designation Name <span className="login-danger">*</span></label>
<input className="form-control" style={{"text-transform":"capitalize"}} type="text" required placeholder="Enter designamtion name" name='industrytypename' id="industrytypename" onChange={handlechage}/>
<span id="errorName" className='errormsg'></span>
</div>
</div>
</div>
</form>
</div>
</div>
</div>
<div className="modal-footer">
<button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal">Close</button>
<button type="submit" className="btn btn-info waves-effect waves-light" onClick={() =>{updateDesignation();}} name='updatedesignation' id='updatedesignation'>Save changes</button>
</div>
</div>
</div>
</div>
< Footer />

</div>
            </div>
    </React.Fragment>
    )
}

