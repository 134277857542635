import React, {useState} from "react";
import {Helmet} from "react-helmet";

export default function Admin(){  
	const [userid, setUserId] = useState("");
	const [password, setPassword] = useState("");
	let clear = () => {
	  setPassword('')
	  setUserId('')
	
	};
	const loginUser = async () => {
		let username = 'Critadmin';
		let userpassword = 'Crite@#456';
		if(username === userid && userpassword === password)
		{
			window.localStorage.setItem('userId' , 421);
		window.localStorage.setItem('loginId' , userid);
		window.localStorage.setItem('userRole' , 'Administrator');
		window.localStorage.setItem('userName' , 'Channel Admin');
	
		
		if(window.localStorage.getItem('userId') !== "")
		{      clear();
			 window.location.href = '/admin-dashboard';
		}
	}
	else{
		
		document.getElementById("errorMsg").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
		<strong>Error!</strong> Invalid Userid or password.
		<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		</button>
		</div>`;
	}
	  }
	  let togglePass = async() =>{
		let field = document.getElementById('password');
		let iconsImg = document.getElementById('passicon');
		if(field.getAttribute('type') == 'password')
		{
			field.setAttribute("type","text");
		}
		else 
		{if(field.getAttribute('type') == 'text')
		{
			field.setAttribute("type","password");
		}
	}
	iconsImg.classList.toggle('feather-eye-off');
	  }
	  let handlechage = async (e) => {
    
		let name = e.target.name;
		let value = e.target.value;
		if (name === 'userid') {
		  setUserId(value)
		}
		else {
			setPassword(value)
		}
	  };
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Admin Panel</title>
                <link rel="canonical" href="http://localhost:5000/admin" />
            </Helmet>
         
    {/* <!-- /Preload --> */}
	
	<div className="main-wrapper login-body">
<div className="login-wrapper">
<div className="container">
<div className="loginbox">
<div className="login-left">
<img className="img-fluid" src="assets/img/login.jpg" alt="Logo"/>
</div>
<div className="login-right">
<div className="login-right-wrap">
<h1>Welcome to CriterionTech Channel Partner</h1>

<h2>Admin Login</h2>
<div id="errorMsg"></div>
<form action="javascript:;"  method="post" className='loginform' id="loginform">
<div className="form-group">
<label>Username <span className="login-danger">*</span></label>
<input className="form-control" type="text" id='userid' placeholder="User Id" name="userid" value={userid} onChange={handlechage}/>
<span className="profile-views"><i className="fas fa-user-circle"></i></span>
</div>
<div className="form-group">
<label>Password <span className="login-danger">*</span></label>
<input className="form-control pass-input" type="password" id="password" value={password} onChange={handlechage} placeholder='*******'/>
<span className="profile-views feather-eye toggle-password" id="passicon" onClick={()=>{togglePass();}}></span>
</div>
<div className="forgotpass">
<div className="remember-me">
<label className="custom_check mr-2 mb-0 d-inline-flex remember-me"> Remember me
<input type="checkbox" name="radio"/>
<span className="checkmark"></span>
</label>
</div>
{/* <a href="forgot-password.html">Forgot Password?</a> */}
</div>
<div className="form-group">
<button className="btn btn-primary btn-block" type="submit" onClick={() => {loginUser();}}>Login</button>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
    </React.Fragment>
    )
}

