export default async function GetTraning(userId) {
    
    let url =window.UserbaseUrl+"/api/TrainingRequest/SelectTrainingRequest?UserId="+userId;
    let head={ 'Content-Type': 'application/json-patch+json'};
    let response = await fetch(url,{
      method: "GET",
      headers: head
    }).then((res) => res.json());
    return response;
  }
