
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import SaveIndustryProduct from "../API/SaveIndustryProduct";
import ManageSidebar from '../components/ManageSidebar';
import Getproduct from '../API/Getproduct';
import GetIndustryType from '../API/GetIndustryType';
import GetJobTitle from "../API/GetJobTitle";


//import Footer from "../components/Footer";

export default function AddProductIndustry(){  

    // let [industrytypename,setIndustryTypeName] = useState();
    let [productlist, getProductName] = useState();
    let [industrylist, getIndustryList] = useState();
    let [product, setProduct] = useState();
    let [industry, setIndustry] = useState();
    let [department, setDepartment] = useState();
    let [designation, setJobTitle] = useState();
    let clear = async () =>{
      document.getElementById("addProductIndustry").reset();
  }
    
  let getProduct = async () => {
    let resp = await Getproduct();
    if(resp.status === 1)
    {
      getProductName(resp.responseValue);  
    }
  };
  let getJobTitle = async()=> {
        
    let resp = await GetJobTitle();
    if(resp.status === 1)
    {
        setJobTitle(resp.responseValue);  
    }
  }
  let getIndustryType = async () => {
    let resp = await GetIndustryType();
    if(resp.status === 1)
    {
        getIndustryList(resp.responseValue);  
    }
  };
let submitProductIndustry = async() =>{
  var userId = localStorage.getItem("userId");
  if(document.getElementById('product').value === ""){
    document.getElementById("errorProduct").innerText = 'Select product';
  }
  else if(document.getElementById('industry').value === ""){
    document.getElementById("errorName").innerText = 'Select industry';
  }
 else if(document.getElementById('department').value === ""){
    document.getElementsByClassName("errorDepartment").innerText = 'Select department';
  }
  
  else{
  const sendData = {
    "productID": product,
    "industryId": industry,
    'department': department,
    "userId" : userId
    }
    
    const resp = await SaveIndustryProduct(sendData);
    console.log(resp);
    clear();
    if(resp.status === 1){
      document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
		  <strong>Success!</strong> Product Industry are added successfully.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
    }
    else{ if(resp.status != 1){
      document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
		  <strong>Error!</strong> Product Industry are not added.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
    }
    }
  }
}

let handlechage = async (e) => {
  //clear();
  let name = e.target.name;
  let value = e.target.value;
  if (name === 'product') {
      setProduct(value)
  }
  if (name === 'industry') {
    setIndustry(value)
  }
  if (name === 'department') {
    setDepartment(value)
  }
  };
     
      useEffect(() => {
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
        getProduct();
        getIndustryType();
        getJobTitle();
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Add Product Industry </title>
                <link rel="canonical" href="http://localhost:5000/add-product-industry" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
              
<div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row align-items-center">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Add Product Industry</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href='/product-industry' >All Product Industry</a></li>
</ul>
</div>
</div>
</div>
</div>

<div className="row">
<div className="col-sm-12">
<div className="card comman-shadow">
<div className="card-body">
  <div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='addProductIndustry'  id='addProductIndustry' enctype="multipart/form-data"> 
<div className="row">
<div className="col-12 col-sm-12">
<h5 className="form-title student-info">Product Industry Information <span><a href="javascript:;"><i className="feather-more-vertical"></i></a></span></h5>
</div>
<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Product Name <span className="login-danger">*</span></label>
<select className="form-control select" name="product" id="product" required onChange={handlechage}>
<option value="">Select Product</option>
{productlist &&  productlist.map((val, ind) => { return (<option value={val.id}>{val.productName}</option>);
})} 
</select>
<span id="errorProduct" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Industry Type Name <span className="login-danger">*</span></label>
<select className="form-control select" name="industry" id="industry" required onChange={handlechage}>
<option value="">Select Industry</option>
{industrylist &&  industrylist.map((val, ind) => { return (<option value={val.id}>{val.industryType}</option>);
})} 
</select>
<span id="errorName" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Department Name <span className="login-danger">*</span></label>
<select className="form-control select" name="department" id="department" required onChange={handlechage}>
<option value="">Select Department</option>
{designation &&  designation.map((val, ind) => { return (<option value={val.id}>{val.designation}</option>);
})} 

</select>
<span id="errorDepartment" className='errormsg'></span>
</div>
</div>
<div className="col-12">
<div className="student-submit">
<button type="submit" name='saveProduct' id='saveProduct' onClick={()=>{submitProductIndustry();}} className="btn btn-primary">Submit</button>
</div>
</div>

</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
            </div>
    </React.Fragment>
    )
}

