
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";
import Footer from "../components/Footer";
import ManageSidebar from '../components/ManageSidebar';
import Getproduct from '../API/Getproduct';
import GetIndustryProduct from '../API/GetIndustryProduct';
import GetProductIndustryById from '../API/GetProductIndustryById';
import RemoveProductIndustry from '../API/RemoveProductIndustry';
import UpdateProductIndustry from '../API/UpdateProductIndustry';

import GetJobTitle from "../API/GetJobTitle";
import GetIndustryType from '../API/GetIndustryType';

export default function ProductIndustry(){  
    let [productindustrylist, getProductIndustryList] = useState();
    let [designation, setJobTitle] = useState();
    let [industry, setIndustry] = useState();
    let [productlist, getProductName] = useState();
    let [industrylist, getIndustryList] = useState();

    let getJobTitle = async()=> {
        
      let resp = await GetJobTitle();
      if(resp.status === 1)
      {
          setJobTitle(resp.responseValue);  
      }
    }
    let getIndustryType = async () => {
      let resp = await GetIndustryType();
      if(resp.status === 1)
      {
          getIndustryList(resp.responseValue);  
      }
    };
    let getProductIndustry = async () => {
        let resp = await GetIndustryProduct();
        console.log(resp);
        if(resp.status === 1)
        {
            getProductIndustryList(resp.responseValue);  
        }
      };
      
      
      let getProduct = async () => {
        let resp = await Getproduct();
        if(resp.status === 1)
        {
          getProductName(resp.responseValue);  
        }
      };
      
      let removeProductIndustry = async (e) =>{
        let con = window.confirm('Are you sure want to delete this Record?');
        if(con == true)
        {
          const sendData = {
            "id": e,
               }
               const resp = await RemoveProductIndustry(sendData);
               
               if(resp.status === 1){
                   document.getElementById("deleteMsg").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
               <strong>Success!</strong>Record are deleted successfully.
               <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
               </button>
               </div>`;
               getProductIndustry();
               window.setTimeout(function() {

                document.getElementById("deleteMsg").style.display='none';
                //window.location.href = '/partner-type';
                }, 2000);
               }
              }
              else{
                return false;
              }
      }
      
     let clear = async () =>{
      document.getElementById("updateIndustryType").reset();

  }
   
    
  let handlechage = async (e) => {
    //clear();
    let name = e.target.name;
    let value = e.target.value;
    // if (name === 'industrytypename') {
    //     setIndustryTypeName(value)
    // }
    // if (name === 'description') {
    //   setDescription(value)
    // }
  
    };
  
      let editProductIndustry = async(e) =>{
        const resp = await GetProductIndustryById(e);
        if(resp.status === 1)
         {
          document.getElementById('industryId').value = resp.responseValue[0].id;
          document.getElementById('productid').value = resp.responseValue[0].productID;
          document.getElementById('industry').value = resp.responseValue[0].industryId;
          document.getElementById('department').value = resp.responseValue[0].department;
         }
 
     }

     let updateProductIndustry = async()=>{
      var userId = localStorage.getItem("userId");
      if(document.getElementById('productid').value === ""){
        document.getElementById("errorProduct").innerText = 'Select product';
      }
      else if(document.getElementById('industry').value === ""){
        document.getElementById("errorName").innerText = 'Select industry';
      }
    else if(document.getElementById('department').value === ""){
        document.getElementsByClassName("errorDepartment").innerText = 'Select department';
      }
      else{
      const sendData = {
        'id': document.getElementById('industryId').value,
        "productID": document.getElementById('productid').value,
        "industryId": document.getElementById('industry').value,
        'department' : document.getElementById('department').value,
        "userId" : userId
        }
        
        const resp = await UpdateProductIndustry(sendData);
        console.log(resp);
        clear();
        if(resp.status === 1){
          document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
          <strong>Success!</strong> Industry Type are updated successfully.
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
          </button>
          </div>`;
          getProductIndustry();
          window.setTimeout(function() {
           // window.location ='/partner-type';
            document.getElementById("textMessage").classList.toggle("hide");
            }, 2000);
        }
        else{
          document.getElementById("errorMessage").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Error!</strong> Industry Type are not updated.
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
          </button>
          </div>`;
        }
      }
     }
     let  searchTable = async()=> {
      // Declare variables
      var input, filter, table, tr, td, i, j, txtValue,found;
      input = document.getElementById("searchText");
      filter = input.value.toUpperCase();
      table = document.getElementById("tableData");
      tr = table.getElementsByTagName("tr");
    
      // Loop through all table rows, and hide those who don't match the search query
    
        for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td");
        for (j = 0; j < td.length; j++) {
            if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
                found = true;
            }
            
        }
        if (found) {
            tr[i].style.display = "";
            found = false;
        } else {
           tr[i].style.display = "none";

        }
    }
  
    }
       let changeFronTDate = (e) =>{
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(e)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
        }
      useEffect(() => {
        getProductIndustry();
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
        getJobTitle();
        getProduct();
        getIndustryType();
        
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Partner Type List</title>
                <link rel="canonical" href="http://localhost:5000/training" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
                <div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Product Industry</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href="/add-product-industry" className="btn btn-primary text-white"><i className="fas fa-plus"></i> Add Product Industry</a></li>
</ul>
</div>
</div>
</div>
</div>
<div className="row">
<div className="col-sm-12">
<div className="card card-table comman-shadow">
<div className="card-body">

<div className="page-header">
<div className="row align-items-center">

<div className="col-9">
    
<h3 className="page-title">Product Industry</h3>
</div>
<div className="col-3 text-end float-end ms-auto download-grp">
<ul className='headerlist'><li className='headerlistitem'><div className="form-group">
<input type="text" className="form-control" placeholder="Search ..." id='searchText' name='searchText' onChange={()=>{searchTable();}}/>
</div></li>

</ul>
</div>
</div>
</div>

<div className="table-responsive">
<span id="deleteMsg"></span>
<table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
<thead className="student-thread">
<tr>
<th>
#
</th>
<th>Product</th>
<th>Industry</th>
<th>Department</th>
<th className="text-end">Action</th>
</tr>
</thead>
<tbody id="tableData">
{
productindustrylist && productindustrylist.length > 0 ?
productindustrylist.map((val, ind) => { return (
<tr>
<td>
{ind+1}
</td>
<td>{val.productName}</td>   
<td>{val.industryType}</td>  
<td>{val.departmentName}</td>   
<td className="text-end">
<div className="actions ">
{/* <a href="javascript:;" className="btn btn-sm bg-success-light me-2 ">
<i className="feather-eye"></i>
</a> */}

<a href="javascript:;" id="partnerstatus" title='Edit' onClick={() => {editProductIndustry(val.id);}} data-bs-toggle="modal" data-bs-target="#con-close-modal" className="btn btn-sm bg-danger-light">
<i id="partnerstatusicon" class="feather-edit"></i>
</a>&nbsp;
<a href="javascript:;" title='Remove' onClick={()=>{removeProductIndustry(val.id);}}  className="btn btn-sm bg-danger-light">
<i className="feather-trash"></i>
</a>
</div>
</td>
</tr>
);
})
: <tr><td colspan="12" style={{textAlign :"center"}}>No results found</td></tr>
}
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<div id="con-close-modal" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display:"none", }}>
<div className="modal-dialog modal-lg">
<div className="modal-content">
<div className="modal-header">
<h4 className="modal-title">Update Product Industry</h4>
<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div className="modal-body">
<div className="card comman-shadow">
<div className="card-body">
<div id='textMessage'></div><div id='errorMessage'></div>
  <form method='post' action='javascript:;' name='updateIndustryType'  id='updateIndustryType' enctype="multipart/form-data"> 
<div className="row">
<input type='hidden' name='industryId' id='industryId' onChange={handlechage}/>
<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Product Name <span className="login-danger">*</span></label>
<select className="form-control select" name="productid" id="productid" required onChange={handlechage}>
<option value="">Select Product</option>
{productlist &&  productlist.map((val, ind) => { return (<option value={val.id}>{val.productName}</option>);
})} 
</select>
<span id="errorProduct" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Industry Type Name <span className="login-danger">*</span></label>
<select className="form-control select" name="industry" id="industry" required onChange={handlechage}>
<option value="">Select Industry</option>
{industrylist &&  industrylist.map((val, ind) => { return (<option value={val.id}>{val.industryType}</option>);
})} 
</select>
<span id="errorName" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Department Name <span className="login-danger">*</span></label>
<select className="form-control select" name="department" id="department" required onChange={handlechage}>
<option value="">Select Department</option>
{designation &&  designation.map((val, ind) => { return (<option value={val.id}>{val.designation}</option>);
})} 
</select>
<span id="errorDepartment" className='errormsg'></span>
</div>
</div>

</div>
</form>
</div>
</div>
</div>
<div className="modal-footer">
<button type="button" className="btn btn-secondary waves-effect" data-bs-dismiss="modal">Close</button>
<button type="submit" className="btn btn-info waves-effect waves-light" onClick={()=>{updateProductIndustry();}} name='updateProduct' id='updateProduct'>Save changes</button>
</div>
</div>
</div>
</div>
< Footer />

</div>
            </div>
    </React.Fragment>
    )
}

