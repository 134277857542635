import axios from 'axios';
import React,{useEffect,useState} from 'react';
import {Helmet} from "react-helmet";
import ManageHeader from "../components/ManageHeader";

import SaveProduct from "../API/SaveProduct";
import Getproduct from "../API/Getproduct";
import SaveProductMaterial from "../API/SaveProductMaterial";
import ManageSidebar from '../components/ManageSidebar';
//import Footer from "../components/Footer";

export default function AddproductMaterial(){  

    let [producttype,setProductType] = useState();
    let [documenttype, setDocumentType] = useState();
    let [productdocument, setDocumentFile] = useState();
    let [documentimage,setDocumentImage] = useState();
    let [productlist, setProduct] = useState();
    const [file, setFile] = useState();
    let clear = async () =>{
      document.getElementById("addProduct").reset();
  }
    

  let getProduct = async () => {
    let resp = await Getproduct();
    if(resp.status === 1)
    {
        setProduct(resp.responseValue);  
    }
  };
      
let submitProductMaterial = async() =>{
  var userId = localStorage.getItem("userId");
  if(document.getElementById('producttype').value === "-1"){
    document.getElementById("errorName").innerText = 'select product Type';
  }
  else if(document.getElementById('documenttype').value === "-1"){
    document.getElementById("errorProvalue").innerText = 'select document type';
  }
  else if(document.getElementById('productdocument').value === ""){
    document.getElementById("errorDoc").innerText = 'choose document';
  }
  else{
    const imgFile = document.getElementById('productdocument');
    const formData = new FormData();
    formData.append(
      "attachmentFiles",
      imgFile.files[0],
      imgFile.files[0].name
  );
  formData.append('ProductID', producttype);
  formData.append('DocumentTypeId', documenttype);
  formData.append('userID',userId);
    let url =window.UserbaseUrl+"/api/ProductMaterial/UploadDoc";

    const resps = axios.post(url, formData);
   console.log(resps);
    clear();

      document.getElementById("textMessage").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">
		  <strong>Success!</strong> Product Material are added successfully.
		  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
		  </button>
		  </div>`;
      window.setTimeout(function() {

        document.getElementById("textMessage").style.display='none';
        //window.location.href = '/partner-type';
        }, 2000);
    
  }
}
const handleFileChange = (e) => {
  if (e.target.files) {
    setFile(e.target.files[0]);
    setDocumentImage(URL.createObjectURL(e.target.files[0]));
}
};
let handlechage = async (e) => {
  //clear();
  let name = e.target.name;
  let value = e.target.value;
  let label = e.target.text;

  if (name === 'producttype') {
 
    setProductType(value)


}
if(name === 'documenttype'){
  setDocumentType(value)
  setDocumentFile('');
  var filedata = document.querySelector("#productdocument");
  if(value == 1)
  {
    filedata.setAttribute('accept', 'audio/*');
  }
  if(value == 2)
  {
    filedata.setAttribute('accept', 'video/*');
  }
  if(value == 3)
  {
    filedata.setAttribute('accept', '.ppt');
  }
  if(value == 4)
  {
    filedata.setAttribute('accept', '.pdf');
  }
}
if(name === 'productdocument'){
  setDocumentFile(value);

}

};
     
      useEffect(() => {
        var pageView = localStorage.getItem("userName");
        var role = localStorage.getItem("userRole");
        if(pageView == null && role == null){
            window.location.href = '/'; 
        }
        getProduct();
      }, []);
        return(
            <React.Fragment>  
               <Helmet>
                <meta charSet="utf-8" />
                <title>CriterionTech - Add Product Material</title>
                <link rel="canonical" href="http://localhost:5000/add-product-material" />
            </Helmet>
            <div className="main-wrapper" id='mobilemenu'>
                <ManageHeader />
                <ManageSidebar />
              
<div className="page-wrapper">
<div className="content container-fluid">

<div className="page-header">
<div className="row align-items-center">
<div className="col-sm-12">
<div className="page-sub-header">
<h3 className="page-title">Add Product Material</h3>
<ul className="breadcrumb">
<li className="breadcrumb-item active"><a href='/product-material' >All Product Material</a></li>
</ul>
</div>
</div>
</div>
</div>

<div className="row">
<div className="col-sm-12">
<div className="card comman-shadow">
<div className="card-body">
  <div id='textMessage'></div><div id='errorMessage'></div>
<form method='post' action='javascript:;' name='addProduct' id='addProduct' enctype="multipart/form-data"> 
<div className="row">
<div className="col-12">
<h5 className="form-title student-info">Product Material Information <span><a href="javascript:;"><i className="feather-more-vertical"></i></a></span></h5>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Product Type <span className="login-danger">*</span></label>
<select className="form-control select" name='producttype' required id="producttype" onChange={handlechage}>
<option value={-1}>Select Product type </option>
{productlist &&  productlist.map((val, ind) => { return (<option value={val.id}>{val.productName}</option>);
})} 
</select>
<span id="errorName" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-6">
<div className="form-group local-forms">
<label>Document Type <span className="login-danger">*</span></label>
<select className="form-control select" name='documenttype' required id="documenttype" onChange={handlechage}>
<option value={-1}>Select Document Type </option>
<option value="1">Audio</option>
<option value="2">Video</option>
<option value="3">PPT</option>
<option value="4">PDF</option>
</select>
<span id="errorProvalue" className='errormsg'></span>
</div>
</div>
<div className="col-12 col-sm-12">
<div className="form-group local-forms">
<label>Document File <span className="login-danger">*</span></label>
<input type="file" className='form-control' accept="" required placeholder="product document" name='productdocument' id="productdocument" onChange={handleFileChange}/>
<span id="errorDoc" className='errormsg'></span>
{/* <div className='imgsec'>
<img src={documentimage} style={{"width":"150px"}} />
</div> */}
</div>


</div>

<div className="col-12">
<div className="student-submit">
<button type="submit" name='saveProduct' id='saveProduct' onClick={()=>{submitProductMaterial();}} className="btn btn-primary">Submit</button>
</div>
</div>
</div>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
            </div>
    </React.Fragment>
    )
}

